import dateNamesFi from 'date-names/fi'

const fiLocale = {
  common: {
    // Yleisiä tekstejä
    myHome: 'Minun kotini',
    mySearch: 'Minun hakuni',
    back: 'Takaisin',
    continue: 'Jatka',
    step: 'Vaihe',
    cancel: 'Peruuta',
    showResults: 'Näytä tulokset',
    close: 'Sulje',
    public: 'Julkinen',
    private: 'Piilotettu',
    onSale: 'Myynnissä',
    edit: 'Muokkaa',
    show: 'Esikatsele',
    redirectToMessages: 'Siirry viesteihin',
    estimatedPriceDescriptionNotFound: 'Emme löytäneet automaattisia hintatietoja alueeltasi',
    messageSent: 'Viesti lähetetty',
    ready: 'Valmis',
    update: 'Päivitä',
    addFromMap: 'Lisää alueita kartasta',
    matches: 'osumaa',
    properties: 'asuntoja',
    propertiesToSell: 'Myynnissä olevat asunnot',
    buy: 'Maksa tilaus',
    creditcardNumber: 'Luottokortin numero',
    youHaveAlreadyShared: 'Olet jo jakanut Facebookiin',
    youHaveAlreadySubscribed: 'Olet jo liittynyt maksulliseksi jäseneksi',
    subscribe: 'Hanki jäsenyys',
    editProfile: 'Muokkaa profiilia',
    moveMapAddress: 'Kohdista kartta paikan nimellä...',
    loginFailed: 'Tarkista tunnus ja salasana!',
    me: 'Minä',
    anonymous: 'Tuntematon',
    //
    // Palvelustamme löytyi sinulle asuntoja 12
    matchesDescription: 'Palvelustamme löytyi sinulle asuntoja',
    deleteWatcher: 'Poista hakuvahti',
    deleteProperty: 'Poista asunto',
    deleteProfile: 'Poista profiili',
    profileIsDeleted: '* käyttäjä on poistanut profiilinsa',
    areYouSure: 'Oletko aivan varma?',
    confirmDelete:
      'Oletko varma että haluat poistaa tilisi? Kaikki Muuviniin syöttämäsi tiedot poistetaan, mukaan lukien asunnot, hakuvahdit ja viestit. Tämä toiminto ei voi peruuttaa.',
    addHeaderImage: 'Lisää kansikuva',
    removeHeaderImage: 'Poista kansikuva',
    placeholderSize: 'Esim. 75',
    placeholderStart: 'Esim. ',

    addAreas: 'Muokkaa alueita',

    addFavourite: 'Asuisin',
    removeFavourite: 'Asuisin',

    useractivation: 'Tunnuksen aktivointi',
    activation: 'Tunnuksesi on aktivoitu',

    registeredPleaseWait:
      'Olemme vastaanottaneet rekisteröitymisesi. Tarkista sähköpostisi ja aktivoi tunnuksesi sähköpostiviestissä olevasta linkistä aloittaaksesi Muuvinin käytön.',

    or: 'tai',
    addProperty: 'Lisää asunto',
    addSearchwatch: 'Lisää hakuvahti',
    profileDeleted: 'Profiilisi on poistettu. Sinut ohjataan etusivulle.',
    changeProperty: 'Vaihda asuntoa',
    notAuthorizedTitle: 'Sori, mutta et pääse tälle sivulle :)',
    imageRequirements: 'Tuemme maks. 15mb JPG tai PNG kuvia.',
    changesNotSaved:
      'Jos poistut sivulta nyt, menetät tallentamattomasi muutokset. Haluatko varmasti poistua?',
    changeLanguage: 'In english'
  },
  frontpage: {
    registerOrLogin: 'Rekisteröidy / kirjaudu',
    nav: {
      whatMuuvin: 'Mikä on Muuvin?'
    },
    hero: {
      heading: 'Muuvin on oikotie sinun uudelle etuovelle',
      cta1: 'Kokeile!'
    },
    statistics: {
      watchers: 'Hakuvahtia',
      homes: 'Kotia',
      users: 'Käyttäjää',
      matches: 'Hakuosumia'
    },
    step1: {
      heading: 'Oikotiesi uudelle etuovelle',
      stepno: 'Vaihe 1/4',
      content:
        'Selvitä asuntosi reaaliaikainen kysyntä heti ja tarjoa halutessasi asuntoasi yksityisesti vain niille käyttäjille, jotka etsivät juuri asuntoasi vastaavaa kotia.',
      addressLabel: 'Syötä osoitteesi:',
      addressPlaceHolder: 'Mannerheimintie, 00250',
      addressNumPlaceHolder: 'Nro',
      help: 'Älä huoli, tämä ei laita asuntoasi myyntiin, esille tai muulla tavalla tuo tietojasi julki. Voit myöhemmässä vaiheessa tehdä kodistasi perinteisen myynti-ilmoituksen, jos haluat.',
      buttonNext: 'Seuraava',
      errorMessage: 'Syötäthän osoitteesi ja talonumerosi'
    },
    step2: {
      heading: 'Millaisessa kodissa asut',
      stepno: 'Vaihe 2/4',
      buttonNext: 'Seuraava',
      help: 'Älä huoli, tämä ei laita asuntoasi myyntiin, esille tai muulla tavalla tuo tietojasi julki. Voit myöhemmässä vaiheessa tehdä kodistasi perinteisen myynti-ilmoituksen, jos haluat.',
      errorMessage: 'Valitse asuntoasi vastaava talotyyppi'
    },
    step3: {
      heading: 'Millaisessa kodissa asut',
      stepno: 'Vaihe 3/4',
      content: 'Kotisi tietoja verrataan alueesi hakuihin.',
      sizeLabel: 'Syötä kotisi koko',
      sizePlaceHolder: '67',
      sizeUnit: 'm²',
      buttonNext: 'Seuraava',
      help: 'Älä huoli, tämä ei laita asuntoasi myyntiin, esille tai muulla tavalla tuo tietojasi julki. Voit myöhemmässä vaiheessa tehdä kodistasi perinteisen myynti-ilmoituksen, jos haluat.',
      errorMessage: 'Syötäthän asuntosi koon'
    },
    step4: {
      heading: 'Hinta-arvio',
      stepno: 'Vaihe 4/4',
      content:
        'Kotisi tietoja verrataan alueesi hakujen hintahaitareihin. Syötä vielä näkemyksesi kotisi todennäköisestä arvosta niin näet ostajat joiden haku vastaa kotiasi.',
      priceLabel: 'Minkä arvoiseksi arvioisit kotisi?',
      pricePlaceHolder: 'esim 320 000',
      priceUnit: '€',
      buttonNext: 'Valmis!',
      help: 'Älä huoli, tämä ei laita asuntoasi myyntiin, esille tai muulla tavalla tuo tietojasi julki. Voit myöhemmässä vaiheessa tehdä kodistasi perinteisen myynti-ilmoituksen, jos haluat.',
      priceEstimate: 'Arviomme kodistasi:',
      errorMessage: 'Syötäthän hinta-arviosi'
    },
    step5: {
      heading: 'Rekisteröidy',
      stepno: 'Valmis',
      promoText: 'Potentiaalista ostajaehdokasta!',
      promoTextNoHits: 'Potentiaalista ostajaehdokasta!',
      promoLink: 'Näytä',
      promoLinkNoHits: 'Rekisteröidy',
      subheading:
        'Muuvin on enemmän kuin tavallinen asuntopalvelu. Muuvin yhdistää asunnot ja niistä kiinnostuneet. Rekisteröitymällä saat:',
      content:
        '• Yhteyden käyttäjiin jotka etsivät asuntoasi vastaavaa kotia\n\n• Arvokasta tietoa alueesi markkinatilanteesta\n\n• Voit tarjota asuntoasi juuri oikealle ostajalle, ilman toimeksiantoa',
      register: 'Rekisteröidy / kirjaudu',
      stats: {
        stat1: 'asukasluku',
        stat2: 'keskineliöhinta',
        stat3: 'hintatrendi 2018',
        stat4: 'pinta-ala',
        tooltip1: 'Asuinalueesi asukasluku',
        tooltip2:
          'Asuinalueesi keskineliöhinta. Asuntosi todellinen neliöhinta riippuu asunnon tarkemmasta sijainnista, kunnosta ja muista ominaisuuksista.',
        tooltip3: 'Asuinalueesi hintatrendi',
        tooltip4: 'Asuinalueesi pinta-ala'
      }
    },
    apartments: {
      heading: 'Unelmakotisi etsii jo sinua.'
    },
    lookingApartment: {
      heading: 'Etsitkö asuntoa',
      box: {
        // heading: "Missä sijaitsee unelmiesi koti?",
        heading: 'Lopeta etsiminen. Aloita löytäminen.',
        content:
          'Kerro meille millainen olisi unelmiesi koti, me etsimme mahdollisen unelmiesi kodin ja välitämme asukkaille viestin mahdollisesta tulevasta asukkaasta.',
        link: 'Löydä uusi kotisi'
      }
    },
    homeListing: {
      heading: 'Julkiset ja myynnissä olevat kodit',
      statistics1: 'Asuntoa Helsingissä',
      statistics2: 'Asuntoa Espoossa',
      link: 'Asuntohakuun'
    },
    faq: {
      slogan: 'Lopeta etsiminen. Aloita löytäminen.',
      heading: 'Kuinka Muuvin toimii?',
      q1: {
        heading: '1. Tieto on avain uuteen kotiin',
        content: 'Muuvin on uusi tietopalvelu kaikille kodeista ja asunnoista kiinnostuneille.'
      },
      q2: {
        heading: '2. Löydä kotisi sinun ehdoillasi',
        content:
          'Muuvin yhdistää kodit ja ostajat sijainnin, talotyypin, koon ja budjettirajauksen mukaan.'
      },
      q3: {
        heading: '3. Sinä päätät kenelle kotisi näkyy',
        content:
          'Kotisi ei näy myytävänä kohteena, ellet itse ilmoita sitä myytäväksi. Saat silti tietoja kiinnostuneista ostajista kotiisi täsmäävien hakujen kautta, ja voit itse päättää, reagoitko niihin vai et.'
      },
      q4: {
        heading: '4. Näitä koteja et löydä muualta!',
        content:
          'Asunnon etsijänä tavoitat kaikki unelmakotiisi täsmäävät kodit samalla haulla - riippumatta ovatko ne aktiivisesti myynnissä vai ei.'
      }
    },
    try: {
      heading: 'Kokeillaanko? Täytä kotisi tiedot ja uudista kanssamme asuntomarkkinat.',
      button: 'Kokeile'
    },
    terms: 'Käyttöehdot ja rekisteriseloste'
  },
  error: {
    registerFailed: 'Rekisteröinti epäonnistui. Tarkista kentät.',
    streetNumber: 'Lisää vielä kadun numero',
    address: 'Anna asunnon osoite',
    houseType: 'Valitse asunnon tyyppi',
    houseSize: 'Kirjoita asunnon koko',
    housePrice: 'Kirjoita asunnon hinta',
    houseVisible: 'Valitse asunnon näkyvyys',
    watcherVisible: 'Valitse hakuvahdin näkyvyys',
    minPrice: 'Anna minimihinta',
    maxPrice: 'Anna maksimihinta',
    minSize: 'Anna minimikoko',
    maxSize: 'Anna maksimikoko',
    noPrice: 'Anna asunnolle hinta. Hinnan täytyy olla yli',
    noArea: 'Valitse ainakin yksi alue ympyrällä',
    postalCode: 'Anna postinumero',
    description:
      'Kuvailemalla millaista asuntoa haet (ominaisuuksia, palveluita tms.) tavoitat kaikki hakuasi vastaavat asunnot riippumatta siitä, ovatko ne myynnissä. Rohkaiset siis juuri oikeat asuntonsa myymistä harkitsevat kodinomistajat tarjoamaan kotiaan sinulle. Ilman myynti-ilmoituksia, ilman välikäsiä.\n\nViesti välittyy ainoastaan käyttäjille joiden kodit vastaavat hakuasi (alue, talotyyppi, asunnon koko ja budjettirajaus). Yksityiskohdat eivät välity. Kenttää ei voi jättää tyhjäksi. Ylläpito varaa oikeuden poistaa epäasialliset hakuviestit.',
    passwords: 'Salasanat eivät täsmää',
    activation: 'Linkki on vanhentunut',
    passwordTooShort: 'Salasanan pitää olla vähintään 8 merkkiä',
    emailInUse: 'Sähköpostiosoite on jo käytössä',
    genericRegisterError:
      'Rekisteröinti epäonnistui. Sähköpostiosoite saattaa olla jo käytössä. Tarkista kentät',
    mapLoadError: 'Karttaa ei pystytty lataamaan, pahoittelut!'
  },
  pageHeaders: {
    page1: '',
    page2: 'Kerro asunnostasi',
    page3: 'Hintatiedot',
    page4: 'Rekisteröidy',
    search: 'Valitse alueet mistä etsit asuntoa',
    editProperty: 'Muokkaa asunnon tietoja',
    addProperty: 'Lisää kodin tiedot',
    editWatch: 'Muokkaa hakua',
    addWatch: 'Valitse alue(ita)',
    chat: 'Viestit',
    backToListing: 'Takaisin listaukseen'
  },
  license: {
    genericBuyText: 'Näytä ostajat',
    // teksti, mikä näytetään ostokehoitus -sivulla
    descriptionSub: '3kk täysi kokemus ilmaiseksi!',
    description: 'Päivitä tilisi päästäksesi käsiksi kaikkiin Muuvinin ominaisuuksiin',

    subscription: {
      buttonText: 'Päivitä',
      description:
        'Päivitä tilisi ja saat lisätä rajattomasti asunto-ilmoituksia Muuviniin.\nVoit koska tahansa lopettaa tilauksen.',
      paymentPerMonth: '14,95 €',
      paymentPerMonthText: '/kk'
    },
    noThanks: {
      buttonText: 'Ei kiitos, jatkan yhdellä asunnolla'
    }
  },
  wizard: {
    whatIsMuuvin: {
      whatIsMuuvin: 'Mikä Muuvin?',
      whatTitle: 'Mikä tämä on?',
      whatParagraph1:
        'Muuvin on uusi tietopalvelu kaikille kodeista ja asunnoista kiinnostuneille. Muuvin yhdistää kodit ja ostajat sijainnin, talotyypin, koon ja budjettirajauksen mukaan. Kotisi ei näy myytävänä kohteena, ellet itse ilmoita sitä myytäväksi. Saat silti tietoja kiinnostuneista ostajista kotiisi täsmäävien hakujen kautta, ja voit itse päättää, reagoitko niihin vai et. Asunnon etsijänä tavoitat kaikki unelmakotiisi täsmäävät kodit samalla haulla - riippumatta ovatko ne aktiivisesti myynnissä vai ei.',
      whatParagraph2: 'Täytä kotisi tiedot ja uudista kanssamme asuntomarkkinat.'
    },
    welcomeback: {
      header:
        'Tervetuloa takaisin %(name)s, olemme vihdoin avanneet uuden Muuvin -palvelun, toivomme että sinäkin hyödyt siitä.',
      header1: 'Tervetuloa takaisin ',
      header2:
        ', olemme vihdoin avanneet uuden Muuvin -palvelun, toivomme että sinäkin hyödyt siitä.',
      descriptionRich: 'Kotialueesi ',
      descriptionRichEnd:
        'vaikuttaa kiinnostavalle! Rekisteröitymällä saat halutessasi yhteyden käyttäjiin, jotka alueellasi etsivät kotiasi vastaavaa asuntoa.',
      descriptionPoor: 'Kotialueellasi ',
      descriptionPoorEnd:
        'ei vielä ole riittävästi käyttäjiä tilastojen näyttämiseksi. Rekisteröidy kuitenkin käyttäjäksi, niin saat meiltä heti tiedon kun joku etsii alueellasi kotiasi vastaavaa asuntoa.'
    },
    page1: {
      //     smallHeader: 'Syötä osoitteesi:',
      header: 'Mitä jos ostajat odottavatkin jo kotiasi myyntiin?',
      description: 'Kokeile, se on ilmaista ja vie vain minuutin.',
      imlookingforhouse: 'Etsitkö kotia? Löydät sen täältä',
      find: 'Hae koteja'
    },
    page2: {
      description: 'Kotisi tietoja verrataan alueesi hakuihin.',
      error: 'Täytä puuttuvat tiedot'
    },
    page3: {
      description:
        'Kotisi tietoja verrataan alueesi hakujen hintahaitareihin. Syötä vielä näkemyksesi kotisi todennäköisestä arvosta niin näet ostajat joiden haku vastaa kotiasi.',
      estimatedPrice: 'Hinta-arvio',
      estimatedPriceDescription:
        'Apuna hinta-arviossa voit käyttää alueesi keskimääräisen neliöhinnan mukaan laskutettu hinta-haitari',
      estimatedPriceDescriptionEnd:
        ' (tämä ei kuitenkaan huomioi mm. kokoa, kuntoa, tarkkaa sijaintia)',
      estimatedPriceDisclamer:
        'Älä huoli, tämä ei laita asuntoasi myyntiin, esille tai muulla tavalla tuo tietojasi julki. Voit myöhemmässä vaiheessa tehdä kodistasi perinteisen myynti-ilmoituksen jos haluat.',
      error: 'Täytä hinta',
      price: 'Hinta'
    },
    page4: {
      descriptionRich: 'Kotialueesi ',
      descriptionRichEnd:
        'vaikuttaa kiinnostavalle! Rekisteröitymällä saat halutessasi yhteyden käyttäjiin, jotka alueellasi etsivät kotiasi vastaavaa asuntoa.',
      descriptionPoor: 'Kotialueellasi ',
      descriptionPoorEnd:
        'ei vielä ole riittävästi käyttäjiä tilastojen näyttämiseksi. Rekisteröidy kuitenkin käyttäjäksi, niin saat meiltä heti tiedon kun joku etsii alueellasi kotiasi vastaavaa asuntoa.',
      bullets: {
        info: 'Tiesitkö tämän kotialueestasi',
        description: 'Muuvin yhdistää asunnot ja ostajat. Rekisteröitymällä saat',
        bullet1: 'yhteyden käyttäjiin jotka etsivät asuntoasi vastaavaa kotia',
        bullet2: 'arvokasta tietoa alueesi markkinatilanteesta',
        bullet3: 'voit tarjota asuntoasi juuri oikeille ostajille ilman toimeksiantoa'
      }
    },
    searchWatchers: 'Hakuvahtia',
    views: 'Näyttöä',
    clicked: 'Katsottu',
    favourites: 'Suosikkilistattu',
    numberOfLikes: 'Tykkäyksiä',
    numberOfComments: 'Kommentteja',

    potentialBuyers: 'Potentiaaliset ostajat',
    population: 'Asukasluku',
    area_km2: 'Pinta-ala km²',
    midPrice: 'Keskihinta €/m²',
    priceTrend: 'Hintatrendi 2018',
    registerLogin: 'Rekisteröidy tai kirjaudu',
    error: {
      streetNumber: 'Lisää vielä kadun numero',
      houseType: 'Valitse asunnon tyyppi',
      houseSize: 'Kirjoita asunnon koko',
      housePrice: 'Kirjoita asunnon hinta'
    }
  },
  form: {
    login: {
      // rekisteröintiosio
      letsgetstarted: 'Tervetuloa käyttäjäksi',
      sloganregister: 'Muuvin yhdistää kodit ja ostajat',

      // wizard -etusivu
      hello: 'Tervetuloa takaisin!',
      slogan: '- Muuvin tiimi.',

      email: 'Sähköposti',
      username: 'Käyttäjätunnus/sähköposti-osoite',
      password: 'Salasana',
      login: 'Kirjaudu',
      firstName: 'Etunimi',
      lastName: 'Sukunimi',
      register: 'Luo tunnus',
      namePlaceholder: 'Nimetön',

      google: 'Kirjaudu Google-tunnuksilla',
      facebook: 'Kirjaudu Facebook-tunnuksilla',
      socialmedialogin: 'Kirjaudu helpommin sosiaalisen median tililläsi.',

      or: 'tai',

      notyetregistered: 'Et vielä ole käyttäjä?',
      registerhere: 'Rekisteröidy tästä',
      alreadyuser: 'Onko sinulla jo tili?',
      loginhere: 'Kirjaudu sisään!',

      forgot: 'Unohtuiko salasana?',
      requestpasswordreset: 'Salasanan palautus',
      requestpassword: 'Pyydä uusi salasana',

      createUsingEmail: 'Luo tili sähköpostiosoitteella'
    },
    forgot: {
      emailSent: 'Sähköposti lähetetty salasanan palauttamiseksi.',
      changePassword: 'Vaihda salasana',
      passwordHasBeenReset: 'Salasana on vaihdettu'
    }
  },

  title: {
    home: 'Koti',
    house: 'Asunto'
  },
  house: {
    postal_code: 'Postinumero',
    add_postal_code: 'Lisää postinumero',
    Address: 'Osoite',
    myApartmentAddress: 'Osoite',
    AddressInput: 'Syötä osoitteesi, esim. Aleksanterinkatu 10, Helsinki',
    Nro: 'Nro',
    Type: 'Tyyppi',
    myApartmentSize: 'Koko',
    priceRange: 'Hintahaitari',
    apartmentSize: 'Koko',
    estimatedPrice: 'Hinta-arvio',
    price: 'Hinta',
    squareMeters: 'Neliöt',
    isForSaleHeader: 'Asuntoni on myytävänä',
    SetForSaleText: '',
    isForSale: 'Olen valmis myymään asuntoni (hinta näkyy ilmoituksessa)',
    publicNotForSale: 'Julkinen mutta ei myynnissä / tulossa myyntiin (hinta ei näy ilmoituksessa)',
    publicAndForSale: 'Myynnissä (hinta näkyy ilmoituksessa)',
    ThisWillNotPutYourHouseOnSale: 'Tämä ei laita vielä asuntoasi myyntiin -teksti',
    ShowInfo: 'Näytä tiedot',
    Submit: 'Päivitä asunnon tiedot',
    SubmitSave: 'Tallenna asunnon tiedot',
    MoreInfo: 'Lisätiedot',
    AddFile: 'Lisää tiedosto',
    Public: 'Julkinen',
    private: 'Yksityinen',
    TellAboutHouse: 'Kerro asunnostasi',
    DescribeYourHouse: 'Millaisessa kodissa asut',
    watchVisibility: 'Hakuvahdin näkyvyys',
    watchVisibilityDescription:
      'Valitsemalla Julkinen tavoitat kaikki unelmakotiisi täsmäävät asunnot samalla haulla riippumatta siitä, ovatko ne aktiivisesti myynnissä tai eivät. Kuvailemalla lisätietokentässä millaista kotia etsit rohkaiset myös ei-myynnissä olevien asuntojen omistajia harkitsemaan myyntiä.',
    SetPublicHeader: 'Valitse asuntosi näkyvyys muille',
    SetPublicText:
      'Valitse yksityinen jos et aktiivisesti ole myymässä kotiasi, mutta olet kiinnostunut kuulemaan asuntoasi vastaavista hauista.',
    HeaderImage: 'Kansikuva',
    externalurl: 'Asunnon myynti-ilmoitus',
    externalurltext: 'Onko asuntosi myynnissä muualla? Liitä linkki tähän',
    notForSalePublicPriceText: 'Tutustu ja tarjoa!',
    views: 'Katsottu',
    listed: 'Listattu',
    favourites: 'Suosikkilistattu',
    numberOfLikes: 'Tykkäyksiä',
    numberOfComments: 'Kommentteja',
    fbShare: 'Jaa Facebookissa'
  },
  searchWatcher: {
    TellAboutWhatYouSearch: 'Kerro mitä haet',
    Public: 'Julkinen, näytä muiden ilmoituksissa',
    Private: 'Yksityinen, älä näytä muiden ilmoituksissa',
    areas: 'Alueet',
    searchWatcherResultHeading: {
      common: 'Valitsemiltasi alueilta löytyy %(count)s hakukriteereihisi sopivaa kotia',
      private: 'ja tämän lisäksi, %(count)s asunnon omistajaa näkevät hakuasi.'
    },
    messageForAnonymous: 'Löysitkö kiinnostavia kohteita? Kirjaudu sisään ja tallenna hakuvahti!'
  },
  houseType: {
    DetachedHome: 'Omakotitalo',
    ApartmentBuilding: 'Kerrostalo',
    TerracedHouse: 'Rivitalo',
    SemiDetachedHome: 'Paritalo'
  },
  shared: {
    fetch: {
      loading: 'Odota hetki...'
    },
    navigation: {
      home: 'Koti',
      explore: 'Selaa',
      house: 'Asunto',
      logout: 'Kirjaudu ulos',
      messages: 'Viestit',
      notifications: 'Ilmoitukset',
      profile: 'Profiili',
      property: 'Asunnot',
      searchwatcher: 'Hakuvahdit',
      favourites: 'Suosikit'
    }
  },
  comment: {
    messageSeller: 'Ota yhteyttä myyjään',
    messageBuyer: 'Ota yhteyttä ostajaan',
    messagePlaceholder: 'Kirjoita viesti tähän...',
    commentPlaceholder: 'Kommentoi kuvaa...',
    sendMessage: 'Lähetä viesti',
    sendPrivateMessage: 'Lähetä yksityisviesti',
    message: 'Viesti',
    privateMessage: 'Lähetä yksityisviesti',
    messageNotEmpty: 'Viestikenttä ei saa olla tyhjä',
    submit: 'Lähetä',
    remove: 'Poista kommentti',
    likes: {
      zero: 'Ei vielä tykätty',
      one: '1 tykkäys',
      other: '%(count)s tykkäystä'
    }
  },
  dashboard: {
    property: {
      noLicense: 'Ikävä kyllä vain yhden asunnon lisääminen on mahdollista tällä hetkellä',
      myPropertyTitle: 'Asuntoni',
      infoAboutProperty: 'Tietoa asunnosta',
      infoAboutPropertySeller: 'Ilmoittaja',
      noDescriptionProperty: 'Asunnosta ei ole tietoa.',

      // Asunnon tiedot
      addTitle: 'Lisää asuntosi ja seuraa mikä kuhina sen ympärillä käy',

      // Asunnon lisäysseliteteksti
      addDescription:
        'Yhdistämme koteja ja ostajia sijainnin, talotyypin, koon ja hinnan mukaan. Kotisi tiedot eivät näy kenellekään, ellet tee siitä myytävää kohdetta. Saat silti tietoja kiinnostuneista ostajista kotiisi täsmäävien hakujen kautta, ja voit päättää reagoitko niihin vai et. Voit seurata kysyntää ja harkita myymistä ilman välittäjiä ja virallisia toimeksiantoja.',
      addButton: 'Lisää asunto',
      potentialProperties: 'Hakuvahdin osumat',
      potentialPropertiesPublic: 'Julkiset ja myynnissä olevat kodit',
      potentialPropertiesHidden: 'Yksityiset kodit',
      potentialPropertiesHiddenInfo:
        'Palvelusta löytyy myös yksityisiä koteja, jotka eivät vielä ole myynnissä. Kannusta heitä harkitsemaan tarjoamaan kotiaan kuvailevalla viestillä. Mitä täsmällisempi kuvaus, sitä osuvampia koteja käyttäjät osaavat tarjota sinulle.',

      potentialPropertiesHiddenDescription:
        'Tällä viestillä tavoitat hakuasi vastaavat yksityiset kodit',
      potentialBuyers: 'Potentiaaliset ostajat',
      potentialBuyers2: 'Asunnostani kiinnostuneet käyttäjät',
      myProperty: 'Asunnostani',

      potentialBuyersTitle: 'Nämä käyttäjät etsivät kotiasi vastaavaa asuntoa',
      potentialBuyersText:
        'Klikkaa käyttäjiä lukeaksesi tarkemmin heidän hausta. Voit halutessasi lähettää käyttäjälle viestin ja harkita kotisi tarjoamista hänelle.',

      potentialBuyersTextWhenWeHaveThem:
        'Tallenna asuntosi ja saat tiedon mahdollisista ostajista kun sellaisia löytyy',
      potentialBuyersTextWhenNotProAccount: 'Asunnollasi on potentiaalisia ostajia.',
      potentialBuyersSaveAndSeeResults: 'Tallenna asuntosi ja näet potentiaaliset ostajat.',

      houseIsCurrently: 'Asuntosi on',

      noDescription:
        'Et vielä ole määrittänyt viestiä jolla tavoitat ne yksityiset kodit, jotka vastaavat hakuasi',
      addItNow: 'Lisää se nyt',
      modifyItNow: 'Muokkaa',

      contactSeller: 'Ota yhteyttä myyjään',
      contactOwner: 'Ota yhteyttä omistajaan'
    },
    searchWatcher: {
      circles: 'Ympyrät',
      noLicense: 'Ikävä kyllä vain yhden hakuvahdin lisääminen on mahdollista tällä hetkellä',
      yourWatcher: 'Hakuvahtisi on',

      addTitle: 'Enemmän kuin hakuvahti',
      addDescription:
        'Haaveiletko kodinvaihdosta? Eikö olisi kätevää, jos tavoittaisit kaikki unelmakotiisi täsmäävät kohteet samalla haulla riippumatta siitä, ovatko ne aktiivisesti myynnissä tai eivät.',
      addButton: 'Tarkenna/muokkaa hakua',
      matches: 'osumaa',
      matchesDescription: {
        zero: 'Palvelustamme ei vielä löytynyt sinulle myytävää asuntoa',
        one: 'Palvelustamme löytyi sinulle myytävä asunto',
        other: 'Palvelustamme löytyi sinulle %(count)s myytävää asuntoa'
      },
      properties: 'asuntoa',
      matchesHiddenDescription: {
        zero: 'Palveluun tulee lähiaikoina lisää kiinnostavia asuntoja.',
        one: 'Palvelusta löytyy myös asunto, joka ei vielä ole julkinen. Pääset tähän ja tuleviin asuntoihin käsiksi tallentamalla hakuvahtisi.',
        other:
          'Palvelustamme löytyy myös %(count)s myytävää asuntoa, jotka eivät ole julkisia. Pääset näihin käsiksi tallentamalla hakuvahtisi.'
      },
      potentialPropertiesTextWhenWeHaveThem:
        'Tähän tulee mahdolliset asunnot näkyviin kun sellaisia löytyy.',
      potentialPropertiesSaveAndSeeResults: 'Tallenna hakuvahtisi ja näet potentiaaliset asunnot.',
      searchWatcherCirclesInstructionsIntro:
        'Valitse sinua kiinnostavia alueita kartalta ympyröillä. Voit käyttää maks. 10 ympyrää per hakuvahti.',
      searchWatcherCirclesInstructionsMouse:
        'Pidä osoitin pohjassa haluamallasi sijainnilla hetken kunnes ympyrä ilmestyy, vedä osoittimella säädäksesi ympyrän koon. Poista ympyrä klikkaamalla sitä.',
      searchWatcherCirclesInstructionsTouch:
        'Pidä sormi näytöllä haluamallasi sijainnilla hetken kunnes ympyrä ilmestyy, vedä sormella säädäksesi ympyrän koon. Poista ympyrä napauttamalla sitä.'
    },
    favourites: {
      // Suosikit sivun etusivu
      addTitle: 'Suosikit',
      addDescription: 'Tee unelmoinnista helpompaa ja kerää kaikki suosikit yhteen listaan.',
      addButton: 'Selaa asuntoja'
    }
  },
  profile: {
    // Profiilin muokkaussivu
    image: 'Profiilikuva',
    firstName: 'Etunimi',
    lastName: 'Sukunimi',
    email: 'Sähköposti',
    phoneNumber: 'Puhelinnumero',
    phoneNumberPlaceholder: '(Numeroa ei lisätty)',
    emailVerified: 'Sähköposti on vahvistettu',
    emailNotVerified: 'Vahvista sähköpostiasi',
    password: 'Salasana',
    password2: 'Salasana uudestaan',
    changePassword: 'Vaihda salasana',
    languageLabel: 'Kieli',
    edit: 'Muokkaa profiilia',
    userSinceStart: 'Muuvin jäsen',
    userSinceEnd: 'alkaen',
    facebookPassport: 'Facebook yhdistetty',
    doFacebookPassport: 'Yhdistä Facebook tili',
    googlePassport: 'Google yhdistetty',
    doGooglePassport: 'Yhdistä Google tili',
    save: 'Tallenna muutokset',
    changeProfilePicture: 'Ota tai lisää kuva',
    removeProfilePicture: 'poista kuva',
    cancel: 'peruuta',
    facebookConnect: {
      buttonText: 'Tuo tiedot Facebookista',
      description: 'Emme julkaise seinällesi mitään, vaan noudamme vain profiilikuvasi ja nimesi.'
    },
    license: {
      license: 'Jäsenyys',
      licenses: 'Jäsenyydet',
      expires: 'erääntyy',
      confirm: 'Oletko varma, että haluat peruuttaa jäsenyytesi?',
      cancel: 'Peruuta jäsenyys',
      cancelled: 'Peruutettu',
      paid: 'Maksettu'
    },

    language: {
      fi: 'suomi',
      en: 'englanti'
    },
    currencies: {
      eur: 'Euro',
      usd: 'USD'
    },
    metric: {
      metric: 'Metri',
      mile: 'Maili'
    },
    passport: {
      facebook: 'Facebook yhdistetty',
      google: 'Google-yhdistetty'
    },
    errors: {
      GENERAL_ERROR_PHONE_INVALID:
        'Puhelinnumero on virheellinen. Tarkista että se alkaa maa-koodilla.',
      GENERAL_ERROR_PHONE_NON_STRING: 'Puhelinnumero on virheellinen.',
      PROFILE_ERROR: 'Profiilin tallentaminen epäonnistui.',
      PHONE_NUMBER_ALREADY_EXISTS_ERROR:
        'Puhelinnumeroa ei voitu lisätä. Käytä toista puhelinnumeroa.',
      EMAIL_ALREADY_EXISTS_ERROR:
        'Sähköpostiosoitetta ei voitu lisätä. Valitse toinen sähköpostiosoite.'
    },
    messages: {
      newLoginMessage:
        'Rekisteröidy tai kirjaudu sisään nyt nähdäksesi kuka etsii juuri sinun kotiasi!'
    }
  },
  chat: {
    count: 'Viestiketjut',
    format: 'D.M.Y [klo] H:mm',
    nomessages: 'Ei viestejä.'
  },
  counterpart: {
    pluralize: (entry, count) =>
      entry[count === 0 && 'zero' in entry ? 'zero' : count === 1 ? 'one' : 'other'],
    names: dateNamesFi,

    formats: {
      date: {
        default: '%a, %e %b %Y',
        long: '%A, %B %o, %Y',
        short: '%b %e'
      },

      time: {
        default: '%H:%M',
        long: '%H:%M:%S %z',
        short: '%H:%M'
      },

      datetime: {
        default: '%a, %e %b %Y %H:%M',
        long: '%A, %B %o, %Y %H:%M:%S %z',
        short: '%e %b %H:%M'
      }
    }
  },
  subscription: {
    title: 'Haluan päivittää jäsenyyteni',
    description:
      'Päivitä tilisi päästäksesi käsiksi kaikkiin Muuvinin ominaisuuksiin. Voit lopettaa tilauksesi koska vaan.'
  },
  menu: {
    dashboard: 'Työpöytä',
    property: 'Asunnot',
    searchwatcher: 'Hakuvahdit',
    favourites: 'Suosikit',
    messages: 'Viestit',
    notifications: 'Ilmoitukset',
    profile: 'Profiili ja asetukset',
    logout: 'Kirjaudu ulos',
    stats: 'Tilastoja palvelusta',

    terms: 'Käyttöehdot ja rekisteriseloste'
  },
  watcher: {
    SubmitSave: 'Tallenna hakuvahti',
    Submit: 'Päivitä hakuvahti',
    name: 'Hakuvahdin nimi'
  },
  notification: {
    houseFound: 'Sinulla on uusi asunto hakuvahdissasi!',
    watchFound: 'Sinulla on uusi ostajaehdokas!',
    favorite: 'Sinun asunossasi asuisi!',
    liked: 'Sait tykkäyksen!',
    commented: 'Sait kommentin!',
    empty: 'Ei ilmoituksia'
  },
  buyer: {
    matchingProperty: 'Asunto, johon ostajan hakuvahti osuu'
  },
  stats: {
    pageTitle: 'Tilastoja palvelusta',
    service: 'Palvelun tiedot',
    properties: 'Asuntojen tiedot',
    userCount: 'Käyttäjää',
    houseCount: 'Asuntoa',
    matchCount: 'Osumia',
    watchCount: 'Hakuvahtia',
    messageCount: 'Viestiä',
    housesThatHaveMatches: 'Asuntoja, joilla hakuosumia',
    cumulativeWatchMatches: 'Kumulatiivisia hakuosumia',
    houseTypeCounts: {
      DetachedHome: 'Omakotitaloa',
      ApartmentBuilding: 'Kerrostaloa',
      TerracedHouse: 'Rivitaloa',
      SemiDetachedHome: 'Paritaloa'
    }
  },
  testEmails: {
    pageTitle: 'Testaa sendgridin maileja',
    description: 'Mailit lähtevät profiilisin sähköposteihin'
  },
  notFound: {
    pageTitle: '404 - ohhoh, nyt meni hieman pieleen',
    description: 'Etsimääsi sivua ei ikävä kyllä löydy :('
  },
  notFoundProperty: {
    pageTitle: '404 - ohhoh, nyt meni hieman pieleen',
    description: 'Etsimääsi asuntoa ei ikävä kyllä löydy, ehkä se on jo myyty? :O'
  },
  explore: {
    title: 'Selaa',
    search: 'Kirjoita kaupunki',
    searchNoHits: 'Ei osumia, kokeile hakea toista kaupunkia',
    showAll: 'Näytä kaikki',
    showMore: 'Näytä lisää',
    showLess: 'Takaisin',
    mostLikedHomes: 'Tykätyimmät kodit',
    mostCommentedHomes: 'Keskustelluimmat kodit',
    hottestAreas: 'Kuumimmat alueet',
    numOfPublicHouses: {
      zero: 'Ei yhtään julkista kotia',
      one: '1 julkinen koti',
      other: '%(count)s julkista kotia'
    },
    numOfPrivateHouses: {
      zero: 'Ei yhtään yksityistä kotia',
      one: '1 yksityinen koti',
      other: '%(count)s yksityistä kotia'
    }
  },
  leads: {
    chooseOptions: 'Valitse yksi tai useampi',
    needProfessionalHelp: 'Tarvitsetko ammattilaisen opastusta?',
    clickHere: 'Klikkaa tästä!',
    tellUsWhatYouNeed: 'Kerro meille miten voimme auttaa!',
    send: 'Lähetä',
    thankYou: 'Kiitos yhteydenotosta!',
    loading: 'Odota hetki...',
    formError:
      'Valitettavasti yhteydenottopyyntö epäonnistui. Kokeile uudelleen tai ota meihin yhteyttä suoraan sähköpostiosoitteella homes@muuvin.com.',
    optionBarter: 'Tarvitsen apua kaupanteossa',
    optionFloorPlan: 'Tarvitsen hyvän pohja-piirrustuksen',
    optionPricing: 'Tarvitsen apua hinta-arvion kanssa',
    optionPhotos: 'Tarvitsen hyviä valokuvia'
  }
}

export default fiLocale
