import React from 'react'
import styled from 'styled-components/macro'
import Color from '../../../styles/color'
import Variables from '../../../styles/variables'

const Wrapper = styled.div`
  display: flex;
  margin: ${Variables.gutter} 0;
  align-items: center;
  a,
  a span {
    color: ${Color.green};
  }
  > .iconwrapper {
    &.iw-icon {
      font-size: 1.25em;
    }
  }
  > div {
    width: 2.2rem;
    text-align: center;
    display: block;
    margin-right: 2rem;

    > img {
      max-width: 100%;
      height: auto;
      vertical-align: middle;
    }
  }
`

const PropertyAttribute = ({ iconSrc, iconFA, description }) => {
  return (
    <Wrapper>
      {iconFA && <div className="iconwrapper iw-icon">{iconFA}</div>}
      {iconSrc && (
        <div className="iconwrapper iw-img">
          <img src={iconSrc} />
        </div>
      )}
      {description && <strong>{description}</strong>}
    </Wrapper>
  )
}

export default PropertyAttribute
