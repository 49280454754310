export function numVal(val, isFloat = (typeof val === 'string' ? val : '').includes('.')) {
  if (!val || Number.isNaN(val)) {
    return 0
  }

  if (typeof val === 'number') {
    return val
  }

  let numericVal = (val || '0').replaceAll(',', '.').replaceAll(/[^\d.]/g, '')

  if (isFloat) {
    numericVal = parseFloat(numericVal)
  } else {
    numericVal = parseInt(numericVal, 10)
  }

  if (!numericVal || isNaN(numericVal)) {
    return 0
  }

  return numericVal
}

export function renderWithThousandSeparators(value) {
  return numVal(value).toLocaleString('en').replaceAll(',', ' ')
}
