import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled, { css } from 'styled-components/macro'
import translate from 'counterpart'
import scrollToComponent from 'react-scroll-to-component'
import moment from 'moment'
import * as AreaActions from '../actions/AreaActions'
import {
  receiveTempProperty,
  getFeaturedByComments,
  getFeaturedByArea,
  getFeaturedByMostLiked
} from '../actions/HouseActions'

import BasicPageContainer from './BasicPageContainer'
import Link from '../components/shared/Link'
import Button from '../components/shared/Button'
import StatBox from '../components/shared/StatBox'
import InputField from '../components/shared/InputField'
import RadioIconField from '../components/shared/RadioIconField'
import Spacer from '../components/shared/Spacer'
import PromoButton from '../components/shared/PromoButton'
import ErrorMessage from '../components/shared/ErrorMessage'
import { numVal } from '../utils/number'

import Variables from '../styles/variables'
import Color from '../styles/color'

import { createApiRequest } from '../utils/Api'

import ReactGA from 'react-ga'

import frontpageHero from '../images/frontpage-hero.jpg'
import frontpageLookingApartmentBanner from '../images/frontpage-looking-apartment-banner.jpg'
import frontpageStep1 from '../images/frontpage-step1.svg'
import iconArrowLeft from '../images/icon-arrow-left.svg'
import iconArrowRight from '../images/icon-arrow-right.svg'
import houseDetached from '../images/house-detached.svg'
import houseApartment from '../images/house-apartment.svg'
import houseTerraced from '../images/house-terraced.svg'
import houseSemidetached from '../images/house-semidetached.svg'
import iconSmiley from '../images/icon-smiley.svg'
import frontpageStep3 from '../images/frontpage-step3.svg'
import frontpageFaq1 from '../images/frontpage-faq-1.svg'
import frontpageFaq2 from '../images/frontpage-faq-2.svg'
import frontpageFaq3 from '../images/frontpage-faq-3.svg'
import frontpageFaq4 from '../images/frontpage-faq-4.svg'
import frontpageFooter from '../images/frontpage-footer.svg'
import LookingApartmentBanner from '../components/shared/LookingApartmentBanner'
import { findDOMNode } from 'react-dom'
import { GoogleMapProvider } from '../components/googlemap/GoogleMapProvider'
import { changeLanguage } from '../actions/LanguageActions'
import { withRouter } from 'react-router'
import LoggedOutHeader from '../components/shared/LoggedOutHeader'

const images = {
  frontpageHero,
  frontpageLookingApartmentBanner,
  frontpageStep1,
  iconArrowLeft,
  iconArrowRight,
  houseDetached,
  houseApartment,
  houseTerraced,
  houseSemidetached,
  iconSmiley,
  frontpageStep3,
  frontpageFaq1,
  frontpageFaq2,
  frontpageFaq3,
  frontpageFaq4,
  frontpageFooter
}

const { roundPrice } = AreaActions

// Utils
const nlnl2p = (s) => s.split('\n\n').map((line, i) => <p key={i}>{line}</p>)

const parseAddressComponents = (addr) =>
  Object.assign({}, ...addr.map((o) => ({ [o.types[0]]: o.long_name })))

const Section = styled.section`
  padding-top: ${Variables.navigationHeight};
  padding-bottom: ${Variables.navigationHeight};
`

const SectionContent = styled.div`
  max-width: ${Variables.maxWidthPage};
  padding: 0 ${Variables.gutter};
  margin-left: auto;
  margin-right: auto;

  ${(props) =>
    props.centeredText &&
    css`
      text-align: center;
    `}

  .text-center {
    text-align: center;
  }

  > h1 {
    margin-top: ${Variables.navigationHeight};
  }
`

const CenteredContent = styled.div`
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  text-align: center !important;

  > * {
    padding-right: 0 !important;
  }
`

const StatsSection = styled(Section)`
  padding-top: 0;
  padding-bottom: 0;
  background-color: ${Color.white};
`

const StatsWrapperBPSmall = '500px'
const StatsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  > div {
    width: 50%;

    @media (min-width: ${StatsWrapperBPSmall}) {
      width: 25%;
    }
  }
`

const Tip = styled.div`
  background-color: ${Color.darkGray};
  color: ${Color.white};
  font-size: ${Variables.smallFontSize};
  padding: 1.3rem 2rem;
  border-radius: 2.5rem;
  position: relative;
  white-space: nowrap;
  font-weight: 600;

  &::before {
    content: '';
    border-width: ${(props) =>
      props.tipBottom ? '0.7rem 0.7rem 0 0.7rem' : '0 0.7rem 0.7rem 0.7rem'};
    border-color: ${(props) =>
      props.tipBottom
        ? Color.darkGray + ' transparent transparent transparent'
        : 'transparent transparent ' + Color.darkGray + ' transparent'};
    border-style: solid;
    position: absolute;
    left: 50%;
    top: ${(props) => (props.tipBottom ? 'auto' : '-0.7rem')};
    bottom: ${(props) => (props.tipBottom ? '-0.7rem' : 'auto')};
    margin-left: -0.35rem;
  }
`

const DemandContainer = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
  background: url(${frontpageHero}) center center;
  background-size: cover;
  color: ${Color.white};
`

const DemandSlider = styled.ul`
  list-style-type: none;
  display: flex;
  align-items: center;
  width: ${(props) => props.children.length * 100 + '%'};
  padding: 0;
  background-color: rgba(0, 0, 0, 0.2);
  margin: 0 0 0 ${(props) => -(props.currentStep * 100) + '%'};
  transition: all ${Variables.defaultSpeed} ${Variables.defaultEasing};
`

const DemandSlide = styled.li`
  width: 100%;
  text-align: center;

  h1 {
    @media (min-width: ${Variables.bpSmall}) {
      font-size: 4.5rem;
    }

    text-shadow: rgb(47, 53, 64, 0.7) 0 0 1rem;
  }

  h2 {
    margin-bottom: 0;

    & + p {
      margin-top: 0;
    }
  }
`

const SlideOnePBSMall = '750px'
const DemandSlideContentOne = styled(Section)`
  > div {
    > div {
      > div {
        &:nth-child(even) {
          margin-top: 4rem;

          img {
            max-width: 100%;
            height: auto;
          }
        }
      }

      @media (min-width: ${SlideOnePBSMall}) {
        display: flex;
        align-items: center;

        > div {
          width: 50%;
        }

        .img-wrapper {
          margin-bottom: 5rem;
          display: inline-block;
          position: relative;
        }
      }

      form {
        label {
          text-align: left;
        }

        > div {
          display: flex;
          align-items: flex-end;

          > div {
            padding-right: ${Variables.gutter};
            flex-grow: 1;
          }
        }
      }
    }
  }
`

const ShortInput = styled(InputField)`
  width: 8rem;
  flex-grow: 0 !important;
  padding-right: 0 !important;
`

const SlideTwoPBSMall = '500px'
const DemandSlideContentTwo = styled(Section)`
  form {
    > div:first-of-type {
      display: flex;
      margin-left: -${Variables.gutterHalf};
      margin-right: -${Variables.gutterHalf};
      flex-wrap: wrap;

      @media (min-width: ${SlideTwoPBSMall}) {
        margin-left: -${Variables.gutter};
        margin-right: -${Variables.gutter};
      }

      > div {
        width: calc(50% - ${parseFloat(Variables.gutterHalf) * 2}rem);
        margin-left: ${Variables.gutterHalf};
        margin-right: ${Variables.gutterHalf};
        max-width: 16rem;
        margin-bottom: ${parseFloat(Variables.gutterHalf) * 2}rem;

        @media (min-width: ${SlideTwoPBSMall}) {
          width: calc(25% - ${parseFloat(Variables.gutter) * 2}rem);
          margin-left: ${Variables.gutter};
          margin-right: ${Variables.gutter};
          margin-bottom: ${parseFloat(Variables.gutter) * 2}rem;
        }
      }
    }
  }
`

const CenteredField = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-items: center !important;
  padding-right: 0 !important;

  label {
    text-align: center !important;
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding-right: 0 !important;

    @media screen and (min-width: 450px) {
      width: 300px;
    }
  }
`

const DemandSlideContentThree = styled(DemandSlideContentOne)``

const DemandSlideContentFour = styled(DemandSlideContentThree)`
  > div {
    > div {
      > div {
        &:nth-child(even) {
          margin-bottom: 4rem;

          @media (min-width: ${SlideOnePBSMall}) {
            margin-bottom: 0rem;
          }
        }
      }

      form {
        > div {
          > div {
            &:last-of-type {
              width: 36rem;
              max-width: 100%;
            }
          }
        }
      }
    }
  }
`

const DemandSlideContentFive = styled(DemandSlideContentThree)`
  > div {
    > div {
      > div {
        &:nth-child(even) {
          margin-top: 10rem;

          @media (min-width: ${SlideOnePBSMall}) {
            margin-top: 0rem;
          }
        }
      }

      form {
        > div {
          > div {
            &:last-of-type {
              width: 36rem;
              max-width: 100%;
            }
          }
        }
      }
    }
  }

  .promo {
    padding: 0 ${Variables.gutter} ${Variables.gutter};
    background-color: ${Color.white};
    border: 0.1rem solid ${Color.border};
    border-radius: ${Variables.borderRadius};
  }

  .register-button-wrapper {
    text-align: center;
  }
`

const TipContainer = styled.div`
  width: auto !important;
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    width: auto !important;
  }
`

const HomeListingBPSmall = '768px'
styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -${parseFloat(Variables.gutterHalf)}rem;
  margin-right: -${parseFloat(Variables.gutterHalf)}rem;

  > div {
    width: calc(100% - ${Variables.gutter});
    margin-left: ${Variables.gutterHalf};
    margin-right: ${Variables.gutterHalf};
    margin-bottom: ${Variables.gutter};

    @media (min-width: ${HomeListingBPSmall}) {
      width: calc(33.333% - ${Variables.gutter});
    }
  }
`

const FAQSectionBPSMall = '550px'
const FAQSection = styled(Section)`
  > div {
    display: flex;
    flex-wrap: wrap;
    padding-left: ${Variables.gutterHalf};
    padding-right: ${Variables.gutterHalf};
    justify-content: center;
    max-width: 110rem;

    @media (min-width: ${FAQSectionBPSMall}) {
      justify-content: space-evenly;
    }

    > header {
      padding-left: ${Variables.gutterHalf};
      padding-right: ${Variables.gutterHalf};
      width: 100%;

      > h2 {
        font-size: ${Variables.h3FontSize};
      }
    }

    > div {
      margin-left: ${Variables.gutterHalf};
      margin-right: ${Variables.gutterHalf};
      max-width: 40rem;
      width: calc(100% - ${Variables.gutter});
      margin-bottom: 6rem;

      &:last-of-type {
        margin-bottom: 0;
      }

      @media (min-width: ${FAQSectionBPSMall}) {
        width: calc(50% - ${Variables.gutter});
      }
    }
  }
`

const TrySection = styled(Section)`
  h2 {
    width: ${Variables.maxWidthCol};
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  form {
    > div {
      max-width: 100%;
      width: 47rem;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      align-items: flex-end;
      justify-content: center;

      > div {
        padding-right: ${Variables.gutter};
        flex-grow: 1;

        &:last-of-type {
          padding-right: 0;
          flex-grow: 0;
          width: 8rem;
        }
      }
    }
  }
`

const FooterImageSection = styled(Section)`
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: 0.2rem solid ${Color.blackish};

  > div {
    max-width: 58rem;

    img {
      vertical-align: top;
      margin-bottom: -0.2rem;
      max-width: 100%;
      height: auto;
    }
  }
`

const Terms = styled(Section)`
  text-align: center;
`

class FrontPageContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      currentStep: 0,
      totalSteps: 4,
      maxStep: 0,
      values: {},
      addressIsValid: false,
      address: {},
      areaStatistics: {},
      watchers: 0,
      featured: [],
      stats: {
        watches: '',
        homes: '',
        users: '',
        matches: ''
      },
      showError: false
    }

    // virtual page loads we use to create GA funnels
    // prefix with _vpl so they are easier to filter out on GA reports if needed
    this.stepsGAVirtualUrls = [
      '/_vpl__index--step1--property-address',
      '/_vpl__index--step2--property-type',
      '/_vpl__index--step3--property-size',
      '/_vpl__index--step4--property-price',
      '/_vpl__index--step5--property-all-set'
    ]

    this._getFeatured = this._getFeatured.bind(this)
    this._getMostHousesArea = this._getMostHousesArea.bind(this)
    this._getMostLikedHouses = this._getMostLikedHouses.bind(this)
    this._getMostCommentedHouses = this._getMostCommentedHouses.bind(this)

    const google = window?.google

    if (google) {
      this.geocoder = new google.maps.Geocoder()
    }
  }

  static propTypes = {
    language: PropTypes.string,
    changeLanguage: PropTypes.func,
    getAreas: PropTypes.func,
    areas: PropTypes.object,
    user: PropTypes.object
  }

  addressInput = null

  redirectAuthenticatedUser = () => {
    const { user, router } = this.props

    // Redirect logged in users to their property page if they should land on the front page.
    if (user) {
      router.replace('/property')
      return true
    }

    return false
  }

  componentDidUpdate() {
    this.redirectAuthenticatedUser()
  }

  componentDidMount() {
    if (this.redirectAuthenticatedUser()) {
      return
    }

    const google = window?.google

    if (google) {
      let input = findDOMNode(this.addressInput?.inputRef)

      if (input) {
        this.autocomplete = new google.maps.places.Autocomplete(input, {
          types: ['address']
        })

        this.autocomplete.addListener('place_changed', this.handlePlaceChanged)
      }
    } else {
      console.error('Google Maps not loaded for Frontpage!')
    }

    let apiRequest = createApiRequest()

    // Hard-code statistics to avoid API calls
    this.handleStatistics({
      watches: 379,
      houses: 578,
      users: 1300,
      hits: 952
    })

    // Get featured houses
    apiRequest({ path: '/house/featured' }).then(({ data }) => this.handleFeatured(data))
  }

  getWatchersStatistics() {
    const { values, geometry } = this.state

    let watchesReqProps = {
      house: {
        houseType: values.houseType,
        size: numVal(values?.houseSize, false),
        geolocation: {
          lat: geometry.location.lat(),
          lng: geometry.location.lng()
        }
      }
    }

    let apiRequest = createApiRequest()

    return apiRequest({ path: '/house/watches', method: 'POST', json: watchesReqProps }).then(
      ({ data }) => {
        return this.setState({ watchers: data?.length || 0 })
      }
    )
  }

  saveTemporaryProperty() {
    const { address, values, geometry } = this.state

    let data = {
      name: `${address.route} ${address.street_number}, ${address.locality}`,
      location: `${address.route} ${address.street_number}, ${address.locality}`,
      houseType: values.houseType,
      price: undefined,
      size: numVal(values?.houseSize, false),
      locationParts: {
        streetNumber: address.street_number,
        streetName: address.route,
        postalCode: address.postal_code,
        city: address.locality,
        country: address.country
      },
      private: true,
      geolocation: {
        lat: geometry.location.lat(),
        lng: geometry.location.lng()
      }
    }

    receiveTempProperty(data)
  }

  handleStatistics(stats) {
    if (stats) {
      this.setState({
        stats: {
          watches: stats.watches,
          homes: stats.houses,
          users: stats.users,
          matches: stats.hits
        }
      })
    }
  }

  handleFeatured(houses = []) {
    var featured = []

    if (houses?.length > 3) {
      while (featured.length < 3) {
        featured.push(houses.splice(Math.floor(Math.random() * houses.length), 1)[0])
      }
    } else {
      featured = houses
    }

    this.setState({ featured })
  }

  validateAddress(address) {
    this.geocoder?.geocode({ address }, (result, status) => {
      if (!result || result.length === 0 || status !== 'OK') {
        return this.setState({ addressIsValid: false })
      }

      const addr = parseAddressComponents(result[0].address_components)

      if (addr.street_number) {
        this.setState({
          addressIsValid: true,
          address: addr,
          geometry: result[0].geometry
        })
      } else {
        this.setState({
          addressIsValid: false
        })
      }
    })
  }

  _getFeatured(country, city) {
    var _city =
      this.state && this.state.featured && this.state.featured._city
        ? this.state.featured._city
        : null
    var _country =
      this.state && this.state.featured && this.state.featured._country
        ? this.state.featured._country
        : null
    var _timestamp =
      this.state && this.state.featured && this.state.featured._timestamp
        ? this.state.featured._timestamp
        : null

    if (
      _city !== city ||
      _country !== country ||
      !_timestamp ||
      (_timestamp && _timestamp.diff(moment(), 'seconds') >= 20)
    ) {
      this._getMostCommentedHouses(city, country)
      this._getMostLikedHouses(city, country)
      this._getMostHousesArea(country)
      var featured = { _timestamp: moment(), _city: city, _country: country }

      this.setState({ featured: featured })
    }
  }
  _getMostCommentedHouses(city, country) {
    var query = ''
    if (city && city !== '-') {
      query += '&city=' + city
    }

    if (country && country !== '-') {
      query += '&country=' + country
    }

    this.props.getFeaturedByComments(query)
  }
  _getMostLikedHouses(city, country) {
    var query = ''

    if (city && city !== '-') {
      query += '&city=' + city
    }

    if (country && country !== '-') {
      query += '&country=' + country
    }

    this.props.getFeaturedByMostLiked(query)
  }
  _getMostHousesArea(country) {
    var query = ''

    if (country && country !== '-') {
      query += '&country=' + country
    }

    this.props.getFeaturedByArea(query)
  }

  handleSubmit = (e) => {
    e.preventDefault()

    let isValid = false
    const baseGAURLIndex = this.state.currentStep

    switch (this.state.currentStep) {
      case 0:
        isValid = !!this.state.addressIsValid
        break
      case 1:
        try {
          let selectedHouseType = document.querySelector('input[name="houseType"]:checked')
          if (selectedHouseType && selectedHouseType.value) {
            this.setState({
              values: { ...this.state.values, houseType: selectedHouseType.value }
            })
            isValid = true
          }
        } catch (err) {}
        break
      case 2:
        isValid = !!(this.state.values.houseSize && this.state.values.houseSize > 0)

        if (isValid) {
          this.handleGAVirtualPageLoad(baseGAURLIndex, '--submit')
          this.saveTemporaryProperty()

          return this.getWatchersStatistics().then(() => {
            this.setState({ currentStep: (this.state.currentStep + 1) % this.state.totalSteps })
          })
        }

        break
    }

    if (isValid) {
      let nextStep = (this.state.currentStep + 1) % this.state.totalSteps
      let maxStep = this.state.maxStep
      let nextMaxStep = Math.max(nextStep, maxStep)
      this.setState({ currentStep: nextStep, showError: false, maxStep: nextMaxStep })
    } else {
      this.setState({ showError: true })
    }

    // send GA page view event
    const baseGAURLSuffix = isValid ? '--submit' : '--invalid'

    this.handleGAVirtualPageLoad(baseGAURLIndex, baseGAURLSuffix)
  }

  handleChangeInput = (e) => {
    this.setState({ values: { ...this.state.values, [e.target.name]: e.target.value } })
  }

  handleChangeInputAddress = (e) => {
    this.setState({
      addressIsValid: false,
      values: { ...this.state.values, [e.target.name]: e.target.value }
    })

    clearTimeout(this.validationTimeout)

    this.validationTimeout = setTimeout(() => {
      if (this.state.values.streetNumber && this.state.values.streetAddress) {
        this.validateAddress(`${this.state.values.streetNumber} ${this.state.values.streetAddress}`)
      }
    }, 200)
  }

  handlePlaceChanged = () => {
    let place = this.autocomplete?.getPlace()

    if (!place.geometry) {
      return
    }

    let addr = parseAddressComponents(place.address_components)

    let streetAddress = `${addr.route}${addr.postal_code ? ', ' + addr.postal_code : ''}${
      addr.locality ? ' ' + addr.locality : ''
    }`

    this.setState({
      values: {
        addressIsValid: false,
        ...this.state.values,
        streetAddress,
        streetNumber: addr.street_number || this.state.values.streetNumber
      }
    })

    this.validationTimeout = setTimeout(() => {
      if (this.state.values.streetNumber && this.state.values.streetAddress) {
        this.validateAddress(`${this.state.values.streetNumber} ${this.state.values.streetAddress}`)
      }
    }, 200)
  }

  // send virtual page url as GA pageview
  handleGAVirtualPageLoad = (indx, suffix) => {
    let baseGAURL = this.stepsGAVirtualUrls[indx]
    if (suffix) {
      baseGAURL += suffix
    }

    ReactGA.pageview(baseGAURL)
  }

  render() {
    const { stats, address } = this.state

    return (
      <div>
        <LoggedOutHeader />
        <BasicPageContainer maxWidth="none" style={{ padding: 0 }}>
          <DemandContainer
            ref={(elem) => {
              this.demandContainer = elem
            }}>
            <DemandSlider id="demand-slider" currentStep={this.state.currentStep}>
              {/* STEP 1: ADDRESS */}
              <DemandSlide>
                <DemandSlideContentOne>
                  <SectionContent>
                    <h1>{translate('frontpage.step1.heading')}</h1>
                    <CenteredContent>
                      <div>
                        {nlnl2p(translate('frontpage.step1.content'))}
                        <form onSubmit={this.handleSubmit}>
                          <div>
                            <InputField
                              ref={(ref) => (this.addressInput = ref)}
                              input={{
                                value: this.state.values['streetAddress'] || '',
                                name: 'streetAddress',
                                onChange: this.handleChangeInputAddress
                              }}
                              id="streetAddress"
                              type="text"
                              showLabel={true}
                              label={translate('frontpage.step1.addressLabel')}
                              placeholder={translate('frontpage.step1.addressPlaceHolder')}
                            />
                            <ShortInput
                              input={{
                                value: this.state.values['streetNumber'] || '',
                                name: 'streetNumber',
                                onChange: this.handleChangeInputAddress
                              }}
                              id="streetNumber"
                              type="number"
                              placeholder={translate('frontpage.step1.addressNumPlaceHolder')}
                              unit={
                                <span
                                  className={`icon-indicator ${
                                    this.state.addressIsValid
                                      ? 'addressIsValid '
                                      : 'addressIsInvalid'
                                  }`}>
                                  {this.state.addressIsValid ? (
                                    <i className="fa fa-check" aria-hidden="true"></i>
                                  ) : (
                                    <i className="fa fa-exclamation" aria-hidden="true"></i>
                                  )}
                                </span>
                              }
                            />
                          </div>
                          {this.state.currentStep === 0 &&
                            this.state.showError &&
                            !this.state.addressIsValid && (
                              <ErrorMessage>
                                {translate('frontpage.step1.errorMessage')}
                              </ErrorMessage>
                            )}
                          <Spacer multiplier={3} />
                          <CenteredContent>
                            <Button type="submit" text={translate('frontpage.step1.buttonNext')} />
                          </CenteredContent>
                        </form>
                      </div>
                    </CenteredContent>
                  </SectionContent>
                </DemandSlideContentOne>
              </DemandSlide>
              {/* STEP 2: HOUSE TYPE */}
              <DemandSlide>
                <DemandSlideContentTwo>
                  <SectionContent>
                    <h1>{translate('frontpage.step2.heading')}</h1>
                    <Spacer multiplier={2} />
                    <form onSubmit={this.handleSubmit}>
                      <CenteredContent>
                        <RadioIconField
                          name="houseType"
                          value="1"
                          label={translate('houseType.DetachedHome')}
                          src={images.houseDetached}
                        />
                        <RadioIconField
                          name="houseType"
                          value="3"
                          label={translate('houseType.ApartmentBuilding')}
                          src={images.houseApartment}
                        />
                        <RadioIconField
                          name="houseType"
                          value="2"
                          label={translate('houseType.TerracedHouse')}
                          src={images.houseTerraced}
                        />
                        <RadioIconField
                          name="houseType"
                          value="4"
                          label={translate('houseType.SemiDetachedHome')}
                          src={images.houseSemidetached}
                        />
                      </CenteredContent>
                      {this.state.currentStep === 1 && this.state.showError && (
                        <ErrorMessage>{translate('frontpage.step2.errorMessage')}</ErrorMessage>
                      )}
                      <Spacer multiplier={1.5} />
                      <Button type="submit" text={translate('frontpage.step2.buttonNext')} />
                    </form>
                  </SectionContent>
                </DemandSlideContentTwo>
              </DemandSlide>
              {/* STEP 3: SIZE */}
              <DemandSlide>
                <DemandSlideContentThree>
                  <SectionContent>
                    <h1>{translate('frontpage.step3.heading')}</h1>
                    <CenteredContent>
                      <div>
                        {nlnl2p(translate('frontpage.step3.content'))}
                        <form onSubmit={this.handleSubmit}>
                          <CenteredContent style={{ flexDirection: 'column' }}>
                            <CenteredField>
                              <InputField
                                input={{
                                  value: this.state.values['houseSize'] || '',
                                  name: 'houseSize',
                                  onChange: this.handleChangeInput
                                }}
                                type="text"
                                showLabel={true}
                                label={translate('frontpage.step3.sizeLabel')}
                                placeholder={translate('frontpage.step3.sizePlaceHolder')}
                                unit={translate('frontpage.step3.sizeUnit')}
                              />
                            </CenteredField>
                            {this.state.currentStep === 2 && this.state.showError && (
                              <ErrorMessage>
                                {translate('frontpage.step3.errorMessage')}
                              </ErrorMessage>
                            )}
                            <Spacer multiplier={2} />
                            <Button type="submit" text={translate('frontpage.step3.buttonNext')} />
                          </CenteredContent>
                        </form>
                      </div>
                    </CenteredContent>
                  </SectionContent>
                </DemandSlideContentThree>
              </DemandSlide>
              {/* STEP 5: STATS */}
              <DemandSlide>
                <DemandSlideContentFive>
                  <SectionContent>
                    <h1>{translate('frontpage.step5.heading')}</h1>
                    <TipContainer>
                      {address && address.street_number ? (
                        <Tip tipBottom>
                          {`${address.route} ${address.street_number}, ${address.postal_code} ${address.locality}`}
                        </Tip>
                      ) : null}
                    </TipContainer>
                    <Spacer multiplier={2} />
                    <CenteredContent>
                      <div>
                        {this.state.watchers && this.state.watchers > 0 ? (
                          <PromoButton
                            link="/login"
                            linkText={translate('frontpage.step5.promoLink')}
                            icon={images.iconSmiley}
                            text={translate('frontpage.step5.promoText')}
                            count={this.state.watchers}
                          />
                        ) : (
                          <PromoButton
                            link="/login"
                            linkText={translate('frontpage.step5.promoLinkNoHits')}
                            icon={images.iconSmiley}
                            text={translate('frontpage.step5.promoTextNoHits')}
                            count="0"
                          />
                        )}
                        <Spacer multiplier={2} />
                        <div className="register-button-wrapper">
                          <Link type="button" to="/login">
                            {translate('frontpage.step5.register')}
                          </Link>
                        </div>
                      </div>
                    </CenteredContent>
                  </SectionContent>
                </DemandSlideContentFive>
              </DemandSlide>
            </DemandSlider>
          </DemandContainer>
          <StatsSection>
            <SectionContent>
              <StatsWrapper>
                <StatBox
                  title={stats.watches}
                  layout="huge"
                  noBorder
                  description={translate('frontpage.statistics.watchers')}
                />
                <StatBox
                  title={stats.homes}
                  layout="huge"
                  noBorder
                  description={translate('frontpage.statistics.homes')}
                />
                <StatBox
                  title={stats.users}
                  layout="huge"
                  noBorder
                  description={translate('frontpage.statistics.users')}
                />
                <StatBox
                  title={stats.matches}
                  layout="huge"
                  noBorder
                  description={translate('frontpage.statistics.matches')}
                />
              </StatsWrapper>
            </SectionContent>
          </StatsSection>
          {/* HOMES LISTINGS */}
          {/*
          <Section white>
            <SectionContent centeredText>
              <h1>{translate('frontpage.apartments.heading')}</h1>
            </SectionContent>
            <SectionContent>
              <ExploreViewFrontpage
                me={this.props.user}
                property={this.props.property}
                housetype={this.props.housetype}
                auth={this.props.auth}
                getFeatured={this._getFeatured}
                country={this.props.country}
                city={this.props.city}
                command={this.props.command}
              />
            </SectionContent>
            <Spacer multiplier={2} />
          </Section>
          */}
          <LookingApartmentBanner showBackground={false} />
          {/* MUUVIN 101 */}
          <SectionContent centeredText className="hidden">
            <h1>{translate('frontpage.faq.slogan')}</h1>
          </SectionContent>
          <FAQSection
            white
            ref={(elem) => {
              this.muuvin101 = elem
            }}>
            <SectionContent centeredText>
              <header>
                <h2>{translate('frontpage.faq.heading')}</h2>
                <Spacer multiplier={3} />
              </header>
              <div>
                <img alt="" src={images.frontpageFaq1} />
                <h3>{translate('frontpage.faq.q1.heading')}</h3>
                {nlnl2p(translate('frontpage.faq.q1.content'))}
              </div>
              <div>
                <img alt="" src={images.frontpageFaq2} />
                <h3>{translate('frontpage.faq.q2.heading')}</h3>
                {nlnl2p(translate('frontpage.faq.q2.content'))}
              </div>
              <div>
                <img alt="" src={images.frontpageFaq3} />
                <h3>{translate('frontpage.faq.q3.heading')}</h3>
                {nlnl2p(translate('frontpage.faq.q3.content'))}
              </div>
              <div>
                <img alt="" src={images.frontpageFaq4} />
                <h3>{translate('frontpage.faq.q4.heading')}</h3>
                {nlnl2p(translate('frontpage.faq.q4.content'))}
              </div>
            </SectionContent>
          </FAQSection>
          <TrySection white>
            <SectionContent centeredText>
              <h2>{translate('frontpage.try.heading')}</h2>
              <Spacer />
              <Button
                type="submit"
                onClick={() =>
                  scrollToComponent(this.demandContainer, {
                    offset: 0,
                    align: 'top',
                    duration: 1000,
                    ease: 'inOutCube'
                  })
                }
                text={translate('frontpage.try.button')}
              />
            </SectionContent>
          </TrySection>
          {/* FOOTER IMAGE */}
          <FooterImageSection white>
            <SectionContent>
              <img alt="" src={images.frontpageFooter} />
            </SectionContent>
          </FooterImageSection>
          {/* FOOTER IF ANY */}
          <Terms>
            <div>
              <Link to="/terms" type="no-deco">
                {translate('frontpage.terms')}
              </Link>
            </div>
          </Terms>
        </BasicPageContainer>
      </div>
    )
  }
}
/*
const mapStateToProps = ({ auth: { language }, auth }, state, ownProps) => ({
  language,
  user: auth.user,
  auth: auth,
  housetype: state.housetype,
  property: state.property,
  area: state.area,
})
*/
const mapStateToProps = (state, ownProps) => {
  return {
    error: state.auth.error,
    errorMessage: state.auth.errorMessage,
    auth: state.auth,
    user: state.auth.user,
    language: state.auth && state.auth.language ? state.auth.language : 'fi',
    comments: state.comments,
    housetype: state.housetype,
    property: state.property,
    watcher: state.watcher,
    area: state.area,
    file: state.file,
    favourites: state.favourites,
    messages: state.messages,

    propertyId: ownProps.params.propertyId,
    watcherId: ownProps.params.watcherId,
    imageId: ownProps.params.imageId,
    country: ownProps.params.country,
    city: ownProps.params.city,
    command: ownProps.params.command
  }
}

const mapDispatchToProps = (dispatch) => ({
  changeLanguage: (lang) => dispatch(changeLanguage(lang)),
  getFeaturedByComments: (query) => dispatch(getFeaturedByComments(query)),
  getFeaturedByArea: (query) => dispatch(getFeaturedByArea(query)),
  getFeaturedByMostLiked: (query) => dispatch(getFeaturedByMostLiked(query))
})

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(function (props) {
    return (
      <GoogleMapProvider>
        <FrontPageContainer {...props} />
      </GoogleMapProvider>
    )
  })
)
