import Color from '../../styles/color'
import styled from 'styled-components/macro'

const Svg = styled.svg``
const Path = styled.path``

export function ArrowLeft({ color = Color.blackish, style, className }) {
  return (
    <Svg style={style} className={className} x="0px" y="0px" viewBox="0 0 3.5 5.7">
      <Path fill={color} d="M0,2.9c0-0.5,3-3.3,3.3-2.8s0.3,5.2,0,5.6C2.9,6,0,3.4,0,2.9z" />
    </Svg>
  )
}

export function ArrowRight({ color = Color.blackish, style, className }) {
  return (
    <Svg style={style} className={className} x="0px" y="0px" viewBox="0 0 3.474 5.659">
      <Path
        fill={color}
        d="M1354.941,480.425c.335-.319,5.1-.258,5.575,0s-2.261,3.257-2.808,3.257S1354.606,480.743,1354.941,480.425Z"
        transform="translate(-480.208 1360.57) rotate(-90)"
      />
    </Svg>
  )
}

export function InfoIcon({ color = Color.blackish, style, className, size = '24px' }) {
  return (
    <Svg style={style} className={className} height={size} viewBox="0 0 24 24" width={size}>
      <Path
        fill={color}
        d="M11 7h2v2h-2zm1 10c.55 0 1-.45 1-1v-4c0-.55-.45-1-1-1s-1 .45-1 1v4c0 .55.45 1 1 1zm0-15C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
      />
    </Svg>
  )
}
