import React from 'react'
import styled from 'styled-components/macro'
import moment from 'moment'

import Avatar from '../shared/Avatar'
import translate from 'counterpart'
import Color from '../../styles/color'
import Variables from '../../styles/variables'
import { nl2p } from '../../utils/Text'

import * as UserActions from '../../actions/UserActions'

const MessageWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: ${Variables.gutter};
  flex-direction: ${(props) => (props.isByMe ? 'row-reverse' : 'row')};

  > div:first-of-type {
    margin-right: ${(props) => (props.isByMe ? 0 : Variables.gutter)};
    margin-left: ${(props) => (props.isByMe ? Variables.gutter : 0)};
    flex: 0 0 auto;
  }
`

const ChatBubbleElement = styled.div`
  background-color: ${(props) => (props.isByMe ? Color.green : Color.chatBubble)};
  color: ${(props) => (props.isByMe ? Color.white : Color.basic)};
  padding: 1.25rem ${Variables.gutter} 1rem;
  border-radius: ${(props) => (props.isByMe ? '2rem 2rem 0 2rem' : '2rem 2rem 2rem 0')};

  > p {
    margin-top: 0.5rem;
    margin-bottom: 0;
  }
`

const UserHeader = styled.header`
  font-size: ${Variables.smallFontSize};
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  > p {
    width: 100%;
    color: ${Color.red};
  }

  span {
    display: inline-block;
    margin-right: 0.75rem;
  }
`

const ChatBubble = ({ message }) => {
  if (!message) {
    return null
  }

  let me = message.me
  let sender = message.sender
  let senderName = sender?.name || translate('common.anonymous')

  let isByMe = false

  if (sender && me && sender.id === me.id) {
    isByMe = true
    senderName = translate('common.me')
  }

  const msg = message.message
  const time = moment(message.createdAt).format(translate('chat.format'))

  return (
    <MessageWrapper isByMe={isByMe}>
      <Avatar src={UserActions.getAvatarUrl(sender)} />
      <ChatBubbleElement isByMe={isByMe}>
        <UserHeader>
          <span>{senderName}</span>
          <time>{time}</time>
          {sender?.deleted && <p>{translate('common.profileIsDeleted')}</p>}
        </UserHeader>
        {nl2p(msg)}
      </ChatBubbleElement>
    </MessageWrapper>
  )
}

export default ChatBubble
