import React, { useEffect, useRef } from 'react'
import styled from 'styled-components/macro'
import Variables from '../../styles/variables'
import PaymentView from '../payment/PaymentView'
import Alert from './Alert'

const Overlay = styled.div`
  position: fixed;
  top: calc(${Variables.navigationHeight});
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;
  background-color: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow: auto;
`

const OverlayContent = styled.div`
  background: white;
  padding: 2rem;
  margin: 1rem 2rem calc(${Variables.navigationHeight} + 2rem);
  max-width: ${Variables.maxWidthPage};
  border-radius: 2rem;
  box-shadow: 0 0 3rem 0 rgba(0, 0, 0, 0.2);
  overflow-y: auto;

  @media (min-width: ${Variables.bpMedium}) {
    margin: 3rem 2rem calc(${Variables.navigationHeight} + 2rem);
  }
`

const GetLicenseOverlay = ({ message, backUrl, subject }) => {
  let overlayRef = useRef(null)

  useEffect(() => {
    if (overlayRef.current) {
      let wrapperRect = overlayRef.current.getBoundingClientRect()
      let height = wrapperRect.height + wrapperRect.top

      document.body.style.overflow = 'hidden'
      document.body.style.height = `${height}px`
    }

    return () => {
      document.body.style.overflow = 'auto'
      document.body.style.height = 'auto'
    }
  }, [overlayRef.current])

  return (
    <Overlay ref={overlayRef}>
      <OverlayContent>
        <Alert notice>
          <p>{message}</p>
        </Alert>
        <PaymentView backUrl={backUrl} subject={subject} />
      </OverlayContent>
    </Overlay>
  )
}

export default GetLicenseOverlay
