import mapStyle from './fancyMapStyles.json'
import { GoogleMap as GoogleMapComponent } from '@react-google-maps/api'
import { useMemo } from 'react'
import { DEFAULT_CENTER } from '../../constants'

const mapContainerStyle = {
  width: '100%',
  height: '100%',
  userSelect: 'none'
}

export function GoogleMap({ children, options = {}, ...mapProps }) {
  const google = window?.google

  const googleMapOptions = useMemo(
    () => ({
      mapTypeControl: false,
      styles: mapStyle,
      fullscreenControl: false,
      zoomControl: true,
      clickableIcons: false,
      zoomControlOptions: {
        position: google?.maps?.ControlPosition?.LEFT_TOP || 5
      },
      scaleControl: true,
      streetViewControl: false,
      streetViewControlOptions: {
        position: google?.maps?.ControlPosition?.LEFT_TOP || 5
      },
      ...options
    }),
    [google, options]
  )

  let center = mapProps.center || DEFAULT_CENTER
  let zoom = mapProps.zoom || 13

  return (
    <GoogleMapComponent
      mapContainerStyle={mapContainerStyle}
      options={googleMapOptions}
      zoom={zoom}
      mapTypeControl={false}
      center={center}
      {...mapProps}>
      {children}
    </GoogleMapComponent>
  )
}
