import React, { Component } from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components/macro'
import { getApiUrl } from '../../utils/Api'

const Wrapper = styled.div`
  width: ${(props) => (props.size === 'small' ? '4.2rem' : '11rem')};
  height: ${(props) => (props.size === 'small' ? '4.2rem' : '11rem')};
  border-radius: 50%;
  overflow: hidden;

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
  }
`

export default class Avatar extends Component {
  static propTypes = {
    size: PropTypes.string,
    src: PropTypes.string
  }

  static defaultProps = {
    size: 'small'
  }

  render() {
    const { size, src } = this.props

    let imgSrc = src

    if (imgSrc.startsWith(getApiUrl())) {
      imgSrc += '?width=256&height=256'
    }

    return (
      <Wrapper size={size}>
        <img src={imgSrc} alt="" />
      </Wrapper>
    )
  }
}
