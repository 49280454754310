import { UserAuthWrapper } from 'redux-auth-wrapper'
import { routerActions } from 'react-router-redux'
import moment from 'moment'

function getUserLicenses(user) {
  return (user?.licenses || []).filter((license) => {
    let expires = moment(license.expires)
    return expires.isAfter(moment())
  })
}

export const UserDoesNotHaveLicense = UserAuthWrapper({
  authSelector: (state) => state.auth,
  redirectAction: routerActions.replace,
  predicate: (auth) => {
    let licenses = getUserLicenses(auth?.user)
    return licenses.length === 0
  },
  failureRedirectPath: '/property/new',
  wrapperDisplayName: 'UserDoesntHaveLicense'
})
