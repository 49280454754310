import * as ActionTypes from '../constants/ActionTypes'

const initialState = {
  watcher: null,
  lastWatcher: null,
  myWatchers: null,
  watchResults: null,
  watchPrivateResults: null,
  tempWatcher: null,
  updateTime: 0,
  wizardWatcher: null,
  watchersFetched: 0
}

function WatcherReducer(state = initialState, action) {
  var date = new Date()
  var now = date.getTime()

  if (action) {
    switch (action.type) {
      case ActionTypes.CLEAR_TEMP_WATCHER:
        localStorage.removeItem('tempWatcher')

        return Object.assign({}, state, {
          wizardWatcher: null,
          updateTime: now
        })
      case ActionTypes.SAVE_TEMP_WATCHER:
        let jsonData = typeof action.data === 'string' ? action.data : JSON.stringify(action.data)
        localStorage.setItem('tempWatcher', jsonData)

        return Object.assign({}, state, {
          wizardWatcher: action.data,
          updateTime: now
        })
      case ActionTypes.POST_WATCH:
        return Object.assign({}, state, {
          lastWatcher: action.data,
          updateTime: now
        })
      case ActionTypes.TEMP_WATCH:
        return Object.assign({}, state, {
          tempWatcher: action.data,
          updateTime: now
        })
      case ActionTypes.GET_WATCH:
        return Object.assign({}, state, {
          lastWatcher: action.data,
          updateTime: now
        })
      case ActionTypes.GET_WATCH_ALL:
        return Object.assign({}, state, {
          myWatchers: action.data,
          updateTime: now,
          watchersFetched: now
        })
      case ActionTypes.GET_WATCH_RESULTS:
        return Object.assign({}, state, {
          watchResults: action.data,
          updateTime: now
        })
      case ActionTypes.GET_WATCH_RESULTS_PRIVATE:
        return Object.assign({}, state, {
          watchPrivateResults: action.data,
          updateTime: now
        })
      case ActionTypes.LOGOUT_SUCCESS:
        return Object.assign({}, state, {
          watcher: null,
          lastWatcher: null,
          myWatchers: null,
          watchResults: null,
          tempWatcher: null,
          updateTime: 0,
          watchersFetched: 0
        })
      default:
        return state
    }
  }
}

export default WatcherReducer
