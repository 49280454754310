import React from 'react'

export function IconClose({ className, style, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="342.949 3331.949 12 12"
      className={className}
      style={style}>
      <path
        fill={color || 'white'}
        d="M7.387,5.875,11.465,1.8a1.048,1.048,0,0,0,0-1.569A1.048,1.048,0,0,0,9.9.228L5.818,4.463,1.9.385a1.048,1.048,0,0,0-1.569,0,1.048,1.048,0,0,0,0,1.569L4.406,6.032.328,9.953a1.048,1.048,0,0,0,0,1.569,1.048,1.048,0,0,0,1.569,0L5.975,7.444l4.078,4.078a1.109,1.109,0,0,0,1.569-1.569Z"
        transform="translate(354.824 3331.974) rotate(90)"
      />
    </svg>
  )
}
