import React, { useMemo } from 'react'
import styled from 'styled-components/macro'
import translate from 'counterpart'
import ChatThread from './ChatThread'
import { orderBy } from 'lodash'
import Color from '../../styles/color'

const EmptyWrapper = styled.div`
  text-align: center;
`

const ChatFrontpage = ({ threads }) => {
  const sortedThreads = useMemo(() => orderBy(threads, 'timestamp', 'desc'), [threads])

  return (
    <div style={{ paddingTop: '1.5rem' }}>
      {Object.keys(threads).length === 0 ? (
        <EmptyWrapper key={`thread_empty`}>
          <h1 style={{ margin: 0 }}>{translate('chat.count')}</h1>
          <p key={0}>{translate('chat.nomessages')}</p>
        </EmptyWrapper>
      ) : (
        <>
          <h2 style={{ margin: 0 }} key={`thread_title`}>
            {translate('chat.count')} <span style={{ color: Color.green }}>{threads.length}</span>
          </h2>
          {sortedThreads.map((thread) => {
            const messages = thread.messages

            return (
              <ChatThread
                key={`thread_${thread.id}`}
                user={thread.user}
                messages={messages}
                unreadMessages={thread.unreadMessages}
                timestamp={thread.timestamp}
              />
            )
          })}
        </>
      )}
    </div>
  )
}

export default ChatFrontpage
