import React, { Component } from 'react'
import styled from 'styled-components/macro'
import translate from 'counterpart'
import CommentArea from './CommentArea'

import Color from '../../styles/color'
import Variables from '../../styles/variables'
import Link from '../shared/Link'
import Measure from 'react-measure'

import Underscore from 'lodash'

import Modal from 'react-modal'
import closeButton from '../../images/close-button.svg'
import { getFileUrlWithStorageId } from '../../utils/getFileUrl'
import Vibrant from 'node-vibrant/dist/vibrant.min'

const modalStyles = {
  overlay: {
    backgroundColor: Color.overlayColor,
    zIndex: 900
  },
  content: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90vw',
    height: '90vh',
    right: 'auto',
    bottom: 'auto',
    /*padding: '4rem ' + Variables.gutter + ' ' + Variables.gutter + ' ' + Variables.gutter,*/
    padding: '0',
    border: 'none',
    boxSizing: 'border-box'
  }
}

const CloseButton = styled.div`
  display: block;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 1001;

  > img {
    width: 1rem;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity ${Variables.defaultSpeed} ${Variables.defaultEasing};
  }
`

const bpWidth = 700

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  min-width: 75vw;
  box-sizing: border-box;
  display: flex;
  flex-flow: ${(props) => (props.width < bpWidth ? 'column' : 'row')};

  .modal-img {
    flex: 2;
    align-self: center;
    display: flex;
    height: 100%;
    width: 100%;
    position: relative;
    align-items: center;
    justify-content: center;

    .modal-ctrl {
      position: absolute;
      height: 100%;
      width: 100%;

      .modal-arrows {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: ${(props) => (props.width < bpWidth ? '50%' : '33.333%')};
        text-decoration: none;

        > span {
          font-size: 4rem;
          text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
          color: ${Color.white};
          opacity: 1;
          display: flex;
          width: 100%;
          height: 100%;
          align-self: center;
          align-items: center;
          text-decoration: none;
          text-align: left;
          box-sizing: border-box;
          padding: 1.5rem;
          transition: all 0.25s ease-out;

          i {
            width: 100%;
          }

          &:hover {
            text-shadow: 3px 3px 15px rgba(0, 0, 0, 0.4);
          }
        }

        &.arrow-right {
          left: auto;
          right: 0;

          > span {
            text-align: right;
          }
        }
      }
    }
  }
  .modal-comments {
    flex: 1;
    width: ${(props) => (props.width < bpWidth ? '100%' : '75%')};
    box-sizing: border-box;
    padding: 0 0rem 0;
  }
  .img-responsive {
    max-width: 100%;
    max-height: 100%;
    height: auto;
    display: block;
    object-fit: contain;
    object-position: center center;
  }

  .container--likes {
    margin: 2rem 0 2rem;
    opacity: 0.5;
    font-size: 1.25rem;
    .button--like-disabled {
      vertical-align: middle;
      transition: color 0.25s, opacity 0.25s;
    }
    .button--like {
      cursor: pointer;
      vertical-align: middle;
      transition: color 0.25s, opacity 0.25s;
      &:hover {
        color: ${Color.green};
      }
      &.state-on {
        color: ${Color.green};
        &:hover {
          opacity: 0.7;
        }
      }
    }
    .fa {
      font-size: 2rem;
      margin-right: 1rem;
    }
  }
`

export default class ImageCommentModal extends Component {
  constructor(props) {
    super(props)

    this.closeModal = this.closeModal.bind(this)
    this._onLikeClick = this._onLikeClick.bind(this)
    this._hasLiked = this._hasLiked.bind(this)
    this._numberOfLikes = this._numberOfLikes.bind(this)

    this.state = {
      dimensions: {
        width: -1,
        height: -1
      },
      backgroundColor: '#ffffff'
    }
  }

  componentDidMount() {
    this.getImageBackgroundColor()
  }

  componentDidUpdate(prevProps) {
    if (this.props.imageId && prevProps.imageId !== this.props.imageId) {
      this.getImageBackgroundColor()
    }
  }

  closeModal() {
    if (this.props && this.props.onClose) {
      this.props.onClose()
    }
  }

  _hasLiked() {
    var me = this.props.me
    var addLikeValue = 0

    if (me && me.id) {
      var property = this.props.property?.lastProperty || null

      if (property && property.imageLikes) {
        for (let imageLike of property.imageLikes) {
          if (
            imageLike.entityId.includes('/' + this.props.imageId) &&
            imageLike.userId === me.id &&
            imageLike.value === 1
          ) {
            addLikeValue++
          }
        }
      }
    }

    return addLikeValue
  }
  _numberOfLikes() {
    var addLikeValue = 0
    var property = this.props.property?.lastProperty

    if ((property?.imageLikes || []).length !== 0) {
      for (let imageLike of property.imageLikes) {
        if (imageLike.entityId.includes('/' + this.props.imageId) && imageLike.value === 1) {
          addLikeValue++
        }
      }
    }

    return addLikeValue
  }

  _onLikeClick() {
    var me = this.props.me

    if (me && me.id) {
      var property = this.props.property?.lastProperty || null

      if (property) {
        var addLikeValue = 1

        if ((property?.imageLikes || []).length !== 0) {
          for (let imageLike of property.imageLikes) {
            if (
              imageLike.entityId.includes('/' + this.props.imageId) &&
              imageLike.userId === me.id &&
              imageLike.value === 1
            ) {
              addLikeValue = 0
            }
          }
        }

        if (this.props && this.props.likeSubmit) {
          this.props.likeSubmit(property.id + '/' + this.props.imageId, addLikeValue)
        }
      }
    }
  }

  createLinksArrows(_img, _imgArr) {
    if (_imgArr && _imgArr.length > 1 && _img) {
      var _imageIndex = Underscore.findIndex(_imgArr, { id: _img })
      //console.log(["-- img --", _img, _imgArr,_imageIndex ])
      if (_imageIndex || _imageIndex === 0) {
        //return null;
        var nextIndex = (_imageIndex + 1) % _imgArr.length
        var prevIndex = (_imageIndex + _imgArr.length - 1) % _imgArr.length
        var _nextImg = _imgArr[nextIndex]
        var _prevImg = _imgArr[prevIndex]

        return (
          <div className="modal-ctrl">
            <Link className="modal-arrows arrow-left" to={this.props.showImageF(_prevImg.id, true)}>
              <i className="fa fa-arrow-left" aria-hidden="true" />
            </Link>
            <Link
              className="modal-arrows arrow-right"
              to={this.props.showImageF(_nextImg.id, true)}>
              <i className="fa fa-arrow-right" aria-hidden="true" />
            </Link>
          </div>
        )
      }
    }
    return null
  }

  getImageBackgroundColor = async () => {
    let url = getFileUrlWithStorageId(this.props.imageId, true)

    if (!url) {
      return
    }

    let palette = await Vibrant.from(url).getPalette()
    let hex = palette.LightMuted.hex

    if (hex) {
      this.setState((currentState) => {
        if (currentState.backgroundColor === hex) {
          return null
        }

        return { ...currentState, backgroundColor: hex }
      })
    }
  }

  render() {
    const { width } = this.state.dimensions
    const { me, property } = this.props

    let shouldRender = false

    if (property && property.lastProperty) {
      shouldRender = true
    }

    if (!shouldRender) {
      return null
    }

    let lastProperty = property.lastProperty

    let _this = this

    var entityId = ''
    var _propertyId = null
    var _imageId = null

    if (lastProperty && lastProperty.id) {
      _propertyId = lastProperty.id
    }

    if (this.props.imageId) {
      _imageId = this.props.imageId
    }

    const imageUrl = getFileUrlWithStorageId(_imageId, true)

    if (!imageUrl) {
      return null
    }

    if (_propertyId && _imageId) {
      entityId = _propertyId + '/' + _imageId
    }

    var _linksArrows = this.createLinksArrows(_imageId, this.props.images)
    //console.log(["-- render --", this.props]);
    var _numberOfLikes = 0
    var _hasLiked = 0

    if (lastProperty && lastProperty.imageLikes) {
      _numberOfLikes = this._numberOfLikes()
      _hasLiked = this._hasLiked()
    }

    var onState = _hasLiked > 0 ? 'state-on' : 'state-off'
    var iconH = _hasLiked > 0 ? 'fa-heart' : 'fa-heart-o'

    var starJSX = (
      <div className="container--likes ">
        <span className={`button--like ${onState}`} onClick={this._onLikeClick}>
          <i className={`fa ${iconH}`} aria-hidden="true" />
        </span>
        <span className="likes-number">
          {translate('comment.likes', { count: _numberOfLikes })}
        </span>
      </div>
    )

    if (!(this.props.me && this.props.me.id)) {
      starJSX = (
        <div className="container--likes ">
          <span className={`button--like-disabled ${onState}`}>
            <i className={`fa ${iconH}`} aria-hidden="true" />
          </span>
          <span className="likes-number">
            {translate('comment.likes', { count: _numberOfLikes })}
          </span>
        </div>
      )
    }

    return (
      <Modal
        isOpen={!!this.props.imageId}
        ariaHideApp={false}
        style={modalStyles}
        onRequestClose={() => _this.closeModal()}>
        <CloseButton onClick={() => _this.closeModal()}>
          <img src={closeButton} />
        </CloseButton>
        <Measure
          onMeasure={(dimensions) => {
            this.setState({ dimensions })
          }}>
          <ModalContent width={width || 1200}>
            <div className="modal-img" style={{ backgroundColor: this.state.backgroundColor }}>
              {_linksArrows}
              <img src={imageUrl} className="img-responsive" />
            </div>
            <div className="modal-comments">
              <CommentArea
                me={me}
                star={starJSX}
                onSubmit={this.props.onSubmit}
                entityId={entityId}
                entity={'image'}
                comments={this.props.comments}
                property={this.props.property}
                houseTypeName={this.props.houseTypeName}
                houseSize={lastProperty.size}
                removeComment={this.props.removeComment}
              />
            </div>
          </ModalContent>
        </Measure>
      </Modal>
    )
  }
}
