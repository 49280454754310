import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import { reducer as FormReducer } from 'redux-form'

import AuthReducer from './AuthReducer'
import HouseTypeReducer from './HouseTypeReducer'
import PropertyReducer from './PropertyReducer'
import WatcherReducer from './WatcherReducer'
import UserReducer from './UserReducer'
import AreaReducer from './AreaReducer'
import FavoriteReducer from './FavoriteReducer'
import MessageReducer from './MessageReducer'
import NotificationsReducer from './NotificationsReducer'
import LicenseReducer from './LicenseReducer'
import CommentsReducer from './CommentsReducer'
import RouteReducer from './RouteReducer'

export default combineReducers({
  auth: AuthReducer,
  housetype: HouseTypeReducer,
  routing: routerReducer,
  favourites: FavoriteReducer,
  messages: MessageReducer,
  form: FormReducer,
  area: AreaReducer,
  property: PropertyReducer,
  comments: CommentsReducer,
  watcher: WatcherReducer,
  user: UserReducer,
  notifications: NotificationsReducer,
  license: LicenseReducer,
  route: RouteReducer
})
