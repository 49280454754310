import React, { Component } from 'react'
import styled from 'styled-components/macro'
import Color from '../../styles/color'
import Variables from '../../styles/variables'

const Wrapper = styled.div`
  position: relative;
  width: 100%;

  @media (min-width: ${Variables.bpSmall}) {
    width: auto;
  }

  .dropdown-element {
    border: 1px solid ${Color.border};
    border-radius: 0.45rem;
    padding: 1rem 3rem;
    transition: 0.15s background linear;

    &:hover {
      background: ${Color.lightGray};
    }
  }
  h3 {
    margin: 0;
    padding-right: 2rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  i {
    opacity: 0.25;
    position: absolute;
    right: 2.5rem;
    top: 1.5rem;
  }

  > div {
    cursor: pointer;

    &:hover {
      //opacity: 0.8;
    }
  }
`

const List = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  list-style: none;
  padding: 0;
  margin: 0;
  background-color: ${Color.white};
  width: 100%;
  text-align: left;
  z-index: 100;
  box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.2);

  > li {
    cursor: pointer;
    padding: 1rem;

    &:hover {
      background-color: ${Color.notificationGrey};
    }
  }
`

export default class Dropdown extends Component {
  ref = React.createRef()

  constructor(props) {
    super(props)

    this.state = {
      listVisible: false
    }
  }

  select = (item) => {
    this.props.onChange(item)
  }

  toggleVisible = () => {
    this.setState((current) => ({ listVisible: !current.listVisible }))
  }

  close = (e) => {
    if (this.ref.current.contains(e.target)) {
      return
    }

    this.setState({ listVisible: false })
  }

  componentDidUpdate() {
    let { listVisible } = this.state

    if (listVisible) {
      document.addEventListener('click', this.close)
    } else {
      document.removeEventListener('click', this.close)
    }
  }

  render() {
    const { selected, forceText, list } = this.props
    let selectName = selected.name
    let subName = selected.subname

    if (forceText) {
      selectName = forceText
      subName = null
    }

    const listItems = list.map((list, index) => {
      return (
        <li
          key={index}
          onClick={() => {
            this.select(list)
          }}>
          <div>{list.name}</div>
          <div>{list.subname}</div>
        </li>
      )
    })

    return (
      <Wrapper>
        <div className="dropdown-element" ref={this.ref} onClick={this.toggleVisible}>
          <h3>
            {selectName} <i className="fa fa-caret-down" aria-hidden="true"></i>
          </h3>
          <span>{subName}</span>
        </div>
        {this.state.listVisible && <List>{listItems}</List>}
      </Wrapper>
    )
  }
}
