import React from 'react'
import ReactDOM from 'react-dom'
import { browserHistory } from 'react-router'
import { createStore, applyMiddleware } from 'redux'
import { routerMiddleware } from 'react-router-redux'
import thunk from 'redux-thunk'
import counterpart from 'counterpart'
import fiLocale from './locales/fi'
import enLocale from './locales/en'
import cookie from 'react-cookies'
import { Provider } from 'react-redux'
import moment from 'moment'
import { loadStripe } from '@stripe/stripe-js'
import { STRIPE_KEY } from './constants'
import reducer from './reducers'

import './styles/global-styles'
import ReactGA from 'react-ga'
import { Elements } from '@stripe/react-stripe-js'
import { GlobalStyle } from './styles/global-styles'
import { initSupertokens } from './supertokens'
import App from './App'
import UserDataProvider from './components/state/UserDataProvider'
import { SessionAuth } from 'supertokens-auth-react/recipe/session'

ReactGA.initialize('UA-106970062-1')

counterpart.registerTranslations('fi', fiLocale)
counterpart.registerTranslations('en', enLocale)

var lang = cookie.load('language', { path: '/' })
counterpart.setLocale(lang || 'fi')

moment.locale(lang || 'fi')

const routingMiddleware = routerMiddleware(browserHistory)
const store = createStore(reducer, applyMiddleware(thunk, routingMiddleware))

const stripePromise = loadStripe(STRIPE_KEY)

initSupertokens()

ReactDOM.render(
  <>
    <GlobalStyle />
    <Provider store={store}>
      <SessionAuth requireAuth={false}>
        <UserDataProvider>
          <Elements stripe={stripePromise}>
            <App store={store} />
          </Elements>
        </UserDataProvider>
      </SessionAuth>
    </Provider>
  </>,
  document.getElementById('root')
)
