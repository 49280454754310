import React, { Component } from 'react'
import translate from 'counterpart'

export default class ProfileEmails extends Component {
  constructor(props) {
    super(props)

    this.state = {
      myProfileRequested: false
    }
  }

  componentDidMount() {
    this.requestMyProfile()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ update: true })
  }

  requestMyProfile() {
    if (!this.state.myProfileRequested) {
      this.setState({ myProfileRequested: true })
    }
  }

  render() {
    if (!this.props.auth?.user?.profile?.administrator) {
      return null
    }

    return (
      <div>
        <h1>{translate('testEmails.pageTitle')}</h1>
        <p>{translate('testEmails.description')}</p>
      </div>
    )
  }
}
