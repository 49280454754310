import React from 'react'
import { connect } from 'react-redux'
import BasicPageContainer from './BasicPageContainer'
import BackHeader from '../components/shared/BackHeader'
import Variables from '../styles/variables'
import styled from 'styled-components/macro'
import LoggedOutHeader from '../components/shared/LoggedOutHeader'

const ContentWrapper = styled.div`
  margin-top: ${Variables.navigationHeight};
`

const LoggedOutContainer = ({ children, maxWidth, customTitle, backUrl, backLinkText }) => {
  let backHeader = null

  if (backUrl) {
    backHeader = (
      <BackHeader backUrl={backUrl} customTitle={customTitle} backLinkText={backLinkText} />
    )
  }

  const hasBackUrl = !!backUrl

  return (
    <div className="LoggedOut">
      <LoggedOutHeader />
      <ContentWrapper>
        {backHeader}
        <BasicPageContainer maxWidth={maxWidth} hasBackNav={hasBackUrl}>
          {children}
        </BasicPageContainer>
      </ContentWrapper>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    time: state.auth.time
  }
}

export default connect(mapStateToProps)(LoggedOutContainer)
