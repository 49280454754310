export const ROUTE_CHANGED = 'ROUTE_CHANGED'

export const ADMIN_STATS_RECEIVED = 'ADMIN_STATS_RECEIVED'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN'

export const PROFILE_REQUEST = 'PROFILE_REQUEST'
export const PROFILE_SUCCESS = 'PROFILE_SUCCESS'
export const PROFILE_FAILURE = 'PROFILE_FAILURE'

export const FETCH_START = 'FETCH_START'
export const FETCH_END = 'FETCH_END'

export const HOUSETYPE_GET = 'HOUSETYPE_GET'

export const FILE_UPLOADED = 'FILE_UPLOADED'

export const POST_PROPERTY = 'POST_PROPERTY'
export const GET_PROPERTY = 'GET_PROPERTY'
export const GET_USER_PROPERTIES = 'GET_USER_PROPERTIES'
export const GET_PROPERTY_WATCHES = 'GET_PROPERTY_WATCHES'

export const CLEAR_TEMP_PROPERTY = 'CLEAR_TEMP_PROPERTY'
export const SAVE_TEMP_PROPERTY = 'SAVE_TEMP_PROPERTY'

export const SET_USER = 'SET_USER'

export const GET_AREA = 'GET_AREA'
export const GET_AREA_ALL = 'GET_AREA_ALL'
export const GET_AREA_PRICE = 'GET_AREA_PRICE'

export const TEMP_WATCH = 'TEMP_WATCH'
export const POST_WATCH = 'POST_WATCH'
export const GET_WATCH = 'GET_WATCH'
export const GET_WATCH_ALL = 'GET_WATCH_ALL'
export const GET_WATCH_RESULTS = 'GET_WATCH_RESULTS'
export const GET_WATCH_RESULTS_PRIVATE = 'GET_WATCH_RESULTS_PRIVATE'

export const UPDATE_FAVORITES = 'POST_FAVORITE'

export const CHAT_SENT = 'CHAT_SENT'
export const MESSAGES_RECEIVED = 'MESSAGES_RECEIVED'
export const SINGLE_MESSAGE_RECEIVED = 'SINGLE_MESSAGE_RECEIVED'
export const CHAT_SOCKET_CONNECTION = 'CHAT_SOCKET_CONNECTION'
export const MESSAGE_MARKED_AS_READ = 'MESSAGE_MARKED_AS_READ'

export const GET_FACEBOOK_LICENSE = 'GET_FACEBOOK_LICENSE'
export const GET_LICENSE = 'GET_LICENSE'
export const GET_LICENCE_CANCEL = 'GET_LICENCE_CANCEL'

export const GET_ALL_NOTIFICATIONS = 'GET_ALL_NOTIFICATIONS'
export const GET_NOTIFICATION = 'GET_NOTIFICATION'

export const MARKED_AS_READ = 'MARKED_AS_READ'

export const GET_USER_LICENSES = 'GET_USER_LICENSES'

export const GET_HOUSE_STATS = 'GET_HOUSE_STATS'

export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE'

export const SAVE_TEMP_WATCHER = 'SAVE_TEMP_WATCHER'

export const CLEAR_TEMP_WATCHER = 'CLEAR_TEMP_WATCHER'

export const ACTIVATE_USER = 'ACTIVATE_USER'

export const MESSAGE_SENT = 'MESSAGE_SENT'
export const MESSAGE_SENT_CLEAR = 'MESSAGE_SENT_CLEAR'

export const COMMENTS_GET_SUCCESS = 'COMMENTS_GET_SUCCESS'
export const COMMENTS_GET_ERROR = 'COMMENTS_GET_ERROR'

export const COMMENTS_POST_SUCCESS = 'COMMENTS_POST_SUCCESS'
export const COMMENTS_POST_ERROR = 'COMMENTS_POST_ERROR'

export const COMMENTS_LIKES_GET_SUCCESS = 'COMMENTS_LIKES_GET_SUCCESS'
export const COMMENTS_LIKES_GET_ERROR = 'COMMENTS_LIKES_GET_ERROR'

export const FEATURED_GET_LIKED = 'FEATURED_GET_LIKED'
export const FEATURED_GET_COMMENTED = 'FEATURED_GET_COMMENTED'
export const FEATURED_GET_BY_AREA = 'FEATURED_GET_BY_AREA'
