import React, { Component } from 'react'

import LoggedInContainer from './LoggedInContainer'

import TermsView from '../components/profile/TermsView'
import PropertyFrontpage from '../components/property/PropertyFrontpage'

export default class FreeContainer extends Component {
  getElementByAction(props) {
    const { route } = props

    if (route.action === 'terms') {
      return (
        <LoggedInContainer navigationRoute={route} selected="terms" hideNavigation={false}>
          <TermsView key="termsview" />
        </LoggedInContainer>
      )
    }

    return <PropertyFrontpage />
  }

  render() {
    let obj = this.getElementByAction(this.props)

    return <div>{obj}</div>
  }
}
