import * as ActionTypes from '../constants/ActionTypes'

const initialState = {
  license: null,
  error: null,
  updateTime: 0,
  userLicenses: null,
  forceUpdate: false
}

function LicenseReducer(state = initialState, action) {
  var date = new Date()
  var now = date.getTime()

  if (action) {
    switch (action.type) {
      case ActionTypes.GET_USER_LICENSES:
        return Object.assign({}, state, {
          userLicenses: action.data,
          updateTime: now,
          forceUpdate: false
        })
      case ActionTypes.GET_LICENCE_CANCEL:
        return Object.assign({}, state, {
          license: action.data,
          updateTime: now,
          forceUpdate: now
        })

      case ActionTypes.GET_LICENSE:
        return Object.assign({}, state, {
          license: action.data,
          updateTime: now,
          forceUpdate: false
        })
      case ActionTypes.LOGOUT_SUCCESS:
        return Object.assign({}, state, {
          license: null,
          error: null,
          updateTime: 0,
          forceUpdate: false
        })

      default:
        return state
    }
  }
}

export default LicenseReducer
