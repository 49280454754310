import { useEffect, useState } from 'react'
import { useSessionContext } from 'supertokens-auth-react/recipe/session'
import { fetchCurrentUser } from '../../actions/AuthActions'
import { connect } from 'react-redux'

function UserDataProvider({ children, auth, fetchCurrentUser }) {
  let { doesSessionExist } = useSessionContext()
  let [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!doesSessionExist || isLoading || !!auth.user) {
      return
    }

    setIsLoading(true)

    fetchCurrentUser().then(() => {
      setIsLoading(false)
    })
  }, [doesSessionExist, auth, fetchCurrentUser])

  return children
}

export default connect(
  (state) => ({
    auth: state.auth
  }),
  (dispatch) => ({
    fetchCurrentUser: () => dispatch(fetchCurrentUser())
  })
)(UserDataProvider)
