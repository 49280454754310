import * as ActionTypes from '../constants/ActionTypes'
import { createApiRequest } from '../utils/Api'
import { changeLanguageTo } from './LanguageActions'

export function receiveLogin(user) {
  return {
    type: ActionTypes.LOGIN_SUCCESS,
    user: user
  }
}

export function receiveLogout() {
  return {
    type: ActionTypes.LOGOUT_SUCCESS
  }
}

export function fetchCurrentUser() {
  const URL = '/current-user'

  return (dispatch) => {
    let apiRequest = createApiRequest()

    return apiRequest({ path: URL })
      .then((result) => {
        if (result?.data) {
          dispatch(receiveLogin(result.data))
          dispatch(changeLanguageTo(result.data.profile.language))
          return true
        }

        return false
      })
      .catch((err) => {
        console.log(err)
        return false
      })
  }
}
