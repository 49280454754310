import dateNamesFi from 'date-names/en'

const enLocale = {
  common: {
    // Yleisiä tekstejä
    myHome: 'My home',
    mySearch: 'My search',
    back: 'Back',
    continue: 'Continue',
    step: 'Step',
    cancel: 'Cancel',
    showResults: 'Show results',
    close: 'Close',
    public: 'Public',
    private: 'Hidden',
    onSale: 'For sale',
    edit: 'Edit',
    show: 'Show',
    estimatedPriceDescriptionNotFound:
      'Sorry, we could not find automatic price statistics in your area',
    messageSent: 'Message sent',
    redirectToMessages: 'Go to messages',
    ready: 'Ready',
    update: 'Update',
    addFromMap: 'Add areas from map',
    matches: 'matches',
    properties: 'homes',
    propertiesToSell: 'Homes for sale',
    buy: 'Purchase subscription',
    creditcardNumber: 'Credit card number',
    subscribe: 'Subscribe',
    youHaveAlreadyShared: 'You have already shared to Facebook',
    youHaveAlreadySubscribed: 'You are already a premium member',
    editProfile: 'Edit profile',
    moveMapAddress: 'Center map on area...',
    loginFailed: 'Please check username or password!',
    me: 'Me',
    anonymous: 'Anonymous',
    //
    // Palvelustamme löytyi sinulle asuntoja 12
    matchesDescription: 'We found you some interesting homes',
    deleteWatcher: 'Remove search',
    deleteProperty: 'Remove home',
    deleteProfile: 'Remove profile',
    profileIsDeleted: '* user profile has been deleted',
    areYouSure: 'Are you sure?',
    confirmDelete:
      'Are you sure you want to delete your account? All your information will be deleted, including houses, searches and messages, and this action cannot be reversed.',
    addHeaderImage: 'Add cover',
    removeHeaderImage: 'Remove cover',
    placeholderSize: 'E.g. 75',
    placeholderStart: 'E.g. ',

    addAreas: 'Edit areas',

    addFavourite: 'Livable',
    removeFavourite: 'Livable',

    activation: 'Your account is now activated',

    registeredPleaseWait:
      'We have received your registeration. Check your email and activate Your user account.',

    or: 'tai',
    addProperty: 'New home',
    addSearchwatch: 'New search',
    profileDeleted: 'Your profile is now deleted. You will be redirected to frontpage',
    changeProperty: 'Change home',
    notAuthorizedTitle: 'Sorry, but you are not authorized to enter this page!',
    imageRequirements: 'We support max 15mb JPG or PNG pictures.',
    changesNotSaved: "If you leave the page now, you'll lose unsaved changes. Are you sure?",
    changeLanguage: 'Suomeksi'
  },
  error: {
    registerFailed: 'Registration failed. Check fields.',
    streetNumber: 'Street number is required',
    address: 'Address is required',
    houseType: 'House type is required',
    houseSize: 'House size is required',
    housePrice: 'Price is required',
    houseVisible: 'Visiblity is required',
    watcherVisible: 'Visiblity is required',
    minPrice: 'Minimum price is required',
    maxPrice: 'Maximum price is required',
    noPrice: 'Enter a price. It must be above',
    minSize: 'Minimum size is required',
    maxSize: 'Maximum size is required',
    noArea: 'Select at least one area with a circle.',
    postalCode: 'Postal code is required',
    passwords: 'Passwords do not match',

    useractivation: 'User activation',
    activation: 'Link is deprecated',

    passwordTooShort: 'Password has to be atleast 8 characters long',
    emailInUse: 'Email is already in use',
    genericRegisterError: 'Registration failed. Email might already be in use. Check all fields.',
    mapLoadError: 'Map cannot be loaded right now, sorry.'
  },
  frontpage: {
    registerOrLogin: 'Register / login',
    nav: {
      whatMuuvin: 'What is Muuvin?'
    },
    hero: {
      heading: 'Muuvin shows the way to your new home',
      cta1: 'Try now!'
    },
    statistics: {
      watchers: 'Watches',
      homes: 'Homes',
      users: 'Users',
      matches: 'Matches'
    },
    step1: {
      heading: 'There may be demand for your home',
      stepno: 'Step 1/4',
      content:
        'Discover the demand for homes matching yours, and consider offering it privately only to those exact users looking for similar homes..',
      addressLabel: 'Your address:',
      addressPlaceHolder: 'Streetname, zip',
      addressNumPlaceHolder: 'No.',
      help: 'Don´t worry, this won´t put your home for sale, on display or make your information public. You can later update your information to a public listing, if you´d like to.',
      buttonNext: 'Next',
      errorMessage: 'Please fill in your street address'
    },
    step2: {
      heading: 'What type of house do you live in?',
      stepno: 'Step 2/4',
      buttonNext: 'Next',
      help: 'Don´t worry, this won´t put your home for sale, on display or make your information public. You can later update your information to a public listing, if you´d like to.',
      errorMessage: 'Select your type of housing'
    },
    step3: {
      heading: 'Tell us more about your home',
      stepno: 'Step 3/4',
      content:
        'We compare your home against other homes in your are. You´ll only be matched with users looking for homes matching your specs.',
      sizeLabel: 'What size is your home?',
      sizePlaceHolder: '67',
      sizeUnit: 'm²',
      buttonNext: 'Next',
      help: 'Don´t worry, this won´t put your home for sale, on display or make your information public. You can later update your information to a public listing, if you´d like to.',
      errorMessage: 'Please fill in living area m²'
    },
    step4: {
      heading: 'Price estimate',
      stepno: 'Step 4/4',
      content:
        'Your home info is compared against price ranges in searches. Give us your estimate to connect with users who´s searches matches your home.',
      priceLabel: 'Fill in price/estimate',
      pricePlaceHolder: 'e.g. 320 000',
      priceUnit: '€',
      buttonNext: 'Ready!',
      help: 'Don´t worry, this won´t put your home for sale, on display or make your information public. You can later update your information to a public listing, if you´d like to.',
      priceEstimate: 'Our price estimate is',
      errorMessage: 'Please fill in your price estimate'
    },
    step5: {
      heading: 'Register',
      stepno: 'All set',
      promoText: 'Potential buyers!',
      promoTextNoHits: "hits, register and you'll get a notice when we find you a potential buyer!",
      promoLink: 'Show',
      promoLinkNoHits: 'Register',
      subheading:
        'Muuvin is much more than an average listing service. Muuvin connects homes with users looking for them. Signup to',
      content:
        '• Connect with users looking for homes matching yours\n\n• Get valuable market information on your neighborhood\n\n• Offer your home to the right buyers without contracts',
      register: 'Register / login',
      stats: {
        stat1: 'population',
        stat2: 'average €/m2',
        stat3: 'pricetrend 2018',
        stat4: 'area',
        tooltip1: 'Population of your neighborhood',
        tooltip2:
          'Average €/m2 in your neighborhood. The actual price price will depend on specific location, condition and other features.',
        tooltip3: 'Pricetrend in yout neighborhood',
        tooltip4: 'Neighborhood area'
      }
    },
    apartments: {
      heading: 'Your dream home is waiting for you.'
    },
    lookingApartment: {
      heading: 'Looking for an apartment?',
      box: {
        // heading: "Where is the home of your dreams?",
        heading: 'Stop searching. Start finding.',
        content:
          'Describe the home of your dreams and we will let everybody know what kind of house you are looking for.',
        link: 'Find your new home'
      }
    },
    homeListing: {
      heading: 'Public listings',
      statistics1: 'Homes / Helsinki',
      statistics2: 'Homes / Espoo',
      link: 'Search homes'
    },
    faq: {
      slogan: 'Stop searching. Start finding.',
      heading: 'How does Muuvin work?',
      q1: {
        heading: '1. Information is the key to your new home',
        content:
          'Muuvin is a new information service for anyone interested in buying or selling property.'
      },
      q2: {
        heading: '2. Discover homes matching your criteria',
        content:
          'Muuvin matches homes and possible buyers based on location, type, size and budget.'
      },
      q3: {
        heading: '3. Choose who sees your information',
        content:
          'Your home is only visible to others if you choose to make it a public listing. You´ll still be notified about matching saved searches and you can choose whether you respond to them or not.'
      },
      q4: {
        heading: '4. You won´t find these homes in other services!',
        content:
          'Discover and reach your new dream home with a single search - regardless if it’s publicly listed or not.'
      }
    },
    try: {
      heading: 'Give it a try! Fill in your home information and be part of the revolution.',
      button: 'Try now!'
    },
    terms: 'Terms and Privacy statement'
  },
  pageHeaders: {
    page1: '',
    page2: 'About your home',
    page3: 'Price / estimated value',
    page4: 'Register',
    search: 'Select areas of interest',
    editProperty: 'Edit home',
    addProperty: 'Add home',
    editWatch: 'Edit search',
    addWatch: 'Select area(s)',
    chat: 'Messages',
    backToListing: 'Back to listing'
  },
  license: {
    genericBuyText: 'Show buyers',
    // teksti, mikä näytetään ostokehoitus -sivulla
    descriptionSub: '3 months of free full access!',
    description: 'Upgrade for full access to Muuvin.',

    subscription: {
      buttonText: 'Upgrade',
      description:
        'Upgrade your account to add an unlimited number of properties to Muuvin.\nYou can cancel your subscription at any time.',
      paymentPerMonth: '14.95 €',
      paymentPerMonthText: '/month'
    },
    noThanks: {
      buttonText: 'No thanks, one property is enough'
    }
  },
  wizard: {
    whatIsMuuvin: {
      whatIsMuuvin: 'What is Muuvin?',
      whatTitle: 'What is Muuvin?',
      whatParagraph1:
        'Muuvin is a new information service for everyone interested in real estate. Muuvin connects homes and buyers by location, type of house, size and budget. Your home details are not publicly listed, unless you want to. You’ll still get notified of interested buyers and can then decide whether to act upon them or not. As a buyer you will reach all homes matching your criteria, regardless if they are actively for sale or not.',
      whatParagraph2: 'Fill in your home details and challenge the outdated housing market with us.'
    },
    welcomeback: {
      header:
        'Tervetuloa takaisin %(name)s, olemme vihdoin avanneet uuden Muuvin -palvelun, toivomme että sinäkin hyödyt siitä.',
      header1: 'Welcome back ',
      header2: ", We've finally opened new  Muuvin service, hope you like it.",
      descriptionRich: 'Kotialueesi ',
      descriptionRichEnd:
        'vaikuttaa kiinnostavalle! Rekisteröitymällä saat halutessasi yhteyden käyttäjiin, jotka alueellasi etsivät kotiasi vastaavaa asuntoa.',
      descriptionPoor: 'Kotialueellasi ',
      descriptionPoorEnd:
        'ei vielä ole riittävästi käyttäjiä tilastojen näyttämiseksi. Rekisteröidy kuitenkin käyttäjäksi, niin saat meiltä heti tiedon kun joku etsii alueellasi kotiasi vastaavaa asuntoa.'
    },
    page1: {
      //      smallHeader: 'Type your address:',
      header: 'What if buyers are already waiting for your home for sale',
      description: "Try it, it's free and only takes a minute",
      imlookingforhouse: 'Looking for a home? Try here',
      find: 'Find homes'
    },
    page2: {
      description: 'Your home info is compared against searches in your area.',
      error: 'Please fill in all fields'
    },
    page3: {
      description:
        "Your home info is compared against price ranges in searches. Give us your estimate to connect with users who's searches matches your home.",
      estimatedPrice: 'Price estimate',
      estimatedPriceDescription:
        'You can use the price range based on the average value in your neighbourhood',
      estimatedPriceDescriptionEnd:
        " (note that this doesn't take into considerarion any home-specific characteristics)",
      estimatedPriceDisclamer:
        " Don't worry, this doesn't put your home for sale or disclose any of the information you've given. You can choose to make your property information visible later (listing)",
      error: 'Fill in price/estimate',
      price: 'Price'
    },
    page4: {
      descriptionRich: 'Your neighbourhood ',
      descriptionRichEnd:
        'seems interesting! Sign up to connect with users looking for homes matching yours!',
      descriptionPoor: 'Your neighbourhood ',
      descriptionPoorEnd:
        "doesn't yet have enough users to show statistics. Sign up now and we will inform you immediately when someone is looking for a home in your area matching yours!",

      bullets: {
        info: 'Did you know?',
        description: "Muuvin connects homes and buyers. When you register you'll get",
        bullet1: 'connected with users that are looking for new home',
        bullet2: 'precious information about areas',
        bullet3: 'possibility to offer your home to right buyer'
      }
    },
    searchWatchers: 'Searches',
    views: 'Views',
    clicked: 'Shows',
    favourites: 'Favorited',
    numberOfLikes: 'Likes',
    numberOfComments: 'Comments',
    potentialBuyers: 'Possible buyers',
    population: 'Population',
    area_km2: 'Area size km²',
    midPrice: 'Midprice €/m²',
    priceTrend: 'Trend 2018',
    registerLogin: 'Sign up or Login',
    error: {
      streetNumber: 'Streetnumber is required',
      houseType: 'House type is required',
      houseSize: 'House size is required',
      housePrice: 'Price is required'
    }
  },
  form: {
    login: {
      // rekisteröintiosio
      letsgetstarted: 'Welcome to Muuvin',
      sloganregister: 'We connect homes and buyers',

      // wizard -etusivu
      hello: 'Welcome back!',
      slogan: '- Team Muuvin.',

      email: 'Email',
      username: 'Username/email',
      password: 'Password',

      login: 'Login',
      firstName: 'First name',
      lastName: 'Last name',
      register: 'Register',
      namePlaceholder: 'Anonymous',

      'log-in': 'Login',

      google: 'Login with Google',
      facebook: 'Login with Facebook',
      socialmedialogin: 'Login with your social media account.',

      or: 'or',

      notyetregistered: 'Not a user yet?',
      registerhere: 'Signup here',
      alreadyuser: 'Already got an account?',
      loginhere: 'Login!',

      forgot: 'Forgot password?',
      requestpasswordreset: 'Reset password',
      requestpassword: 'Request new password',

      createUsingEmail: 'Register using email'
    },
    forgot: {
      emailSent: 'Check your email to reset password.',
      changePassword: 'Change password',
      passwordHasBeenReset: 'Password updated'
    }
  },

  title: {
    home: 'Home',
    house: 'Home'
  },
  house: {
    postal_code: 'Postal code',
    add_postal_code: 'Add postal code',
    Address: 'Address',
    myApartmentAddress: 'Address',
    AddressInput: 'E.g. Aleksanterinkatu 10, Helsinki',
    Nro: 'Nr',
    Type: 'Type',
    myApartmentSize: 'Size',
    priceRange: 'Price range',
    isForSaleHeader: 'My home is for sale',
    SetForSaleText: '',
    isForSale: "I'm ready to sell my home (the price will be displayed)",
    publicNotForSale: 'Public but not for sale / coming soon (price not shown)',
    publicAndForSale: 'For sale',
    apartmentSize: 'Size',
    estimatedPrice: 'Price estimate',
    price: 'Hinta',
    squareMeters: 'Square meters',
    ThisWillNotPutYourHouseOnSale: "Don't worry, this won't put your house up for sale!",
    ShowInfo: 'Show info',
    Submit: 'Update information',
    SubmitSave: 'Save information',
    MoreInfo: 'Additional information',
    AddFile: 'Add file',
    Public: 'Public',
    private: 'Private',
    TellAboutHouse: 'Please tell us about your home',
    DescribeYourHouse: 'Please describe how do you live',
    watchVisibility: 'Search visibility',
    watchVisibilityDescription:
      "Choose Public to access all homes matching your dream criteria regardless if they're actively for sale or not. Describe your search preferences to encourage owners of homes not yet for sale to consider selling.",
    SetPublicHeader: 'Choose visibility to other users',
    SetPublicText:
      "Choose Private if you're not actively selling, but still interested in hearing about searches matching your home.",
    HeaderImage: 'Cover image',
    externalurl: 'Public listing',
    externalurltext: 'Are you selling your home elsewhere? Paste link here',
    notForSalePublicPriceText: 'Check it out and bid!',
    views: 'Viewed',
    listed: 'Listed',
    favourites: 'Favourite-listed',
    numberOfLikes: 'Likes',
    numberOfComments: 'Comments',
    fbShare: 'Share on Facebook'
  },
  searchWatcher: {
    TellAboutWhatYouSearch: 'Please describe what you are looking for',
    Public: 'Public, show to homes matching my search',
    Private: "Private, I'm just interested in actual listings",
    areas: 'Areas',
    searchWatcherResultHeading: {
      common:
        '%(count)s results matching your search criteria were found in the areas you selected',
      private:
        'and in addition, %(count)s owners of private homes will see what you are looking for.'
    },
    messageForAnonymous: 'Did you find some cool homes? Sign up to save your searches!'
  },
  houseType: {
    DetachedHome: 'Detached home',
    ApartmentBuilding: 'Apartment',
    TerracedHouse: 'Terraced house',
    SemiDetachedHome: 'Semi-detached home'
  },
  shared: {
    fetch: {
      loading: 'Please wait...'
    },
    navigation: {
      home: 'Home',
      explore: 'Explore',
      house: 'Home',
      logout: 'Logout',
      messages: 'Messages',
      notifications: 'Notifications',
      profile: 'Profile',
      property: 'Homes',
      searchwatcher: 'Watches',
      favourites: 'Favorites'
    }
  },

  comment: {
    messageSeller: 'Message seller',
    messageBuyer: 'Message buyer',
    messagePlaceholder: 'Write your message...',
    commentPlaceholder: 'Write a comment...',
    sendMessage: 'Send message',
    sendPrivateMessage: 'Send private message',
    message: 'Message',
    privateMessage: 'Private message',
    messageNotEmpty: "Oops, you wouldn't leave an empty message, would you?",
    submit: 'Send',
    remove: 'Remove comment',
    likes: {
      zero: 'No likes yet',
      one: '1 like',
      other: '%(count)s likes'
    }
  },
  dashboard: {
    property: {
      noLicense: 'Unfortunately you can only add one property',
      myPropertyTitle: 'My property',
      infoAboutProperty: 'Info about this home',
      infoAboutPropertySeller: 'Seller',
      noDescriptionProperty: 'No info',

      // Asunnon tiedot
      addTitle: "Add your home to find out it's demand and much more",

      // Asunnon lisäysseliteteksti
      addDescription:
        " We connect homes and buyers based om location, type, size and price-range. Your home details aren't visible to anyone unless you turn your info into a listing. You'll still be notified about searches matching your home and can decide for yourself if you want to do something with that informationa. Muuvin allows you to follow the market and consider selling without contracts or agents.",
      addButton: 'Add home',
      potentialProperties: 'Matching homes',
      potentialBuyers: 'Matching searches',
      potentialBuyers2: 'Users that are interested in',
      potentialPropertiesPublic: 'Public homes',
      potentialPropertiesHidden: 'Private homes',
      potentialPropertiesHiddenInfo:
        'There are also private homes that are not yet available for sale. Encourage them to consider giving their home a descriptive message. The more accurate the description, the more relevant homes the users can offer you.',
      potentialPropertiesHiddenDescription:
        "With this description You'll get more matches from seller",

      potentialBuyersTitle: 'These users are searching apartment that is just like your home',
      potentialBuyersText:
        'Click on users for more information. You can contact them or even offer them your home.',

      potentialBuyersTextWhenWeHaveThem:
        'Save your property and we will inform you about potential buyers when they are found.',
      potentialBuyersTextWhenNotProAccount: 'Your property has potential buyers.',
      potentialBuyersSaveAndSeeResults: 'Save your property and see potential buyers.',

      houseIsCurrently: 'Your house is currently',

      noDescription:
        'You have not yet set up a description to reach the private homes that match your search',
      addItNow: 'Add it now',
      modifyItNow: 'Modify',

      contactSeller: 'Contact seller',
      contactOwner: 'Contact owner'
    },
    searchWatcher: {
      circles: 'Circles',
      noLicense: 'Unfortunately you can save only one search',
      yourWatcher: 'Your search',
      // Hakuvahdin lisäyssivu
      addTitle: 'More than your average property search',
      addDescription:
        "Dreaming of a new home? Wouldn't it be convenient to reach all homes matching your dreams with one simple search, regardless if they're active for sale or not.",
      addButton: 'Specify/edit search',
      matches: 'matches',
      matchesDescription: {
        zero: "We didn't find any houses yet.",
        one: 'We did find you a house.',
        other: 'We did find you %(count)s houses.'
      },
      properties: 'houses',
      matchesHiddenDescription: {
        zero: 'We are getting more insteresting homes in upcoming days.',
        one: "We found you a house that isn't public yet. Save your search to get informed on new homes.",
        other:
          "There are also %(count)s houses on sale that aren't public yet. Save your search to get your hands on them."
      },
      potentialPropertiesTextWhenWeHaveThem:
        'Potential properties are visible here when they are found.',
      potentialPropertiesSaveAndSeeResults: 'Save your search and see matching properties.',
      searchWatcherCirclesInstructionsIntro:
        'Select areas from the map that interest you using circles. You may use 10 circles per seatch watcher.',
      searchWatcherCirclesInstructionsMouse:
        "Select the center of a circle by pressing and holding on the map for a moment, until a circle appears. Adjust it's size by dragging the pointer. Remove a circle by clicking it.",
      searchWatcherCirclesInstructionsTouch:
        "Select the center of a circle by pressing and holding on the map for a moment, until a circle appears. Adjust it's size by dragging your finger. Remove a circle by tapping it."
    },
    favourites: {
      // Suosikit sivun etusivu
      addTitle: 'Favorite',
      addDescription: 'Make dreaming easier by collecting all your favorite homes in one list.',
      addButton: 'Browse homes'
    }
  },
  profile: {
    // Profiilin muokkaussivu
    image: 'Profile image',
    firstName: 'First name',
    lastName: 'Last name',
    email: 'Email',
    emailVerified: 'Email is verified',
    emailNotVerified: 'Verify your email',
    phoneNumber: 'Phone number',
    phoneNumberPlaceholder: '(Phone number not set)',
    password: 'Password',
    password2: 'Confirm password',
    changePassword: 'Change password',
    languageLabel: 'Language',
    edit: 'Edit profile',
    userSinceStart: 'Member since',
    userSinceEnd: '',
    facebookPassport: 'Facebook connected',
    doFacebookPassport: 'Connect Facebook',
    googlePassport: 'Google connected',
    doGooglePassport: 'Connect Google',
    save: 'Save changes ',
    changeProfilePicture: 'Take picture',
    removeProfilePicture: 'Remove picture',
    cancel: 'cancel',
    facebookConnect: {
      buttonText: 'Bring info from Facebook',
      description:
        "We won't post anything on your behalf, we'll just fetch your profile picture and name."
    },
    license: {
      license: 'Membership',
      licenses: 'Memberships',
      expires: 'expires',
      confirm: 'Are you sure you want to cancel your membership?',
      cancel: 'Cancel membership',
      cancelled: 'Cancelled',
      paid: 'Paid membership'
    },
    language: {
      fi: 'Finnish',
      en: 'English'
    },
    currencies: {
      eur: 'Euro',
      usd: 'USD'
    },
    metric: {
      metric: 'Metric',
      mile: 'Imperial'
    },
    passport: {
      facebook: 'Facebook connected',
      google: 'Google connected'
    },
    errors: {
      GENERAL_ERROR_PHONE_INVALID:
        'Phone number is invalid. THe number must starts with the country code',
      GENERAL_ERROR_PHONE_NON_STRING: 'Phone number is invalid',
      PROFILE_ERROR: 'Profile error',
      PHONE_NUMBER_ALREADY_EXISTS_ERROR:
        'The phone number could not be added. Please use a different number.',
      EMAIL_ALREADY_EXISTS_ERROR: 'This email could not be added. Please use a different email.'
    },
    messages: {
      newLoginMessage: 'Sign up or log in now to see who is searching for a home matching yours!'
    }
  },
  chat: {
    count: 'Threads',
    format: 'DD.MM.YY [at] HH:mm',
    nomessages: 'No messages.'
  },
  counterpart: {
    names: dateNamesFi,

    formats: {
      date: {
        default: '%a, %e %b %Y',
        long: '%A, %B %o, %Y',
        short: '%b %e'
      },

      time: {
        default: '%H:%M',
        long: '%H:%M:%S %z',
        short: '%H:%M'
      },

      datetime: {
        default: '%a, %e %b %Y %H:%M',
        long: '%A, %B %o, %Y %H:%M:%S %z',
        short: '%e %b %H:%M'
      }
    }
  },
  subscription: {
    title: 'You need to upgrade your account',
    description:
      'Upgrade your account to access all Muuvin features. You can cancel your subscription at any time.'
  },
  menu: {
    dashboard: 'Dashboard',
    property: 'Homes',
    searchwatcher: 'Searches',
    favourites: 'Favorites',
    messages: 'Messages',
    notifications: 'Notifications',
    profile: 'Profile and settings',
    stats: 'Statistics',
    logout: 'Logout',

    terms: 'Terms and Privacy statement'
  },
  watcher: {
    SubmitSave: 'Save search',
    Submit: 'Update search',
    name: 'Search watcher name'
  },
  notification: {
    houseFound: 'New home matching your search watcher!',
    watchFound: 'Your home matched a new search watcher!',
    favorite: 'Your home was favorited!',
    liked: 'You got a like!',
    commented: 'You got a comment!',
    empty: 'No new notifications'
  },
  buyer: {
    matchingProperty: 'Your home matching this users search'
  },
  stats: {
    pageTitle: 'Tilastoja palvelusta',
    service: 'Palvelun tiedot',
    properties: 'Asuntojen tiedot',
    userCount: 'Käyttäjää',
    houseCount: 'Asuntoa',
    matchCount: 'Osumia',
    watchCount: 'Hakuvahtia',
    messageCount: 'Viestiä',
    housesThatHaveMatches: 'Asuntoja, joilla hakuosumia',
    cumulativeWatchMatches: 'Kumulatiivisia hakuosumia',
    houseTypeCounts: {
      DetachedHome: 'Omakotitaloa',
      ApartmentBuilding: 'Kerrostaloa',
      TerracedHouse: 'Rivitaloa',
      SemiDetachedHome: 'Paritaloa'
    }
  },
  testEmails: {
    pageTitle: 'Testaa sendgridin maileja',
    description: 'Mailit lähtevät profiilisin sähköposteihin'
  },
  notFound: {
    pageTitle: '404 - oh my, you got lost',
    description: 'Maybe the link was outdated?'
  },
  notFoundProperty: {
    pageTitle: '404 - oh my, you got lost',
    description: 'This apartment is gone, or maybe sold? :O'
  },
  explore: {
    title: 'Explore',
    search: 'Search a city',
    searchNoHits: 'No homes, try to search different city',
    showAll: 'Show all',
    showMore: 'Show more',
    showLess: 'Go back',
    mostLikedHomes: 'Most liked homes',
    mostCommentedHomes: 'Most discussed homes',
    hottestAreas: 'Hottest areas',
    numOfPublicHouses: {
      zero: 'No public homes',
      one: '1 public home',
      other: '%(count)s public homes'
    },
    numOfPrivateHouses: {
      zero: 'No private homes',
      one: '1 private home',
      other: '%(count)s private homes'
    }
  },
  leads: {
    chooseOptions: 'Choose one or many',
    needProfessionalHelp: 'Do you need professional assistance?',
    clickHere: 'Click here!',
    tellUsWhatYouNeed: 'Tell us how we can help you',
    send: 'Send',
    thankYou: 'Thanks for contacting us!',
    loading: 'Wait a moment...',
    formError:
      'Unfortunately, your contact request failed. Try again or email us at homes@muuvin.com.',
    optionBarter: 'I need help with selling',
    optionFloorPlan: 'I need a good floor plan',
    optionPricing: 'I need help with pricing',
    optionPhotos: 'I need good photos'
  }
}

export default enLocale
