import React, { useState } from 'react'
import translate from 'counterpart'
import Measure from 'react-measure'
import styled from 'styled-components/macro'
import { orderBy } from 'lodash'
import Color from '../../../styles/color'
import Variables from '../../../styles/variables'
import Link from '../../shared/Link'
import Pill from '../../shared/Pill'

import * as TextUtils from '../../../utils/Text'
import { roundPrice, getCurrencySymbol } from '../../../actions/AreaActions'
import { StaticGoogleMap } from '../../googlemap/staticMapImage'
import { getFileUrlWithStorageId } from '../../../utils/getFileUrl'

const Wrapper = styled.div`
  position: relative;
  background-position: 50% 50%;
  background-size: cover;
  height: 0;
  padding-bottom: ${(props) => (props.width < 500 ? '57.69%' : '30%')};
  background-color: ${Color.border};
  color: ${Color.white};
  border-radius: ${Variables.borderRadius};
  overflow: hidden;

  > a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
  }
`

const PlaceholderMapImage = styled(StaticGoogleMap)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`

const GradientBackground = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
`

const PrivateTag = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
`

const PropertyInfo = styled.div`
  position: absolute;
  left: ${Variables.gutter};
  right: ${Variables.gutter};
  bottom: ${Variables.gutter};
  z-index: 10;

  h3 {
    line-height: 1;
    margin: 1rem 0 0;
    font-size: ${(props) =>
      props.width < parseInt(Variables.bpSmall, 10)
        ? Variables.h2FontSizeMobile
        : Variables.h2FontSize};
  }

  p {
    font-size: 1.5rem;
    margin: 0.25rem 0 0;
    font-weight: 100;
  }
`

const PropertyBlock = ({
  property,
  imageSize = 512,
  urlPrefix = '',
  houseType,
  showPrivateTag,
  dontLink
}) => {
  let [dimensions, setDimensions] = useState({ width: -1, height: -1 })
  const { width } = dimensions

  let bgStyle = {}
  let privateTag

  let images = orderBy(property?.images || [], 'order', ['asc'])

  if (images.length !== 0) {
    // TODO: resize to imageSize
    bgStyle = {
      backgroundImage: `url("${getFileUrlWithStorageId(images[0].id, true)}")`
    }
  }

  let streetName = ''
  let city = ''

  if (property.locationParts) {
    streetName = property.locationParts.streetName
    city = property.locationParts.city
  }

  let houseTypeJSX = null
  let houseSizeJSX = null

  if (houseType?.housetypes) {
    TextUtils.getById(property.houseType, houseType.housetypes)
    let houseTypeObj = houseType.housetypes.find((item) => item.id === property.houseType)

    if (houseTypeObj) {
      houseTypeJSX = <span>{translate('houseType.' + houseTypeObj.name)}</span>
    }
  }

  if (property.size) {
    houseSizeJSX = (
      <span>{property.size ? TextUtils.str_replace('.', ',', property.size) + 'm²' : ''}</span>
    )
  }

  if (showPrivateTag) {
    privateTag = (
      <PrivateTag>
        <Pill text={property.private ? translate('common.private') : translate('common.public')} />
      </PrivateTag>
    )
  } else {
    privateTag = (
      <PrivateTag>
        <Pill
          text={
            property.isForSale
              ? `${roundPrice(property.price, 10)} ${getCurrencySymbol(property.currency)}`
              : translate('house.notForSalePublicPriceText')
          }
        />
      </PrivateTag>
    )
  }

  return (
    <Measure onMeasure={(dimensions) => setDimensions(dimensions)}>
      <div className="propertyBlock--block">
        <Wrapper style={bgStyle} width={width}>
          {(images.length === 0 && property.geolocation && (
            <PlaceholderMapImage
              width={600}
              height={200}
              lat={property.geolocation.lat}
              lng={property.geolocation.lng}
              zoom={15}
            />
          )) ||
            null}
          <GradientBackground />
          {privateTag}
          <PropertyInfo width={width}>
            <h3>
              {streetName}, {city}
            </h3>
            <p>
              {houseTypeJSX}&nbsp;{houseSizeJSX}
            </p>
          </PropertyInfo>
          {!dontLink && (
            <Link className="propertyLink" to={`${urlPrefix}/property/${property.id}`} />
          )}
        </Wrapper>
      </div>
    </Measure>
  )
}

export default PropertyBlock
