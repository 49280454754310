import React, { useCallback } from 'react'
import { getAvatarUrl } from '../../actions/UserActions'
import translate from 'counterpart'
import Spacer from '../shared/Spacer'
import Link from '../shared/Link'
import LicensesList from './LicensesList'
import moment from 'moment'
import Avatar from '../shared/Avatar'
import styled from 'styled-components/macro'
import { signOut } from 'supertokens-auth-react/recipe/thirdpartypasswordless'

const ProfileWrapper = styled.div`
  padding-top: 1.5rem;
`

const ProfileFrontpage = ({ onLogout, auth, cancelSubscription, license }) => {
  let logoutHandler = useCallback(async () => {
    await signOut()
    onLogout()
    window.location.href = '/'
  }, [onLogout])

  let user = auth?.user?.profile

  if (!user) {
    return null
  }

  return (
    <ProfileWrapper>
      <Avatar src={getAvatarUrl(user)} size="big" />
      <h1>{user.name}</h1>
      <p>
        {translate('profile.userSinceStart')} {moment(user.createdAt).format('MMMM Y')}{' '}
        {translate('profile.userSinceEnd')}
      </p>
      <Spacer />
      <Link to="profile/edit" type="outline">
        {translate('profile.edit')}
      </Link>
      <Spacer />
      {/*<Link to="auth-info" type="link">
        Auth info
      </Link>*/}
      <Spacer multiplier={2} />
      <LicensesList auth={auth} cancelSubscription={cancelSubscription} license={license} />
      {user && user.administrator && (
        <div>
          <Spacer multiplier={2} />
          <p>
            <Link to="/admin/stats">{translate('menu.stats')}</Link>
          </p>
        </div>
      )}
      <Spacer multiplier={2} />
      <p>
        <a href="#" onClick={logoutHandler}>
          {translate('menu.logout')}
        </a>
      </p>
    </ProfileWrapper>
  )
}

export default ProfileFrontpage
