import SuperTokens from 'supertokens-auth-react'
import ThirdPartyPasswordless from 'supertokens-auth-react/recipe/thirdpartypasswordless'
import Session from 'supertokens-auth-react/recipe/session'
import {
  REACT_APP_AUTH_NAME,
  REACT_APP_AUTH_API_DOMAIN,
  REACT_APP_AUTH_WEB_DOMAIN,
  REACT_APP_AUTH_API_PATH,
  REACT_APP_AUTH_WEB_PATH,
  REACT_APP_AUTH_GATEWAY_PATH
} from './constants'
import colors from './styles/color'
import Variables from './styles/variables'
import logoSvg from './images/logo-app.svg'
import translate from 'counterpart'
import MessageBanner from './components/shared/MessageBanner'

const SupertokensHeaderOverride = ({ DefaultComponent, ...props }) => {
  return (
    <div>
      <img style={{ width: '10rem', marginBottom: '1.5rem' }} src={logoSvg} />
      <MessageBanner
        style={{
          marginBottom: '2rem',
          marginTop: '1rem',
          marginLeft: '-5rem',
          marginRight: '-5rem'
        }}
        canClose={false}
        message={translate('profile.messages.newLoginMessage')}
        messageKey="newLogin"
      />
      <DefaultComponent {...props} />{' '}
    </div>
  )
}

export function initSupertokens() {
  SuperTokens.init({
    appInfo: {
      appName: REACT_APP_AUTH_NAME,
      apiDomain: REACT_APP_AUTH_API_DOMAIN,
      websiteDomain: REACT_APP_AUTH_WEB_DOMAIN,
      apiBasePath: REACT_APP_AUTH_API_PATH,
      websiteBasePath: REACT_APP_AUTH_WEB_PATH,
      apiGatewayPath: REACT_APP_AUTH_GATEWAY_PATH
    },
    recipeList: [
      ThirdPartyPasswordless.init({
        contactMethod: 'EMAIL_OR_PHONE',
        emailVerificationFeature: {
          mode: 'REQUIRED'
        },
        signInUpFeature: {
          defaultCountry: 'FI',
          providers: [ThirdPartyPasswordless.Google.init(), ThirdPartyPasswordless.Facebook.init()]
        },
        getRedirectionURL: async (context) => {
          if (context.action === 'SUCCESS') {
            if (context.isNewUser) {
              // user signed up
              return '/profile/edit'
            }

            if (context.redirectToPath) {
              // we are navigating back to where the user was before they authenticated
              return context.redirectToPath
            } else {
              // user signed in
              return '/'
            }
          }

          return undefined
        },
        palette: {
          error: colors.red,
          textTitle: colors.basic,
          textLabel: colors.basic,
          textInput: colors.basic,
          textPrimary: colors.basic,
          textLink: colors.green,
          success: colors.green,
          primary: colors.green
        },
        style: {
          container: {
            fontFamily: `'${Variables.fontFamily}', 'Helvetica Neue', Arial, sans-serif`
          }
        },
        override: {
          components: {
            PasswordlessUserInputCodeFormHeader_Override: SupertokensHeaderOverride,
            ThirdPartyPasswordlessHeader_Override: SupertokensHeaderOverride
          }
        }
      }),
      Session.init()
    ]
  })
}
