import * as ActionTypes from '../constants/ActionTypes'
import { uniqBy } from 'lodash'

const initialState = {
  messages: [],
  updateTime: 0,
  socket: null,
  io: null,
  sent: false
}

function MessageReducer(state = initialState, action) {
  let date = new Date()
  let now = date.getTime()

  if (action) {
    switch (action.type) {
      case ActionTypes.MESSAGE_SENT:
        return Object.assign({}, state, {
          sent: true
        })
      case ActionTypes.MESSAGE_SENT_CLEAR:
        return Object.assign({}, state, {
          sent: false
        })
      case ActionTypes.MESSAGE_MARKED_AS_READ:
        let nextMessages = [...(state.messages || [])]
        let updateMessages = action.data

        for (let msg of updateMessages) {
          let messageIndex = nextMessages.findIndex((message) => message.id === msg.id)

          if (messageIndex !== -1) {
            nextMessages.splice(messageIndex, 1, msg)
          }
        }

        return Object.assign({}, state, {
          messages: nextMessages,
          updateTime: now
        })
      case ActionTypes.CHAT_SOCKET_CONNECTION:
        return Object.assign({}, state, {
          socket: action.socket,
          io: action.io
        })
      case ActionTypes.MESSAGES_RECEIVED:
        return Object.assign({}, state, {
          messages: action.data || [],
          updateTime: now
        })
      case ActionTypes.SINGLE_MESSAGE_RECEIVED:
        let receivedMessage = action.data.data
        let uniqMessages = uniqBy([...(state.messages || []), receivedMessage], 'id')

        return Object.assign({}, state, {
          messages: uniqMessages,
          updateTime: now
        })
      case ActionTypes.LOGOUT_SUCCESS:
        return Object.assign({}, state, {
          messages: [],
          updateTime: 0
        })
      default:
        return state
    }
  }
}

export default MessageReducer
