export const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY
export const REACT_APP_GOOGLE_APIKEY = process.env.REACT_APP_GOOGLE_APIKEY
export const DEFAULT_CENTER = { lat: 60.192059, lng: 24.945831 }

export const REACT_APP_AUTH_API_DOMAIN = process.env.REACT_APP_AUTH_API_DOMAIN
export const REACT_APP_AUTH_WEB_DOMAIN = process.env.REACT_APP_AUTH_WEB_DOMAIN
export const REACT_APP_AUTH_API_PATH = process.env.REACT_APP_AUTH_API_PATH
export const REACT_APP_AUTH_WEB_PATH = process.env.REACT_APP_AUTH_WEB_PATH
export const REACT_APP_AUTH_GATEWAY_PATH = process.env.REACT_APP_AUTH_GATEWAY_PATH || undefined
export const REACT_APP_AUTH_NAME = process.env.REACT_APP_AUTH_NAME

export const REACT_APP_API_URL = process.env.REACT_APP_API_URL
export const REACT_APP_SOCKET_IO_PATH = process.env.REACT_APP_SOCKET_IO_PATH
export const REACT_APP_FILESTORAGE_URL = process.env.REACT_APP_FILESTORAGE_URL

export const MAX_CIRCLES = parseInt(process.env.REACT_APP_MAX_CIRCLES || '10', 10)
export const MAX_CIRCLE_RADIUS = parseInt(process.env.REACT_APP_MAX_CIRCLE_RADIUS || '5000', 10)
