import React, { Component } from 'react'
import styled from 'styled-components/macro'
import translate from 'counterpart'
import Variables from '../../styles/variables'

import StatBox from '../shared/StatBox'

const StatsWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: -${Variables.gutterHalf};

  > div {
    margin: ${Variables.gutterHalf};
    width: calc(50% - ${Variables.gutter});
  }
`

export default class ProfileStats extends Component {
  constructor(props) {
    super(props)

    this.state = {
      myProfileRequested: false
    }
  }

  componentDidMount() {
    this.requestMyProfile()
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ update: true })
  }

  requestMyProfile() {
    if (!this.state.myProfileRequested) {
      this.setState({ myProfileRequested: true })
      if (this.props.getAppStats) {
        this.props.getAppStats()
      }
    }
  }

  render() {
    if (!this.props.auth?.user) {
      return null
    }

    const stats = this.props.auth.adminStats

    if (!stats) {
      return null
    }

    return (
      <div>
        <h1>{translate('stats.pageTitle')}</h1>

        <h3 className="infoTitle">{translate('stats.service')}</h3>
        <StatsWrapper>
          <StatBox title={stats.userCount || '–'} description={translate('stats.userCount')} />
          <StatBox
            title={stats.messageCount || '–'}
            description={translate('stats.messageCount')}
          />
          <StatBox title={stats.watchCount || '–'} description={translate('stats.watchCount')} />
          <StatBox title={stats.houseCount || '–'} description={translate('stats.houseCount')} />
          <StatBox
            title={stats.housesThatHaveMatches || '–'}
            description={translate('stats.housesThatHaveMatches')}
          />
          <StatBox
            title={stats.cumulativeWatchMatches || '–'}
            description={translate('stats.cumulativeWatchMatches')}
          />
        </StatsWrapper>

        <h3 className="infoTitle">{translate('stats.properties')}</h3>
        <StatsWrapper>
          <StatBox
            title={stats.houseTypeCounts.ApartmentBuilding || '–'}
            description={translate('stats.houseTypeCounts.ApartmentBuilding')}
          />
          <StatBox
            title={stats.houseTypeCounts.DetachedHome || '–'}
            description={translate('stats.houseTypeCounts.DetachedHome')}
          />
          <StatBox
            title={stats.houseTypeCounts.SemiDetachedHome || '–'}
            description={translate('stats.houseTypeCounts.SemiDetachedHome')}
          />
          <StatBox
            title={stats.houseTypeCounts.TerracedHouse || '–'}
            description={translate('stats.houseTypeCounts.TerracedHouse')}
          />
        </StatsWrapper>
      </div>
    )
  }
}
