import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Measure from 'react-measure'
import styled, { css } from 'styled-components/macro'
import translate from 'counterpart'

import Color from '../../styles/color'
import Variables from '../../styles/variables'
import Link from './Link'

const BP = 400
const Wrapper = styled.div`
  display: flex;
  flex-wrap: ${(props) => (props.width < BP ? 'wrap' : 'no-wrap')};
  justify-content: ${(props) => (props.width < BP ? 'center' : 'space-between')};
  align-items: center;
  text-align: ${(props) => (props.width < BP ? 'center' : 'left')};
  border-radius: ${Variables.borderRadius};
  padding: ${Variables.gutterHalf} ${Variables.gutter};

  ${(props) =>
    props.layout === 'default' &&
    css`
      background: ${Color.white};
      border: 0.1rem solid ${Color.border};
      color: ${Color.blackish};
    `}
`

const Img = styled.div`
  height: 5rem;
  max-height: 100%;

  img {
    display: block;
    height: 100%;
    width: auto;
  }
`

const Count = styled.div`
  font-size: ${Variables.h2FontSizeMobile};
  font-weight: 700;
  padding: 0 1rem;
`

const Text = styled.div`
  padding: ${(props) => (props.width < BP ? '1rem' : '0 2rem 0 1rem')};
  flex: 1;
  flex-basis: ${(props) => (props.width < BP ? '100%' : 'auto')};
`

export default class PromoButton extends Component {
  constructor(props) {
    super(props)

    this.state = {
      dimensions: {
        width: -1,
        height: -1
      }
    }
  }

  static propTypes = {
    text: PropTypes.string,
    link: PropTypes.string,
    linkText: PropTypes.string,
    count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    icon: PropTypes.string,
    layout: PropTypes.string
  }

  static defaultProps = {
    layout: 'default',
    linkText: translate('boxButton.linkText')
  }

  render() {
    const { text, link, linkText, count, icon, layout } = this.props
    const { width } = this.state.dimensions

    return (
      <Measure
        onMeasure={(dimensions) => {
          this.setState({ dimensions })
        }}>
        <Wrapper layout={layout} width={width}>
          {icon && (
            <Img>
              <img src={icon} alt="" />
            </Img>
          )}
          {count && <Count>{count}</Count>}
          {text && <Text width={width}>{text}</Text>}
          {link && (
            <Link to={link} type="button" size="small">
              {linkText}
            </Link>
          )}
        </Wrapper>
      </Measure>
    )
  }
}
