import React, { Component } from 'react'
import translate from 'counterpart'

let currentYear = new Date().getFullYear()

export default class TermsView extends Component {
  constructor(props) {
    super(props)

    this.buildPage = this.buildPage.bind(this)
  }

  buildPage(notifications) {
    var returnData = null
    var lang = translate.getLocale()
    if (lang === 'fi') {
      returnData = this.fi()
    } else {
      returnData = this.en()
    }
    return returnData
  }

  en() {
    // english licenses and terms
    return (
      <div>
        <h1>Muuvin terms of use {currentYear}</h1>
        <h3>TERMS OF USE</h3>
        <p>Terms of Service ("Terms")</p>
        <p>Last updated: July 4, 2022</p>
        <p>
          Please read these Terms of Service ("Terms", "Terms of Service") carefully before using
          the https://www.muuvin.com website (the "Service") operated by Muuvin Solutions Ltd ("us",
          "we", or "our").
        </p>
        <p>
          Your access to and use of the Service is conditioned on your acceptance of and compliance
          with these Terms. These Terms apply to all visitors, users and others who access or use
          the Service.
        </p>
        <p>
          By accessing or using the Service you agree to be bound by these Terms. If you disagree
          with any part of the terms then you may not access the Service.
        </p>

        <h3>Accounts</h3>
        <p>
          When you create an account with us, you must provide us information that is accurate,
          complete, and current at all times. Failure to do so constitutes a breach of the Terms,
          which may result in immediate termination of your account on our Service.
        </p>
        <p>
          You are responsible for safeguarding the password that you use to access the Service and
          for any activities or actions under your password, whether your password is with our
          Service or a third-party service.
        </p>
        <p>
          You agree not to disclose your password to any third party. You must notify us immediately
          upon becoming aware of any breach of security or unauthorized use of your account.
        </p>

        <h3>Links To Other Web Sites</h3>
        <p>
          Our Service may contain links to third-party web sites or services that are not owned or
          controlled by Muuvin.
        </p>
        <p>
          Muuvin has no control over, and assumes no responsibility for, the content, privacy
          policies, or practices of any third party web sites or services. You further acknowledge
          and agree that Muuvin shall not be responsible or liable, directly or indirectly, for any
          damage or loss caused or alleged to be caused by or in connection with use of or reliance
          on any such content, goods or services available on or through any such web sites or
          services.
        </p>
        <p>
          We strongly advise you to read the terms and conditions and privacy policies of any
          third-party web sites or services that you visit.
        </p>

        <h3>Termination</h3>
        <p>
          We may terminate or suspend access to our Service immediately, without prior notice or
          liability, for any reason whatsoever, including without limitation if you breach the
          Terms.
        </p>
        <p>
          All provisions of the Terms which by their nature should survive termination shall survive
          termination, including, without limitation, ownership provisions, warranty disclaimers,
          indemnity and limitations of liability.
        </p>
        <p>
          We may terminate or suspend your account immediately, without prior notice or liability,
          for any reason whatsoever, including without limitation if you breach the Terms.
        </p>
        <p>
          Upon termination, your right to use the Service will immediately cease. If you wish to
          terminate your account, you may simply delete your profile. All your personal data
          provided will then be completely removed from our database.
        </p>
        <p>
          All provisions of the Terms which by their nature should survive termination shall survive
          termination, including, without limitation, ownership provisions, warranty disclaimers,
          indemnity and limitations of liability.
        </p>

        <h3>Governing Law</h3>
        <p>
          These Terms shall be governed and construed in accordance with the laws of Finland,
          without regard to its conflict of law provisions.
        </p>
        <p>
          Our failure to enforce any right or provision of these Terms will not be considered a
          waiver of those rights. If any provision of these Terms is held to be invalid or
          unenforceable by a court, the remaining provisions of these Terms will remain in effect.
          These Terms constitute the entire agreement between us regarding our Service, and
          supersede and replace any prior agreements we might have between us regarding the Service.
        </p>

        <h3>Changes</h3>
        <p>
          We reserve the right, at our sole discretion, to modify or replace these Terms at any
          time. If a revision is material we will try to provide at least 30 days notice prior to
          any new terms taking effect. What constitutes a material change will be determined at our
          sole discretion.
        </p>
        <p>
          By continuing to access or use our Service after those revisions become effective, you
          agree to be bound by the revised terms. If you do not agree to the new terms, please stop
          using the Service.
        </p>

        <h3>Contact Us</h3>
        <p>If you have any questions about these Terms, please contact us.</p>

        <p>&nbsp;</p>
        <hr />
        <p>&nbsp;</p>

        <h1>Muuvin privacy statement {currentYear}</h1>

        <h3>PRIVACY STATEMENT</h3>
        <p>Personal Data Act (FI-Henkilötietolaki, [523/1999] 10 §)</p>

        <h3>1. Registrar’s information</h3>
        <p>
          Muuvin Solutions Ltd, Business code FI 3123215-4
          <br />
          FIN-00120 Helsinki
          <br />
          <a href="mailto:info@muuvin.com">info@muuvin.com</a>
        </p>

        <h3>2. Contact person related to Register</h3>
        <p>
          Lars Carlsson
          <br />
          <a href="mailto:lasse@muuvin.com">lasse@muuvin.com</a>
        </p>

        <h3>3. Name of Register</h3>
        <p>Muuvin service register</p>

        <h3>4. Purpose for processing personal data</h3>
        <p>Customer Relationships and Service Experience development</p>

        <h3>5. Data contents of Register</h3>
        <ul>
          <li>User information (name, email address)</li>
          <li>Other user information e.g. profile and social media links</li>
          <li>Housing information (address, house type, size and price estimate)</li>
          <li>Apartment search information (search areas, house types, size and price range)</li>
        </ul>

        <h3>6. Information sources</h3>
        <p>
          The sources of information are information provided by the users and information freely
          available on internet.
        </p>

        <h3>7. Transfers of data and transfer of data outside the EU or EEA</h3>
        <p>
          In the event that the disclosure of the User's information becomes extraneous, Users will
          be asked to give their consent in prior. Data will not be transferred outside the European
          Union or the European Economic Area.
        </p>

        <h3>8. Principles of Registry protection</h3>
        <p>
          Personal data is kept confidential. The Data Network Services used and the hardware on
          which the registry is located are properly protected.
        </p>
      </div>
    )
  }
  //'
  fi() {
    // finnish licenses and terms

    return (
      <div>
        <h1>Muuvin käyttöehdot {currentYear}</h1>

        <h3>Käyttöehdot</h3>
        <p>Käyttöehdot ("Ehdot")</p>
        <p>Viimeisin päivitys: 4. heinäkuuta 2022</p>
        <p>
          Lue nämä käyttöehdot ("Ehdot", "Käyttöehdot") ennen kuin käytät Muuvin Solutions Oy:n
          ("meitä", “me”, “meidän”) ylläpitämää https://www.muuvin.com -palvelua ("Palvelu").
        </p>
        <p>
          Palvelun käyttöoikeus ja käytön ehtona ovat näiden ehtojen hyväksymisen ja noudattaminen.
          Nämä ehdot koskevat kaikkia vierailijoita, käyttäjiä ja muita käyttäjiä.
        </p>
        <p>
          Käyttäessäsi Palvelua tai käytät sitä sitoudut noudattamaan näitä ehtoja. Jos et ole samaa
          mieltä minkä tahansa ehdon kohdan kanssa, et saa käyttää Palvelua.
        </p>

        <h3>Tili</h3>
        <p>
          Kun luot tilin Palveluun, sinun on annettava meille tietoja, jotka ovat aina oikeita,
          täsmällisiä ja ajankohtaisia. Mikäli näin ei ole, merkitsee se ehtojen rikkomista, joka
          voi johtaa tilin välittömään lopettamiseen palvelussamme.
        </p>
        <p>
          Sinä olet kaikissa tapauksissa vastuussa Palvelussa käyttämäsi salasanan suojaamisesta
        </p>
        <p>
          Sitoudut olemaan paljastamatta salasanaasi kolmannelle osapuolelle. Sinun on ilmoitettava
          meille välittömästi, tietoosi tulevasta tietoturvaongelmasta tai tilisi luvattomasta
          käytöstä.
        </p>

        <h3>Linkit muihin verkkosivustoihin</h3>
        <p>
          Palvelumme voi sisältää linkkejä kolmannen osapuolen sivustoihin tai palveluihin, joita
          Muuvin ei omista tai valvo.
        </p>
        <p>
          Muuvinilla ei ole hallintaa eikä vastuuta minkään kolmannen osapuolen verkkosivuston tai
          -palvelun sisällöstä, tietosuojakäytännöistä tai käytännöistä. Lisäksi olet tietoinen ja
          hyväksyt, että Muuvin ei ole vastuussa suoraan tai epäsuorasti vahingoista tai
          menetyksistä, joita aiheutuu tai väitetään johtuvan sellaisesta sisällöstä tai tavaroiden
          tai palveluiden käytöstä tai luottamisesta tällaisia ​​verkkosivustoja tai palveluita,
          jotka liittyvät kolmansiin osapuoliin.
        </p>
        <p>
          Suosittelemme, että tutustut kolmansien osapuolten sivustoja tai palveluita koskeviin
          ehtoihin ja tietosuojakäytäntöihin.
        </p>

        <h3>Käytön päättyminen</h3>
        <p>
          Voimme lopettaa tai keskeyttää pääsyn Palveluun välittömästi ilman ennakkoilmoitusta tai
          vastuuta mistä tahansa syystä, mukaan lukien rajoituksetta, jos loukkaat ehtoja.
        </p>
        <p>
          Kaikki Ehtojen määräykset, jotka luonteensa johdosta päättävät tai vastaavat
          irtisanomisesta, ovat jatkuvia, mukaan lukien rajoituksetta omistusoikeudelliset
          säännökset, takuuvastuut, korvausvastuu ja vastuun rajoitukset.
        </p>
        <p>
          Päättymishetkellä oikeus käyttää Palvelua lopetetaan välittömästi. Jos haluat lopettaa
          tilisi, voit tilin asetuksissa poistaa profiilisi. Silloin kaikki annetut henkilötiedot
          poistetaan kokonaan tietokannasta.
        </p>

        <h3>Sovellettava lainsäädäntö</h3>
        <p>
          Näitä ehtoja säännellään ja tulkitaan Suomen lakien mukaisesti, ottaen huomioon
          lainvalintasäännökset.
        </p>
        <p>
          Näiden ehtojen oikeuksien tai tarjoamisvelvoitteiden noudattamatta jättämistä ei pidetä
          oikeuksista luopumisena. Jos tuomioistuin pitää jonkin näiden ehtojen määräyksestä
          pätemättömiä tai täytäntöönpanokelvottomia, muut ehtojen määräykset pysyvät kuitenkin
          voimassa. Nämä ehdot muodostavat koko Palvelumme välisen sopimuksen meille, ja korvaavat
          kaikki aikaisemmat sopimukset, joita meillä on Palvelun suhteen.
        </p>

        <h3>Muutokset</h3>
        <p>
          Pidätämme oikeuden yksipuoleisen harkintamme mukaan muokata tai korvata näitä ehtoja
          milloin tahansa. Jos muutos on tarpeellinen, pyrimme ilmoittmaan muutoksesta vähintään 30
          päivää ennen uusien ehtojen voimaantuloa.
        </p>
        <p>
          Jatkamalla Palvelun käyttämistä tai käyttämistä sen jälkeen, kun tarkistukset tulevat
          voimaan, sitoudut noudattamaan tarkistettuja ehtoja. Jos et hyväksy uusia ehtoja, sinun
          tulee lopettaa Palvelun käyttö.
        </p>

        <h3>Yhteydenotto</h3>
        <p>Ota meihin yhteyttä jos sinulla on kysyttävää näistä ehdoista.</p>

        <p>&nbsp;</p>
        <hr />
        <p>&nbsp;</p>

        <h1>Muuvin rekisteriseloste {currentYear}</h1>

        <h2>REKISTERISELOSTE</h2>
        <p>Henkilötietolaki (523/1999) 10 §</p>
        <h3>1. Rekisterinpitäjän tietot</h3>
        <p>
          Muuvin Solutions Oy, y-tunnus 3123215-4
          <br />
          FIN-00120 Helsinki
          <br />
          <a href="mailto:info@muuvin.com">info@muuvin.com</a>
        </p>

        <h3>2. Yhteyshenkilö rekisteriä koskevissa asioissa</h3>
        <p>
          Lars Carlsson
          <br />
          <a href="mailto:lasse@muuvin.com">lasse@muuvin.com</a>
        </p>

        <h3>3. Rekisterin nimi</h3>
        <p>Muuvin palvelurekisteri</p>

        <h3>4. Henkilötietojen käsittelyn tarkoitus</h3>
        <p>Asiakassuhteiden ja palvelun käyttökokemuksen kehittäminen</p>

        <h3>5. Rekisterin tietosisältö</h3>
        <ul>
          <li>Käyttäjätiedot (nimi, sähköpostiosoite)</li>
          <li>Muut asiakastiedot mm. profiili- ja sosiaalisen median linkitykset</li>
          <li>Asuntotiedot (osoite, talotyyppi, koko ja hinta-arvio)</li>
          <li>Asuntohakutiedot (hakualueet, talotyypit, koko- ja hinta-haitari)</li>
        </ul>

        <h3>6. Säännönmukaiset tietolähteet</h3>
        <p>
          Tietolähteinä ovat käyttäjän itse ilmoittamat ja internetistä vapaasti saatavilla olevia
          tietoja.
        </p>

        <h3>
          7. Tietojen säännönmukaiset luovutukset ja tietojen siirto EU:n tai ETA:n ulkopuolelle
        </h3>
        <p>
          Mikäli Käyttäjien tietojen luovuttaminen ulkopuolelle tulee ajankohtaiseksi, Käyttäjiltä
          pyydetään etukäteen asianmukainen suostumus. Tietoja ei siirretä Euroopan Unionin tai
          Euroopan talousalueen ulkopuolelle.
        </p>

        <h3>8. Rekisterin suojauksen periaatteet</h3>
        <p>
          Henkilötiedot säilytetään luottamuksellisina. Käytetyt tietoverkkopalvelut ja laitteisto,
          jolla rekisteri sijaitsee, ovat suojattu asianmukaisesti.
        </p>

        <p>&nbsp;</p>
        <hr />
        <p>&nbsp;</p>

        <h1>Tietosuojaseloste</h1>
        <p>OLEMME PÄIVITTÄNEET TIETOSUOJAKÄYTÄNTÖÄMME 25.2.2021</p>
        <h3>Muuvinin palveluita koskevat henkilötietojen suojaperiaatteet</h3>
        <p>
          Muuvin Solutions Oy (jäljempänä “Muuvin”) on sitoutunut suojaamaan palvelujensa käyttäjien
          yksityisyyttä ja noudattaa toiminnassaan tietosuoja- ja muita lakeja sekä hyviä
          tietosuojakäytäntöjä. Tämä tietosuojalauseke kuvaa Muuvinin henkilötietojen keräämistä ja
          käsittelyä koskevat käytännöt. “Henkilötieto” tarkoittaa tietoa, joka on yhdistettävissä
          tiettyyn henkilöön.
          <br />
          Henkilötietojen käsittelyn ensisijaisena perusteena on Muuvinin palvelujen käyttäminen,
          käyttäjän muu asiallinen yhteys Muuvinin palveluihin tai käyttäjän suostumus.
          <br />
          Muuvinin sivustoihin kuuluvat <a href="https://muuvin.com/">muuvin.com</a> ja{' '}
          <a href="https://muuvin.fi/">muuvin.fi</a> sivustot.
          <br />
          Mikäli et hyväksy henkilötietojesi käsittelyä tämän tietosuojapolitiikan mukaisesti, älä
          käytä Muuvinin sivustoja tai palveluja.
        </p>
        <h3>Mitä tietoa keräämme?</h3>
        <p>
          Muuvin voi kerätä käyttäjistään tässä tietosuojalausekkeessa määriteltyjen
          käyttötarkoitusten kannalta tarpeellisia tietoja. Käyttötarkoitus määrittää, minkälaista
          tietoa käyttäjistä kerätään eri tilanteissa.
          <br />
          Henkilötietojasi voidaan kerätä palvelun käytön yhteydessä, lähettäessäsi viestejä
          Muuvinin palvelun kautta, jatkaessasi Muuvinin kautta aloittamaasi keskustelua
          sähköpostitse toisen käyttäjän tai yhteystyökumppanin kanssa, kilpailujen, kampanjoiden
          tai tutkimusten yhteydessä sekä muutoin asioidessasi kanssamme tai käyttäessäsi Muuvinia.
          <br />
          Henkilötietojasi voidaan kerätä myös kolmansien osapuolten ylläpitämistä palvelun
          kehittämisen tai operoimisen kannalta relevanteista rekistereistä.
        </p>
        <p>Muuvin kerää muun muassa alla mainittuihin ryhmiin kuuluvia henkilötietoja.</p>
        <p>
          1. Muuvinille antamasi tai tallentamasi tiedot
          <br />
          Saatamme kysyä sinulta palvelun käytön yhteydessä tietoja, joita voit halutessasi antaa
          Muuvinille:
        </p>
        <ul>
          <li>Nimi</li>
          <li>Salasana tai muu yksilöivä tunnus</li>
          <li>Sähköpostiosoite ja muita yhteystietoja (esim. osoite, puhelinnumero)</li>
          <li>
            Kotiasi tai muun ilmoittamasi asuntoon liittyvät tiedot (osoite, talotyyppi, koko ja
            hinta, sekä vapaavalintaiset tiedot esim. esittelyteksti ja kuvat)
          </li>
          <li>
            Asuntohakuun liittyvät tiedot (alue-, talotyyppi-, koko- ja hintarajaus sekä
            vapaatekstikenttä)
          </li>
          <li>Viestintä muiden käyttäjien kanssa</li>
          <li>
            Liitettyjen sosiaalisten verkkopalveluiden yhdistämisessä tulleet tiedot (social login:
            Facebook ja/tai Google)
          </li>
        </ul>
        <p>
          Lisäksi voidaan kerätä muita palvelujen toimittamiseen, asiakasviestintään ja käyttäjien
          tunnistamiseen liittyviä tietoja. Voimme kerätä myös ns. demografiatietoja esim. ikä,
          sukupuoli, kieli, asuinaluetta kuvaavat tiedot, sekä mieltymyksiäsi, toivomuksiasi ja
          tietoja antamistasi suostumuksista sekä muita vastaavia tietoja, joita tarvitsemme mm.
          tuotteiden ja palvelujen kehittämiseen ja palvelujemme kohdentamiseen.
        </p>
        <p>
          2. Palvelujen käytöstä havainnoidut ja johdetut tiedot
          <br />
          Saatamme kerätä sinusta ja Muuvinin palvelujen käytöstä seuraavia tietoja:
        </p>
        <ul>
          <li>
            Verkkopalvelujen analytiikkajärjestelmien keräämät tiedot (ks. Käyttäjän tunnistus ja
            evästeiden käyttö)
          </li>
          <li>Asiakasviestinnän tiedot, esim. tiedot linkkien klikkauksista</li>
          <li>Verkkosivu tai palvelu, jolta on siirrytty Muuvinin verkkosivulle tai palveluun</li>
          <li>
            Laitetunnisteet, kuten päätelaitteen malli ja yksilöllinen laite- ja/tai evästetunniste
          </li>
          <li>Tiedonkeruun kanava: internetselain, mobiiliselain, sovellus sekä selaimen versio</li>
          <li>IP-osoite</li>
          <li>Päätelaitteen käyttöjärjestelmä</li>
          <li>Istuntotunniste, istunnon aika ja kesto</li>
        </ul>
        <p>
          Vaikka osaa Muuvinin palveluista voi selailla ilman, että sinun täytyy rekisteröityä tai
          muuten tunnistautua, selaimesi toimittaa Muuvinille tiettyjä tietoja, esimerkiksi
          käyttämäsi IP-osoitteen, selailun ajankohdan, miltä sivuilta olet tulossa, millä sivuilla
          vierailet, mitä linkkejä käytät ja mitä sisältöjä olet katsonut. Muuvinin tuotteiden ja
          palvelujen käytön yhteydessä voi syntyä myös muuta teknisluonteista tietoa, jota keräämme.
          Esimerkiksi liittymäsi puhelinnumero, palvelun käyttöajankohta ja muita vastaavia palvelun
          käyttöä kuvaavia tietoja voi siirtyä Muuvinille osana normaalia palvelun käyttöä. Muuvin
          käyttää internet-sivustoillaan evästeitä ("cookie"), joiden käytöstä annetaan lisätietoja
          alempana kohdassa "Käyttäjän tunnistus ja evästeiden käyttö".
        </p>
        <p>
          3. Asiakkuuteen ja sopimussuhteeseen liittyvät tiedot
          <br />
          Keräämme tietoja ja tilastoja palvelun käyttöön liittyvistä seikoista. Keräämme myös
          palvelujen maksamiseen ja laskutukseen liittyviä tietoja, kuten laskutusosoite ja
          luottokorttitiedot, sekä luottotietojen tarkastamiseen liittyviä tietoja ja muut vastaavia
          taloudellisia tietoja.
        </p>
        <h3>Miksi käsittelemme henkilötietoja?</h3>
        <p>
          Käsittelemme henkilötietojasi alla määriteltyihin yhteen tai useampiin tarkoituksiin
          liittyen.
        </p>
        <p>
          1. Tuotteiden ja palvelujen tarjoaminen
          <br />
          Henkilö- ja tunnistetietojasi voidaan käyttää palvelun toimittamiseen ja toteuttamiseen,
          tilausten hallinnointiin ja muiden vastaavien velvoitteiden täyttämiseen sekä pyyntöihisi
          ja kysymyksiisi vastaamiseen. Henkilötietojasi voidaan käyttää myös käyttäjien
          tunnistamiseen viestien välitykseen, tietoturvasta huolehtimiseen sekä väärinkäytösten
          ehkäisyyn ja tutkintaan, lain sallimissa rajoissa. Henkilötietojasi ei luovuteta Muuvinin
          ulkopuolelle käytettäväksi markkinointiin ilman suostumustasi.
        </p>
        <p>
          2. Tuotteiden ja palvelujen kehittäminen
          <br />
          Henkilötietojasi voidaan käyttää Muuvinin tuotteiden ja palvelujen kehittämiseen. Käytämme
          ensisijaisesti tilastollisia ja muita vastaavia tietoja, joista yksittäistä käyttäjää ei
          voida tunnistaa. Henkilötietojasi voidaan käyttää myös tuotteittemme ja palvelujemme
          kohdentamiseen, esimerkiksi voidaksemme näyttää sinulle räätälöityja relevantteja
          sisältöjä. Voimme tehdä tuotteiden ja palvelujen kehittämisen edellyttämiä tilastoja ja
          yhdistää eri tuotteiden ja palvelujen yhteydessä kerättyjä tietoja siltä osin, kuin tiedot
          on kerätty samaan käyttötarkoitukseen.
        </p>
        <p>
          3. Asiakasviestintä ja markkinointi
          <br />
          Henkilötietojasi voidaan käyttää lain sallimissa rajoissa Muuvinin, sen edustamien
          yhteistyökumppanien tuotteiden ja palvelujen markkinointiin, suoramarkkinointiin,
          markkinatutkimuksiin sekä asiakasviestintään, esimerkiksi informoidaksemme sinua
          tuotteisiimme ja palveluihimme liittyvistä asioista. Voimme myös tehdä
          asiakastyytyväisyyttä koskevia tutkimuksia.
        </p>
        <h3>Alaikäisiä koskevien tietojen käsittely</h3>
        <p>
          Pyrimme välttämään henkilötietojen keräämistä alle 18-vuotiaista henkilöistä. Koska
          palvelujen käyttäjien ikää voi olla vaikea todentaa, myös alle 18 vuotiaita koskevia
          tietoja voi päätyä tietokantoihimme. Toimintatapanamme on, etteivät alaikäiset (Suomessa
          alle 18-vuotiaat henkilöt) voi tehdä ostoksia tai muita oikeustoimia tällä sivustolla
          ilman vanhempansa tai laillisen holhoojansa suostumusta.
        </p>
        <h3>Luovutammeko henkilötietoja?</h3>
        <p>
          Muuvin ei myy, lainaa tai muutoin luovuta henkilötietojasi muuten kuin seuraavissa
          tilanteissa:
        </p>
        <p>
          1. Suostumus.
          <br />
          Voimme luovuttaa henkilötietojasi siinä määrin kuin olet antanut meille siihen
          suostumuksesi. Jotkin palvelumme voivat olla luonteeltaan sellaisia, että niissä
          julkaistaan käyttäjien henkilötietoja, esimerkiksi yhteisöpalvelut ja sellaiset palvelut,
          joiden avulla käyttäjät julkaisevat omia sisältöjään.
        </p>
        <p>
          2. Alihankkijat
          <br />
          Henkilötietojasi voidaan luovuttaa Muuvinin alihankkijoille siinä määrin, kun ne
          osallistuvat tässä politiikassa kuvattujen käyttötarkoitusten toteuttamiseen.
        </p>
        <p>
          3. Kansainväliset tietojen siirrot
          <br />
          Muuvin pyrkii toteuttamaan palvelut ja käsittelemään henkilötietosi ensisijaisesti EU- tai
          ETA-alueella sijaitsevia toimijoita ja palveluja hyödyntäen. Muuvinin palveluja saatetaan
          kuitenkin joissakin tapauksissa toteuttaa myös muualla sijaitsevia toimijoita, palveluja
          ja palvelimia käyttäen ja tällöin henkilötietojasi saatetaan siirtää eri maiden välillä.
          Tällaiset siirrot voivat sisältää henkilötietojen luovutuksia EU- tai ETA-alueen
          ulkopuolelle sellaisiin maihin, joiden henkilötietojen käsittelyä koskeva lainsäädäntö
          poikkeaa Suomen lain vaatimuksista, esimerkiksi Yhdysvaltoihin. Muuvin huolehtii
          tällöinkin henkilötietojesi suojan riittävästä tasosta muun muassa sopimalla
          henkilötietojen luottamuksellisuuteen ja käsittelyyn liittyvistä asioista lainsäädännön
          edellyttämällä tavalla, esimerkiksi EU:n komission hyväksymiä mallisopimuslausekkeita
          käyttäen ja muutenkin siten, että henkilötietojen käsittely tapahtuu tämän
          tietosuojalausekkeen mukaisesti.
        </p>
        <p>
          4. Pakottavan lainsäädännön edellyttämät luovutukset
          <br />
          Henkilötietojasi voidaan luovuttaa lakiin perustuvan, esimerkiksi viranomaisen esittämän
          vaatimuksen perusteella.
        </p>
        <p>
          5. Yritysjärjestelyt
          <br />
          Mikäli Muuvin Solutions Oy myy liiketoimintansa tai osan siitä, voidaan kyseiseen
          liiketoimintaan liittyviä henkilötietoja siirtää liiketoiminnan mukana uudelle
          omistajalle.
        </p>
        <h3>Miten huolehdimme tietojen ajantasaisuudesta?</h3>
        <p>
          Pyrimme kohtuullisin keinoin pitämään hallussamme olevat henkilötiedot oikeellisina ja
          poistamalla tarpeettomia tietoja sekä päivittämällä vanhentuneita tietoja.
          <br />
          Muuvinin palvelut mahdollistavat sen, että käyttäjä hallinnoi itse omia tietojaan.
          Jokaisen käyttäjän velvollisuutena on olla toimittamatta Muuvin Solutions Oy:lle
          virheelliseksi tietämäänsä tietoa.
        </p>
        <h3>Mitä teemme suojataksemme henkilötietojasi?</h3>
        <p>
          Kaikkeen henkilötietojen luovuttamiseen liittyy riskejä eikä mikään tekniikka tai prosessi
          ole täysin turvallinen. Muuvin pyrkii käyttämään asianmukaisia teknisiä ja organisatorisia
          keinoja henkilötietojen suojaamiseksi oikeudetonta pääsyä, luovuttamista ja muuta
          oikeudetonta käsittelyä vastaan, ottaen huomioon tietojen luonteen, tekniset
          mahdollisuudet tietojen suojaamiseen, tietoihin kohdistuvan uhkan sekä tietojen
          suojaamisen kustannukset.
          <br />
          Tällaisia keinoja voivat olla mm. palomuurien, salaustekniikoiden, turvallisten
          laitetilojen käyttö, kulunvalvonta, rajoitettu käyttöoikeuksien myöntäminen ja käytön
          valvonta, henkilötietojen käsittelyyn osallistuvien henkilöiden ohjeistaminen,
          alihankkijoiden huolellinen valinta sekä sopimuksellisten ja muiden vastaavien keinojen
          käyttö. Käytämme standardien mukaisia tekniikoita liiketapahtumiemme luottamuksellisuuden
          turvaamiseksi.
        </p>
        <h3>Käyttäjän tunnistus ja evästeiden käyttö</h3>
        <p>
          Muuvin voi käyttää sivustoillaan evästeitä eli "cookieta". Evästeet ovat pieniä
          tiedostoja, jotka lähetetään päätelaitteeseesi sivustoltamme, ja joiden avulla
          tyypillisesti kerätään tiettyjä päätelaitetta koskevia tietoja, esimerkiksi käyttäjän
          IP-osoite, päätelaitteen käyttöjärjestelmän tietoja, selaimen tyyppi ja tieto siitä, miltä
          sivustolta käyttäjä on tulossa Muuvinin palveluun. Evästeiden käyttö mahdollistaa
          käyttäjille tiettyjä palveluja: esimerkiksi käyttäjän täytyy syöttää salasanansa vain
          kerran istunnon aikana tai käyttäjän ei tarvitse täyttää syötää uudestaan aiemmin
          syöttämiään tietoja. Evästeitä käytetään myös sivustomme liikenteen analysointiin
          palvelujemme kehittämiseksi.
        </p>
        <p>
          Voimme käyttää myös ns. web beaconeja joidenkin sivustojemme yhteydessä. Emme käytä web
          beaconeja yksittäisten käyttäjien tunnistamiseen, vaan vain sivustojemme kehittämiseen.
          Web beaconit ovat tyypillisesti sivustolle sijoitettavia graafisia kuvia, joiden avulla
          voidaan kerätä tietoja sivuston käytöstä. Tyypillisesti web beaconit eivät tuota muuta
          informaatiota, kuin mitä käyttäjän selain toimittaa palveluntarjoajalle osana palvelujen
          käyttöä. Niiden avulla voidaan myös analysoida evästeitä. Jos evästeiden käyttö on
          estetty, web beacon ei voi seurata käyttäjän toimintaa, mutta voi edelleen kerätä
          selaimesi tuottamaa tietoa.
        </p>
        <p>
          Mikäli et halua vastaanottaa evästeitä tai haluat tulla informoiduksi ennen niiden
          vastaanottamista, voit muuttaa selaimesi asetuksia vastaavasti, mikäli selaimesi tämän
          mahdollistaa. Evästeiden käytön estäminen voi kuitenkin johtaa siihen, että sivuston tai
          palvelun kaikki ominaisuudet eivät ole käytettävissäsi. Mahdollisten palveluntarjoajien
          sivustot, joihin Muuvinin palvelussa on linkki, voivat myös käyttää evästeitä tai web
          beaconeja. Muuvinilla ei ole pääsyä tai kontrollia tällaisiin evästeisiin.
        </p>
        <h3>Kuinka kauan säilytämme tietojasi?</h3>
        <p>
          Säilytämme tietojasi voimassa olevan lainsäädännön mukaisesti ja vain niin kauan, kuin on
          tarpeen tässä tietosuojalausekkeessa määriteltyjen tarkoitusten toteuttamiseksi. Kun
          henkilötietojesi käsittelyperuste on päättynyt, poistamme henkilötietosi.
          <br />
          Huomioithan, että jotkut Muuvinin palveluosiot saattavat sisältää julkisia,
          vuorovaikutteisia ja viestinnällisiä ominaisuuksia kuten esim. kommentteja eri
          sisältöihin, ja tuottamasi sisältö voi jäädä näihin näkyviin myös sen jälkeen, kun olet
          lopettanut rekisteröimäsi käyttäjätunnuksen tai käyttäjäsuhde päättyy.
        </p>
        <h3>Mitkä ovat oikeutesi?</h3>
        <p>
          Sinulla on lain sallimissa puitteissa oikeus tarkastaa, mitä tietoja olemme sinusta
          keränneet. Sinulla on oikeus lain sallimissa puitteissa vaatia virheellisen, tarpeettoman,
          puutteellisen tai vanhentuneen henkilötiedon korjaamista tai poistamista. Voit myös
          kieltää Muuvinia käsittelemästä itseäsi koskevia tietoja suoramainontaan, etämyyntiin tai
          muuhun suoramarkkinointiin tai markkina- tai mielipidetutkimukseen.
        </p>
        <h3>Muutokset tähän tietosuojalausekkeeseen</h3>
        <p>
          Muuvin kehittää jatkuvasti palvelujaan ja voi tehdä ajoittain muutoksia tähän
          tietosuojalausekkeeseen ilman etukäteistä ilmoitusta. Jos tätä tietosuojalauseketta
          kuitenkin olennaisesti muutetaan, Muuvin pyrkii ilmoittamaan siitä tämän
          tietosuojalausekkeen alussa ja sivustollaan vähintään 30 vuorokauden ajan.
        </p>
        <h3>Kuka on rekisterinpitäjä ja mihin voin ottaa yhteyttä?</h3>
        <p>
          Henkilötietojesi rekisterinpitäjänä toimii Muuvin Solutions Oy, osoitteessa c/o Samuel
          Thesleff, Uudenmaankatu 19-21 A 21 00120 Helsinki.
          <br />
          Yksityisyydensuojaan liittyvissä asioissa voit ottaa yhteyttä sähköpostitse osoitteeseen{' '}
          <a href="mailto:info@muuvin.com">info@muuvin.com</a>
          <br />
          Huomioithan, että voimme tietoturvasyistä joutua varmistamaan henkilöllisyytesi ennen
          pyyntösi täyttämistä.
        </p>
      </div>
    )
  }

  render() {
    const content = this.buildPage()

    return <div>{content}</div>
  }
}
