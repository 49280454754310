import translate from 'counterpart'
import Link from './Link'
import React from 'react'
import styled from 'styled-components/macro'
import Color from '../../styles/color'
import frontpageLookingApartmentBanner from '../../images/frontpage-looking-apartment-banner.jpg'

let images = {
  frontpageLookingApartmentBanner
}

const LookingApartmentBannerBPSMall = '580px'

const LookingApartmentBannerView = styled.div`
  background: ${(p) =>
    p.showBackground
      ? `${Color.chatBubble} url(${images.frontpageLookingApartmentBanner}) 50% 50% no-repeat`
      : 'rgb(250,251,252)'};
  background-size: cover;
  text-align: center;
  position: relative;
  padding: 4rem 1rem;
  overflow: hidden;
  color: ${(p) => (p.showBackground ? Color.white : 'rgb(68,72,81)')};
  border-top: ${(p) => (p.showBackground ? 'none' : '1px solid #eee')};
  border-bottom: ${(p) => (p.showBackground ? 'none' : '1px solid #eee')};

  @media (min-width: ${LookingApartmentBannerBPSMall}) {
    padding: 7rem 5rem;
  }

  &::before {
    display: ${(p) => (p.showBackground ? 'block' : 'none')};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(0, 0, 0, 0.3));
    z-index: 1;
  }

  > div {
    position: relative;
    z-index: 2;

    > h1 {
      margin: 0;
      ${(p) => (p.showBackground ? `text-shadow: 0 0 7rem ${Color.grey}` : '')};
    }

    > p {
      max-width: 46rem;
      margin-left: auto;
      margin-right: auto;
      ${(p) => (p.showBackground ? `text-shadow: 0 0 7rem ${Color.grey}` : '')};
    }
  }
`

// Utils
const nlnl2p = (s) => s.split('\n\n').map((line, i) => <p key={i}>{line}</p>)

const LookingApartmentBanner = ({ showBackground = true }) => {
  return (
    <LookingApartmentBannerView showBackground={showBackground}>
      <div>
        <h1>{translate('frontpage.lookingApartment.box.heading')}</h1>
        {nlnl2p(translate('frontpage.lookingApartment.box.content'))}
        <Link to="/find" type="button">
          {translate('frontpage.lookingApartment.box.link')}
        </Link>
      </div>
    </LookingApartmentBannerView>
  )
}

export default LookingApartmentBanner
