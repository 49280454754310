import React, { useMemo } from 'react'
import styled from 'styled-components/macro'

import translate from 'counterpart'

import NotificationItem from './NotificationItem'
import { orderBy } from 'lodash'
import Button from '../shared/Button'

const EmptyWrapper = styled.div`
  text-align: center;
`

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1.5rem;
  padding-bottom: 1rem;
`

const Heading = styled.h2`
  margin: 0;
`

const NotificationView = ({ notifications, markAsRead, markAllAsRead }) => {
  const notificationItems = useMemo(
    () => orderBy(notifications?.notifications || [], 'createdAt', 'desc'),
    [notifications.notifications]
  )

  return (
    <div>
      <HeaderRow>
        <Heading>{translate('menu.notifications')}</Heading>
        <Button size="small" onClick={markAllAsRead}>
          Mark all as read
        </Button>
      </HeaderRow>

      {notificationItems.length === 0 ? (
        <EmptyWrapper>
          <h1>{translate('menu.notifications')}</h1>
          <p>{translate('notification.empty')}</p>
        </EmptyWrapper>
      ) : (
        notificationItems.map((notification) => (
          <NotificationItem
            key={notification.id}
            notification={notification}
            markAsRead={markAsRead}
          />
        ))
      )}
    </div>
  )
}

export default NotificationView
