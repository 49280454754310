import * as ActionTypes from '../constants/ActionTypes'

const initialState = {
  housetypes: null,
  updateTime: 0
}

function HouseTypeReducer(state = initialState, action) {
  var date = new Date()
  var now = date.getTime()
  if (action) {
    switch (action.type) {
      case ActionTypes.HOUSETYPE_GET:
        var housetypes = []

        for (let houseType of action.housetypes) {
          let idx = action.housetypes.indexOf(houseType)
          housetypes.splice(idx + 1, 0, houseType)
        }

        return Object.assign({}, state, {
          housetypes: housetypes,
          updateTime: now
        })
      case ActionTypes.LOGOUT_SUCCESS:
        return Object.assign({}, state, {
          housetypes: null,
          updateTime: 0
        })

      default:
        return state
    }
  }
}

export default HouseTypeReducer
