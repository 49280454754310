import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import Color from '../../styles/color'
import Variables from '../../styles/variables'
import iconClose from '../../images/close-button.svg'

const Wrapper = styled.div`
  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: none;
    padding: 1rem 1.5rem;
    margin: 0.25rem;
    background-color: ${(props) => (props.layout === 'primary' ? Color.primary : Color.darkerGray)};
    border-radius: 2rem;
    font-size: ${Variables.smallFontSize};

    > p {
      margin: 0;
    }

    > div {
      margin-left: 1rem;
      cursor: pointer;

      img {
        width: 0.8rem;
        height: auto;
      }
    }
  }
`

export default class Pill extends Component {
  constructor(props) {
    super(props)

    this.onClickEvent = this.onClickEvent.bind(this)
  }

  static propTypes = {
    text: PropTypes.string,
    onClickHandler: PropTypes.func,
    layout: PropTypes.string
  }

  static defaultProps = {
    layout: 'normal'
  }

  onClickEvent() {
    const { onClickHandler } = this.props
    onClickHandler()
  }

  render() {
    const { text, onClickHandler, layout } = this.props

    return (
      <Wrapper layout={layout}>
        <div>
          <p>{text}</p>
          {onClickHandler && (
            <div
              onClick={() => {
                this.onClickEvent()
              }}>
              <img src={iconClose} />
            </div>
          )}
        </div>
      </Wrapper>
    )
  }
}
