import * as ActionTypes from '../constants/ActionTypes'

const initialState = {
  favourites: null,
  updateTime: 0
}

function FavouritesReducer(state = initialState, action) {
  var date = new Date()
  var now = date.getTime()

  if (action) {
    switch (action.type) {
      case ActionTypes.UPDATE_FAVORITES:
        return Object.assign({}, state, {
          favourites: action.data,
          updateTime: now
        })
      case ActionTypes.LOGOUT_SUCCESS:
        return Object.assign({}, state, {
          favourites: null,
          updateTime: 0
        })

      default:
        return state
    }
  }
}

export default FavouritesReducer
