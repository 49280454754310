import React from 'react'

const isNumeric = (val) => !isNaN(parseInt(val, 10))

const HighlightNumbers = ({ children, color, className, style }) => {
  if (typeof children !== 'string') {
    return children
  }

  let wrappedNumbers = children
    .split(' ')
    .map((word) =>
      !isNumeric(word) ? word : `<span style="color: ${color || '#2F3540'};">${word}</span>`
    )
    .join(' ')

  return (
    <div
      className={className}
      style={{ ...(style || {}), display: 'inline' }}
      dangerouslySetInnerHTML={{ __html: wrappedNumbers }}
    />
  )
}

export default HighlightNumbers
