import React, { Component } from 'react'

import Color from '../../styles/color'
import Variables from '../../styles/variables'

import styled from 'styled-components/macro'

const FieldContainer = styled.div`
  display: ${(props) => (props.type === 'hidden' ? 'none' : 'block')};

  > div {
    position: relative;

    input {
      border: ${(p) => (p.hasError ? `0.1rem solid ${Color.red}` : `0.1rem solid ${Color.border}`)};
      display: block;
      padding: 1.5rem;
      width: 100%;
      font-size: ${Variables.bodyFontSize};
      line-height: ${Variables.bodyLineHeight};
      border-radius: ${Variables.borderRadius};
    }
  }
`

const ErrorDisplay = styled.div`
  font-size: 1.2rem;
  margin-top: 1rem;
  color: ${Color.red};
`

const PrependWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: ${Variables.gutterHalf};
  transform: translateY(-50%);
  color: ${Color.blackish};
`

export default class InputField extends Component {
  static defaultProps = {
    showLabel: true
  }

  inputRef = null

  separatorGenerate(value) {
    // Get the value.
    if (!value) {
      return
    }

    const vall = value + ''
    let input = vall.replace(/[\D\s._-]+/g, '')
    input = input ? parseInt(input, 10) : 0

    return input === 0 ? '0' : input.toLocaleString('en-US').split(',').join(' ')
  }

  render() {
    const {
      meta,
      type,
      style,
      showLabel,
      label,
      id,
      input,
      required,
      placeholder,
      thousands,
      unit,
      className,
      disabled
    } = this.props

    const hasError = !!(meta && meta.touched && meta.error)

    if (thousands) {
      if (input && (input.value || input.value === '0')) {
        input.value = this.separatorGenerate(input.value)
      }
    }

    return (
      <FieldContainer hasError={hasError} type={type} style={style} className={className}>
        {showLabel && <label>{label}</label>}
        <div>
          <input
            ref={(ref) => (this.inputRef = ref)}
            id={id}
            {...input}
            required={required || false}
            placeholder={placeholder}
            disabled={disabled}
            type={type}
          />
          {unit && <PrependWrapper>{unit}</PrependWrapper>}
        </div>
        {hasError && <ErrorDisplay>{meta.error}</ErrorDisplay>}
      </FieldContainer>
    )
  }
}
