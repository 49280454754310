import React, { useState, useMemo } from 'react'
import styled from 'styled-components/macro'
import { Link } from 'react-router'
import moment from 'moment'
import Measure from 'react-measure'
import translate from 'counterpart'

import Avatar from '../shared/Avatar'
import NotificationIndicator from '../shared/NotificationIndicator'
import Color from '../../styles/color'
import Variables from '../../styles/variables'
import * as UserActions from '../../actions/UserActions'
import messageLetter from '../../images/message-letter.svg'

const Wrapper = styled.div`
  border-bottom: 1px solid ${Color.border};
  background-color: ${(props) => (props.unreadMessages ? Color.lightGray : Color.white)};

  a {
    text-decoration: none;
  }
`

const bpWidth = 500

const InnerWrapper = styled.div`
  padding: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div:first-of-type {
    flex: 0 0 auto;
  }

  > div:nth-of-type(2) {
    padding-left: ${Variables.gutter};
    padding-right: ${Variables.gutter};
    flex: 1;
    display: ${(props) => (props.width < bpWidth ? 'block' : 'flex')};
    max-width: calc(100% - 7rem);

    > strong {
      display: block;
      padding-right: ${(props) => (props.width < bpWidth ? 0 : Variables.gutter)};
      min-width: 10rem;
    }

    > div {
      display: block;
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    > time {
      display: block;
      text-align: ${(props) => (props.width < bpWidth ? 'left' : 'right')};
      padding-left: ${(props) => (props.width < bpWidth ? 0 : Variables.gutter)};
    }
  }
`

const IconWrapper = styled.div`
  width: 2.6rem;
  text-align: center;

  > img {
    width: 2rem;
    height: auto;
    vertical-align: middle;
  }
`

const MS_DAY = 1000 * 60 * 60 * 24

const ChatThread = ({ user, unreadMessages, timestamp, messages }) => {
  const [width, setWidth] = useState(undefined)
  let name = user?.name || translate('common.anonymous')

  let timeAgo = useMemo(() => {
    const now = moment()
    const timeStampMoment = moment(new Date(parseInt(timestamp, 10)))
    const diff = now.diff(timeStampMoment)

    if (diff > MS_DAY) {
      return moment(timeStampMoment).format('ddd D.M.Y')
    }

    return timeStampMoment.fromNow()
  }, [timestamp])

  const lastMessage = [...messages].pop()

  if (!user || !lastMessage) {
    return null
  }

  return (
    <Measure onMeasure={(dimensions) => setWidth(dimensions.width)}>
      <Wrapper unreadMessages={unreadMessages}>
        <Link to={`/messages/${user.id}#comment`}>
          <InnerWrapper width={width}>
            <Avatar src={UserActions.getAvatarUrl(user)} />
            <div>
              <strong>{name}</strong>
              <div>{lastMessage.message}</div>
              <time>{timeAgo}</time>
            </div>
            {unreadMessages ? (
              <NotificationIndicator count={unreadMessages} size="big" />
            ) : (
              <IconWrapper>
                <img src={messageLetter} />
              </IconWrapper>
            )}
          </InnerWrapper>
        </Link>
      </Wrapper>
    </Measure>
  )
}

export default ChatThread
