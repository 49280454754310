import * as ActionTypes from '../constants/ActionTypes'
import { createApiRequest } from '../utils/Api'
import { fetchStart, fetchEnd } from './FetchActions'

export function receiveFavorites(data) {
  return {
    type: ActionTypes.UPDATE_FAVORITES,
    data: data
  }
}

export function toggleFavorite(id) {
  let path = `/favorite/${id}`
  let method = 'PATCH'

  return (dispatch, getState) => {
    let apiRequest = createApiRequest(getState().auth.accessToken)

    return apiRequest({ path: path, method })
      .then((json) => {
        dispatch(receiveFavorites(json.data))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

export function getFavorites() {
  var URL = '/favorite/'
  var METHOD = 'GET'

  return (dispatch, getState) => {
    let apiRequest = createApiRequest(getState().auth.accessToken)

    dispatch(fetchStart('favoriteGet'))

    return apiRequest({ path: URL, method: METHOD })
      .then((json) => {
        dispatch(receiveFavorites(json.data))
        dispatch(fetchEnd('favoriteGet'))
      })
      .catch((error) => {
        dispatch(fetchEnd('favoriteGet'))
      })
  }
}
