import properUrlJoin from 'proper-url-join'
import { REACT_APP_API_URL } from '../constants'

export function getApiUrl(path = '') {
  return properUrlJoin(REACT_APP_API_URL, path)
}

export function createApiRequest() {
  return ({ path, method = 'GET', json, body }) => {
    const fetchConfig = {
      timeout: 1000 * 60 * 5,
      method
    }

    if (body) {
      fetchConfig.body = body
    }

    if (json) {
      fetchConfig.body = JSON.stringify(json)
    }

    return fetch(getApiUrl(path), fetchConfig).then((result) => result.json())
  }
}
