import React from 'react'
import styled from 'styled-components/macro'
import translate from 'counterpart'

import moment from 'moment'
import Avatar from '../shared/Avatar'

import * as UserActions from '../../actions/UserActions'

const Wrapper = styled.div`
  .comment--row {
    display: flex;
    flex-flow: row;
    margin: 0 0 1rem;
    font-size: 1.25rem;
    .comment--avatar {
      display: block;
      margin: 0 1.75rem 0.5rem 0;
    }
    .comment--text {
      width: 100%;
      padding: 0.5rem 0 0;
      .comment--username {
        font-weight: bold;
        color: #000;
      }
      .comment--meta {
        margin: 0.5rem 0 0;
      }
      .comment--time {
        opacity: 0.5;
      }
      .comment--remove {
        cursor: pointer;
        text-decoration: underline;
        opacity: 0.5;
        margin-right: 0.5rem;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
`

const CommentsList = (props) => {
  const comments = props.comments.comments || []

  return (
    <Wrapper>
      <div>
        {comments.map((comment) => {
          const user = comment?.user || null
          const commentTxt = comment?.comment || null
          const username = UserActions.getUserNameShort(user)

          let commentTime = null
          const commentId = comment.id

          let commentTimestamp = ''

          if (comment && comment.createdAt) {
            const yesterday = moment().add(-8, 'hours')
            commentTimestamp = moment(comment.createdAt).format()

            if (moment(comment.createdAt) > yesterday) {
              commentTime = moment(comment.createdAt).fromNow()
            } else {
              commentTime = moment(comment.createdAt).format('DD.MM.YYYY HH:mm')
            }
          }

          return (
            <div className="comment--row" key={comment.id}>
              <div className="comment--avatar">
                <Avatar src={UserActions.getAvatarUrl(user)} />
              </div>
              <div className="comment--text">
                <span className="comment--username">{username} </span>
                <span className="comment--comment">{commentTxt}</span>
                <div className="comment--meta">
                  {props.me && comment.userId === props.me.id ? (
                    <span
                      onClick={() => props.removeComment(commentId)}
                      className="comment--remove">
                      {translate('comment.remove')}
                    </span>
                  ) : (
                    ''
                  )}
                  <time className="comment--time" dateTime={commentTimestamp}>
                    {commentTime}
                  </time>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </Wrapper>
  )
}

export default CommentsList
