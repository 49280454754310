import React, { useCallback, useEffect, useMemo } from 'react'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import styled from 'styled-components/macro'

import Button from '../shared/Button'
import TextArea from '../shared/TextArea'
import translate from 'counterpart'
import UserListItem from '../shared/UserListItem'
import Spacer from '../shared/Spacer'
import ShowChat from '../chat/ShowChat'

import * as UserActions from '../../actions/UserActions'
import LoginButton from './LoginButton'
import * as MessageActions from '../../actions/MessageActions'

const ButtonContainer = styled.div`
  width: 25rem;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
`

const CommentToSeller = ({
  seller,
  resetMessagesSent,
  message,
  handleSubmit,
  initialize,
  change,
  me,
  messages,
  hideTitle,
  isBuyer
}) => {
  const onSubmit = useCallback(
    (e) => {
      if (message && message.length !== 0) {
        handleSubmit(e)
        change('message', '')
      }
    },
    [message, handleSubmit, change]
  )

  const sellerId = seller.id

  useEffect(() => {
    if (sellerId) {
      let receiverUserId = sellerId
      initialize({ receiverUserId })

      if (resetMessagesSent) {
        resetMessagesSent()
      }
    }
  }, [resetMessagesSent, sellerId])

  const thread = useMemo(() => {
    if ((messages || []).length === 0) {
      return null
    }

    return MessageActions.getThreads(messages || [], me)[sellerId]
  }, [messages, sellerId, me])

  let name = translate('form.login.namePlaceholder')

  if (seller?.name) {
    name = seller.name
  }

  const userWrapper = <UserListItem avatarSrc={UserActions.getAvatarUrl(seller)} name={name} />

  let title = !hideTitle
    ? isBuyer
      ? translate('comment.messageBuyer')
      : translate('comment.messageSeller')
    : null

  if (!me?.id) {
    return (
      <div>
        {title ? <h3>{title}</h3> : null}
        <LoginButton isButton={true} label={translate('wizard.registerLogin')} />
      </div>
    )
  }

  return (
    <div>
      <h3>{title}</h3>
      <form onSubmit={onSubmit}>
        {userWrapper}
        <ShowChat thread={thread || null} total={3} me={me} />
        <Spacer />
        <Field
          width="100%"
          name="message"
          label={translate('comment.privateMessage')}
          placeholder={translate('comment.messagePlaceholder')}
          unit=""
          component={TextArea}
          type="text"
          required={true}
        />
        <Spacer />
        <ButtonContainer>
          <Button text={translate('comment.sendPrivateMessage')} type="submit" />
        </ButtonContainer>
      </form>
    </div>
  )
}

const selector = formValueSelector('commenttoseller') // <-- same as form name

export default connect((state) => {
  const message = selector(state, 'message')
  const receiver = selector(state, 'receiverUserId')

  return {
    messages: state.messages.messages,
    message: message,
    receiverUserId: receiver
  }
})(
  reduxForm({
    form: 'commenttoseller'
  })(CommentToSeller)
)
