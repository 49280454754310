import React, { Component } from 'react'
import { connect } from 'react-redux'

import PaymentView from '../components/payment/PaymentView'
import GetLicense from '../components/payment/GetLicense'

import LoggedInContainer from './LoggedInContainer'
import { ElementsConsumer } from '@stripe/react-stripe-js'

class PaymentContainer extends Component {
  constructor(props) {
    super(props)

    this._loadUserById = this._loadUserById.bind(this)
    this._unloadUser = this._unloadUser.bind(this)
    this._loadPropertyById = this._loadPropertyById.bind(this)
    this._unloadProperty = this._unloadProperty.bind(this)
  }

  _loadUserById(id) {
    this.props.getUserById(id)
  }

  _unloadUser() {
    this.props.unloadUser()
  }

  _loadPropertyById(propertyId) {
    this.props.getPropertyById(propertyId)
  }

  _unloadProperty() {
    this.props.unloadProperty()
  }

  render() {
    let { route, auth, license, returnUrl = '/property' } = this.props
    const backUrl = this.props.route?.returnUrl || returnUrl

    return (
      <LoggedInContainer
        navigationRoute={this.props.route}
        backUrl={backUrl}
        hideNavigation={false}
        maxWidth="page">
        {route?.action === 'view' ? (
          <PaymentView license={license} auth={auth} backUrl={backUrl} />
        ) : (
          <ElementsConsumer>
            {({ elements, stripe }) => (
              <GetLicense elements={elements} stripe={stripe} backUrl={backUrl} />
            )}
          </ElementsConsumer>
        )}
      </LoggedInContainer>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    license: state.license,
    auth: state.auth
  }
}

export default connect(mapStateToProps)(PaymentContainer)
