import React, { Component } from 'react'
import translate from 'counterpart'
import styled from 'styled-components/macro'
import cookie from 'react-cookies'
import EmptyWatcher from './EmptyWatcher'
import PropertyBlock from '../property/items/PropertyBlock'

import Alert from '../shared/Alert'
import Dropdown from '../shared/Dropdown'
import Link from '../shared/Link'
import { Link as RouterLink } from 'react-router'
import infoBuyers from '../../images/info-potential-buyers.svg'

import Color from '../../styles/color'
import colors from '../../styles/color'
import Variables from '../../styles/variables'
import { getZoomFromArea, StaticGoogleMap } from '../googlemap/staticMapImage'
import { compact, isNumber } from 'lodash'
import { ColumnWrapper, LeftColumn, RightColumn, ResultsWrapper } from './Columns'
import { renderWithThousandSeparators, numVal } from '../../utils/number'
import { SuperScript } from '../shared/Typography'

import { IconPlus } from '../../icons/IconPlus'
import { MAX_CIRCLES } from '../../constants'
import HighlightNumbers from '../shared/HighlightNumbers'

const SearchWatcherActionRow = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 9;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;

  > div:first-child {
    margin-right: 0;
    margin-bottom: ${Variables.gutterHalf};
    max-width: 100%;

    @media (min-width: ${Variables.bpSmall}) {
      margin-right: ${Variables.gutter};
    }
  }
`

const ActionWrapper = styled.div`
  width: 100%;
  display: flex;
  margin: 0.5rem -${Variables.gutterHalf} 0;
  justify-content: space-between;

  @media (min-width: ${Variables.bpSmall}) {
    width: auto;
    margin: 0 -${Variables.gutterHalf};
  }

  @media (min-width: ${Variables.bpMedium}) {
    justify-content: flex-end;
  }

  a {
    margin: ${Variables.gutterHalf};
    white-space: nowrap;
  }
`

const SelectedAreasWrapper = styled.div`
  margin-top: 1rem;
  position: relative;

  > h2 {
    margin-top: 0;
    margin-bottom: 1rem;
  }
`

const AreaImagesWrapper = styled.div`
  display: flex;
  margin-left: -1rem;
  flex-wrap: wrap;
`

const WatcherAreaImageWrapper = styled.div`
  border-radius: 50%;
  width: 7rem;
  height: 7rem;
  border: 1px solid ${(p) => p.color || Color.green};
  background-color: ${(p) => p.color || 'transparent'};
  position: relative;
  margin-left: 1rem;
  margin-bottom: 1rem;
  display: flex;

  &:hover {
    img {
      transform: scale(2);
      z-index: 20;
    }
  }
`

const WatcherConfigBox = styled.div`
  margin-top: 1rem;
  padding: ${Variables.gutter};
  border: 0.1rem solid ${Color.border};
`

const WatcherParamsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${Variables.bpSmall}) {
    flex-direction: row;
    justify-content: space-between;
  }
`

const WatcherConfigItem = styled.div`
  margin-bottom: 1.25rem;
  flex: 1 1 33.333%;

  @media (min-width: ${Variables.bpSmall}) {
    margin: 0 0 1.5rem;
    text-align: center;

    &:first-child {
      text-align: left;
      margin-right: 1rem;
    }

    &:last-child {
      text-align: right;
      margin-left: 1rem;
    }
  }
`

const WatcherConfigValue = styled.div`
  color: ${Color.grey};
`

const WatcherConfigLabel = styled.div`
  font-weight: bold;
`

const WatcherAreaImage = styled(StaticGoogleMap)`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  margin: -50% 0 0 -50%;
  border-radius: 50%;
  object-fit: contain;
  overflow: hidden;
  transition: transform 0.2s ease-out;
  display: block;
  z-index: 10;
  outline: 1px solid ${Color.green};
`

const AddWatcherLink = styled(RouterLink)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`

const AddWatcherAreaIcon = styled(IconPlus)`
  width: 3rem;
  height: 3rem;
  display: block;

  path {
    fill: white;
  }
`

const StatHeading = styled.h2``

export default class SearchWatcherFrontpage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      myPropertiesRequested: false,
      dropDownSelected: null,
      selectedWatchId: null,
      dimensions: {
        width: -1,
        height: -1
      }
    }

    this.requestWatcherHouses = this.requestWatcherHouses.bind(this)
    this.requestMyWatchers = this.requestMyWatchers.bind(this)
    this._handleOnChangeDropdown = this._handleOnChangeDropdown.bind(this)
    this.requestWatcherHouses = this.requestWatcherHouses.bind(this)
  }

  componentDidMount() {
    this.initializeDropdown()
    this.requestMyWatchers()
  }

  componentDidUpdate(prevProps, prevState) {
    this.initializeDropdown()
    let selectedWatcher = this.state.dropDownSelected?.value

    if (selectedWatcher && selectedWatcher !== prevState.dropDownSelected?.value) {
      this.requestWatcherHouses(selectedWatcher)
    }
  }

  initializeDropdown = () => {
    let watchers = this.props.watcher?.myWatchers || []

    if (watchers.length !== 0 && !this.state.dropDownSelected) {
      let dropdownItems = this.buildDropdownItems(watchers)
      let selectedWatcher =
        this.getCookieWatcher(dropdownItems) ||
        dropdownItems.find((dd) => dd.value === watchers[0].id)

      if (selectedWatcher) {
        this.setState({ dropDownSelected: selectedWatcher })
      }
    }
  }

  requestWatcherHouses(id) {
    if (id && this.state.selectedWatchId !== id) {
      this.props.getWatchResults(id)
      this.props.getHiddenHousesByWatchId(id)
      this.setState({ selectedWatchId: id })
    }
  }

  requestMyWatchers() {
    this.props.getMyWatchers()
  }

  _handleOnChangeDropdown(item) {
    cookie.remove('selectedWatcher')
    cookie.save('selectedWatcher', item.value, { path: '/' })
    this.requestWatcherHouses(item.value)
    this.setState({ dropDownSelected: item })
  }

  buildDropdownItems(watchers) {
    let dropdownItems = []

    for (let watcher of watchers) {
      let pr = {}

      pr.name = watcher.name || ''
      pr.subname = ''
      pr.value = watcher.id
      pr.object = watcher

      dropdownItems.push(pr)
    }

    return dropdownItems
  }

  getCookieWatcher(watchers) {
    let selectedWatcherCookie = cookie.load('selectedWatcher', { path: '/' })
    return watchers.find((watcher) => watcher.value === selectedWatcherCookie)
  }

  render() {
    let actionsContent = null
    let watcherSelect = <EmptyWatcher />
    let watcherResultsHeading = null
    let potentialHidden = null
    let areaCircles = null
    let areasJSX = []
    let selected

    let watcher = null

    if ((this.props.watcher?.myWatchers || []).length !== 0) {
      let watchers = this.props.watcher.myWatchers
      let dropdownItems = this.buildDropdownItems(watchers)
      selected = this.state.dropDownSelected

      if (selected) {
        if (this.props.watcher.myWatchers.length > 1) {
          watcherSelect = (
            <Dropdown
              list={dropdownItems}
              selected={selected}
              forceText={selected?.name}
              onChange={this._handleOnChangeDropdown}
            />
          )
        } else {
          watcherSelect = <h3>{selected.name}</h3>
        }

        watcher = selected.object

        if (watcher.areas) {
          for (let area of watcher.areas) {
            let zoom = getZoomFromArea(area)
            areasJSX.push(
              <WatcherAreaImageWrapper key={`${area.lat}_${area.lng}_${area.radius}`}>
                <WatcherAreaImage {...area} zoom={zoom} />
              </WatcherAreaImageWrapper>
            )
          }

          if (watcher.areas.length < MAX_CIRCLES)
            areasJSX.push(
              <WatcherAreaImageWrapper color={Color.green} key="add-area">
                <AddWatcherLink type="outline" to={`/find/edit/${watcher.id}?open_map=true`}>
                  <AddWatcherAreaIcon />
                </AddWatcherLink>
              </WatcherAreaImageWrapper>
            )
        }

        areaCircles = <AreaImagesWrapper>{areasJSX}</AreaImagesWrapper>

        actionsContent = (
          <>
            <Link type="outline" to={'/find/edit/' + watcher.id}>
              {translate('common.edit')}
            </Link>
            <Link type="button" to="/find/new">
              {translate('common.addSearchwatch')}
            </Link>
          </>
        )
      }
    } else {
      return <EmptyWatcher />
    }

    let count = 0

    if (this.props.watcher && this.props.watcher.watchResults) {
      count = this.props.watcher.watchResults.length
      watcherResultsHeading = (
        <h2>
          {translate('dashboard.property.potentialPropertiesPublic')}{' '}
          <span style={{ color: Color.green }}>{count}</span>
        </h2>
      )
    }

    let countPrivate = 0

    if (this.props.watcher && this.props.watcher?.watchPrivateResults) {
      countPrivate = this.props.watcher.watchPrivateResults
    }

    potentialHidden = (
      <div>
        <h2>
          {translate('dashboard.property.potentialPropertiesHidden')}{' '}
          <span style={{ color: Color.green }}>{countPrivate}</span>
        </h2>
        <Alert iconSrc={infoBuyers}>
          <p className="smallInfo">
            {translate('dashboard.property.potentialPropertiesHiddenInfo')}
          </p>
        </Alert>
      </div>
    )

    let selectedDescription = ''

    if (selected) {
      if (selected.object && selected.object.description) {
        selectedDescription = selected.object.description

        if (selectedDescription.length > 140) {
          selectedDescription = selectedDescription.substr(0, 135) + '...'
        }
      }
    }

    let houseTypeNames = (this.props.housetype?.housetypes || []).map((houseType) => {
      return {
        id: houseType.id,
        name: translate(`houseType.${houseType.name}`)
      }
    })

    let watcherHouseTypes = !watcher
      ? []
      : compact(
          watcher.houseType
            .split(',')
            .map((houseTypeId) => houseTypeNames.find((name) => name?.id === houseTypeId)?.name)
        )

    return (
      <div>
        {selected && (
          <Alert iconSrc={infoBuyers}>
            <p>
              {translate('dashboard.searchWatcher.yourWatcher')}&nbsp;
              <strong>
                {selected.object.private ? translate('common.private') : translate('common.public')}
              </strong>
            </p>
          </Alert>
        )}
        <ColumnWrapper>
          <LeftColumn>
            <StatHeading>
              <HighlightNumbers color={colors.green}>
                {translate('searchWatcher.searchWatcherResultHeading.common', {
                  count: count
                })}
              </HighlightNumbers>
              {countPrivate !== 0 ? (
                <HighlightNumbers color={colors.green}>
                  {' ' +
                    translate('searchWatcher.searchWatcherResultHeading.private', {
                      count: countPrivate
                    })}
                </HighlightNumbers>
              ) : (
                '.'
              )}
            </StatHeading>
            <SearchWatcherActionRow>
              {watcherSelect}
              <ActionWrapper>{actionsContent}</ActionWrapper>
            </SearchWatcherActionRow>
            {watcher && (
              <WatcherConfigBox>
                <WatcherParamsWrapper>
                  {watcherHouseTypes.length !== 0 && (
                    <WatcherConfigItem>
                      <WatcherConfigLabel>{translate('house.Type')}</WatcherConfigLabel>
                      <WatcherConfigValue>{watcherHouseTypes.join(', ')}</WatcherConfigValue>
                    </WatcherConfigItem>
                  )}
                  <WatcherConfigItem>
                    <WatcherConfigLabel>{translate('house.priceRange')}</WatcherConfigLabel>
                    <WatcherConfigValue>
                      {renderWithThousandSeparators(watcher.minPrice)} &ndash;{' '}
                      {renderWithThousandSeparators(watcher.maxPrice)} €
                    </WatcherConfigValue>
                  </WatcherConfigItem>
                  <WatcherConfigItem>
                    <WatcherConfigLabel>{translate('house.apartmentSize')}</WatcherConfigLabel>
                    <WatcherConfigValue>
                      {watcher.minSize} &ndash; {watcher.maxSize} m<SuperScript>2</SuperScript>
                    </WatcherConfigValue>
                  </WatcherConfigItem>
                </WatcherParamsWrapper>
                <WatcherConfigLabel>{translate('searchWatcher.areas')}</WatcherConfigLabel>
                <SelectedAreasWrapper>{areaCircles}</SelectedAreasWrapper>
              </WatcherConfigBox>
            )}

            <h4 style={{ marginTop: '2rem' }}>
              {selectedDescription
                ? translate('dashboard.property.potentialPropertiesHiddenDescription')
                : translate('dashboard.property.noDescription')}
            </h4>
            <p>
              <em>"{selectedDescription ? selectedDescription : null}"</em>
            </p>
          </LeftColumn>
          <RightColumn>
            {potentialHidden}
            {watcherResultsHeading}
            <ResultsWrapper>
              {(this.props.watcher?.watchResults || []).map((property) => (
                <PropertyBlock
                  key={`property_${property.id}`}
                  property={property}
                  houseType={this.props.housetype}
                />
              ))}
            </ResultsWrapper>
          </RightColumn>
        </ColumnWrapper>
      </div>
    )
  }
}
