import * as ActionTypes from '../constants/ActionTypes'
import { createApiRequest } from '../utils/Api'
import { fetchStart, fetchEnd } from './FetchActions'

import * as PageActions from './PageActions'
import { string2number } from '../utils/Text'

import cookie from 'react-cookies'

export function receiveGetWatchPrivateResults(data) {
  return {
    type: ActionTypes.GET_WATCH_RESULTS_PRIVATE,
    data: data
  }
}
export function receiveGetWatchResults(data) {
  return {
    type: ActionTypes.GET_WATCH_RESULTS,
    data: data
  }
}
export function storeTemporaryWatch(data) {
  return {
    type: ActionTypes.TEMP_WATCH,
    data: data
  }
}

export function receiveDelete(data) {
  return {
    type: ActionTypes.GET_WATCH,
    data: null
  }
}

export function receivePost(data) {
  return {
    type: ActionTypes.POST_WATCH,
    data: data
  }
}
export function receiveGet(data) {
  return {
    type: ActionTypes.GET_WATCH,
    data: data
  }
}
export function receiveGetAll(data) {
  return {
    type: ActionTypes.GET_WATCH_ALL,
    data: data
  }
}
export function receiveUnload() {
  return {
    type: ActionTypes.GET_WATCH,
    data: null
  }
}
export function receiveTempWatcher(data) {
  return {
    type: ActionTypes.SAVE_TEMP_WATCHER,
    data: data
  }
}
export function clearTempWatcherAttributes() {
  return {
    type: ActionTypes.CLEAR_TEMP_WATCHER,
    data: null
  }
}
export function clearTemporaryWatcher() {
  return (dispatch, getState) => {
    dispatch(clearTempWatcherAttributes())
  }
}

function createWatcherData(saveData) {
  if (!saveData) {
    return
  }

  let formData = { ...saveData }

  formData.houseType = saveData.houseType
    .map((val, index) => (val ? index : false))
    .filter((val) => !!val)
    .join(',')

  formData.minPrice = saveData.price[0]
  formData.minSize = saveData.size[0]
  formData.maxPrice = saveData.price[1]
  formData.maxSize = saveData.size[1]
  formData.private = false

  return formData
}

export function saveTemporaryWatcher(saveData) {
  let watchData = createWatcherData(saveData)

  return (dispatch, getState) => {
    dispatch(receiveTempWatcher(JSON.stringify(watchData)))
  }
}

export function create(saveData) {
  let watchData = createWatcherData(saveData)

  let URL = '/watch'
  let METHOD = 'POST'

  // Switch to update if it has an ID
  if (saveData.id) {
    URL = `/watch/${saveData.id}`
    METHOD = 'PATCH'
  }

  return (dispatch, getState) => {
    let apiRequest = createApiRequest(getState().auth.accessToken)

    return apiRequest({ path: URL, method: METHOD, json: watchData })
      .then((json) => {
        dispatch(receivePost(json.data))

        if (json.data) {
          cookie.save('selectedWatcher', json.data.id, { path: '/' })
        }

        PageActions.changePage('/find')
      })

      .catch((error) => {
        console.error(error)
      })
  }
}

export function getAll() {
  let URL = '/watch/'

  return (dispatch, getState) => {
    let apiRequest = createApiRequest(getState().auth.accessToken)

    dispatch(fetchStart('watchers'))
    return apiRequest({ path: URL })
      .then((json) => {
        dispatch(receiveGetAll(json.data))
        dispatch(fetchEnd('watchers'))
      })
      .catch((error) => {
        console.error(error)
        dispatch(fetchEnd('watchers'))
      })
  }
}
export function unload() {
  return (dispatch) => {
    dispatch(receiveUnload())
  }
}

export function getById(id) {
  let URL = `/watch/${id}`

  return (dispatch, getState) => {
    let apiRequest = createApiRequest(getState().auth.accessToken)

    return apiRequest({ path: URL })
      .then((json) => {
        dispatch(receiveGet(json.data))
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

export function deleteWatcher(id) {
  let URL = `/watch/${id}`
  let METHOD = 'DELETE'

  return (dispatch, getState) => {
    let apiRequest = createApiRequest(getState().auth.accessToken)

    return apiRequest({ path: URL, method: METHOD })
      .then((json) => {
        dispatch(receiveDelete(json.data))
        dispatch(getAll())
        cookie.remove('selectedWatcher')
        PageActions.changePage('/find')
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

export function getHousesByWatchId(id) {
  let URL = `/house/?watch=${id}`

  return (dispatch, getState) => {
    let apiRequest = createApiRequest(getState().auth.accessToken)

    return apiRequest({ path: URL })
      .then((json) => {
        dispatch(receiveGetWatchResults(json.data))
      })
      .catch((error) => {
        console.error(error)
      })
  }
}
export function getHiddenHousesByWatchId(id) {
  let URL = '/house/findPrivate?watch=' + id

  return (dispatch, getState) => {
    let apiRequest = createApiRequest(getState().auth.accessToken)

    return apiRequest({ path: URL })
      .then((json) => {
        dispatch(receiveGetWatchPrivateResults(json.data))
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

export function getHousesByWatchParams(data) {
  if (!data || Object.keys(data).length === 0) {
    return
  }

  let URL = '/watch/house'
  let METHOD = 'POST'

  return (dispatch) => {
    let apiRequest = createApiRequest()

    return apiRequest({ path: URL, method: METHOD, json: data })
      .then((json) => {
        dispatch(receiveGetWatchResults(json.data))
      })
      .catch((error) => {
        console.error(error)
      })
  }
}
export function getWatcherByParams(data) {
  let URL = '/house/watches'
  let METHOD = 'POST'

  if (data.house && data.house.price) {
    data.house.price = string2number(data.house.price)
  }

  return (dispatch, getState) => {
    let apiRequest = createApiRequest(getState().auth.accessToken)

    return apiRequest({ path: URL, json: data, method: METHOD })
      .then((json) => {
        dispatch(receiveGetWatchResults(json.data))
      })
      .catch((error) => {
        console.error(error)
      })
  }
}
