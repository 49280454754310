import React from 'react'
import styled from 'styled-components/macro'

import Avatar from './Avatar'

import Variables from '../../styles/variables'

const Wrapper = styled.div`
  margin-bottom: ${Variables.gutter};

  > div:first-of-type {
    display: flex;
    align-items: center;
    margin-bottom: ${Variables.gutter};

    > div:first-of-type {
      margin-right: ${Variables.gutter};
    }

    h2 {
      margin: 0;
    }
  }
`

const UserBlockItem = ({ avatarSrc, name, memberDescription, description }) => {
  return (
    <Wrapper>
      <div>
        {avatarSrc && <Avatar src={avatarSrc} size="big" />}
        <div>
          {name && <h2>{name}</h2>}
          {memberDescription && <div>{memberDescription}</div>}
        </div>
      </div>
      {description && description}
    </Wrapper>
  )
}

export default UserBlockItem
