import React, { useMemo } from 'react'
import translate from 'counterpart'
import Link from '../shared/Link'
import ChatBubble from './ChatBubble'
import { orderBy } from 'lodash'

const ShowChat = ({ thread, total }) => {
  let messages = useMemo(() => {
    if ((thread?.messages || []).length === 0) {
      return []
    }

    return orderBy(thread.messages, 'createdAt', ['asc']).slice(-total)
  }, [thread, total])

  return (
    <div>
      {thread && thread.id && (
        <p>
          <Link to={`/messages/${thread.id}`}>{translate('common.redirectToMessages')}</Link>
        </p>
      )}
      {messages.map((message) => (
        <ChatBubble key={`message_${message.id}`} message={message} />
      ))}
    </div>
  )
}

export default ShowChat
