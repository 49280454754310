import * as ActionTypes from '../constants/ActionTypes'
import counterpart from 'counterpart'
import moment from 'moment'

import cookie from 'react-cookies'

const initialState = {
  user: null,
  error: null,
  errorMessage: null,
  saved: false,
  time: 0,
  triedToLogIn: null,
  language: cookie.load('language', { path: '/' }) || 'fi',
  adminStats: null
}

function AuthReducer(state = initialState, action) {
  var dateT = new Date()
  var time = dateT.getTime()
  if (action) {
    switch (action.type) {
      case ActionTypes.ADMIN_STATS_RECEIVED:
        return Object.assign({}, state, {
          adminStats: action.data,
          updateTime: time
        })
      case ActionTypes.LOGIN_SUCCESS:
        return Object.assign({}, state, {
          user: action.user,
          error: null,
          saved: false,
          time: time,
          triedToLogIn: true
        })
      case ActionTypes.PROFILE_FAILURE:
        return Object.assign({}, state, {
          error: action.error,
          saved: false,
          time: time
        })
      case ActionTypes.PROFILE_SUCCESS:
        return Object.assign({}, state, {
          error: null,
          saved: true,
          time: time
        })
      case ActionTypes.LOGOUT_SUCCESS:
        return Object.assign({}, state, {
          user: null,
          saved: false,
          time,
          triedToLogIn: true
        })

      case ActionTypes.CHANGE_LANGUAGE: {
        var lang = 'fi'
        if (action.lang) {
          lang = action.lang
          cookie.save('language', lang, { path: '/' })
          counterpart.setLocale(lang)
          moment.locale(lang)
        }
        return Object.assign({}, state, {
          time: time,
          user: state.user,
          language: lang
        })
      }
      default:
        return state
    }
  }
}

export default AuthReducer
