import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import { mix } from 'polished'
import translate from 'counterpart'

import Color from '../../styles/color'
import colors from '../../styles/color'
import Variables from '../../styles/variables'

import CommentToSeller from '../shared/CommentToSeller'
import Spacer from '../shared/Spacer'
import PropertyAttribute from './items/PropertyAttribute'
import GoogleMapView from '../googlemap/GoogleMapView'
import Link from '../shared/Link'
import ImageCommentModal from './ImageCommentModal'
import NotFoundProperty from '../notfound/NotFoundProperty'
import { changePage } from '../../actions/PageActions'
import houseType from '../../images/property/housetype.svg'
import houseSize from '../../images/property/housesize.svg'
import { getCurrencySymbol, numberWithCommas } from '../../actions/AreaActions'
import { orderBy, find } from 'lodash'
import fbBlue from '../../images/icon-facebook-logo-blue.png'
import { GoogleMapProvider } from '../googlemap/GoogleMapProvider'
import LoginButton from '../shared/LoginButton'
import { getFileUrl } from '../../utils/getFileUrl'
import LoadingIndicator from '../loader/LoadingIndicator'

const Wrapper = styled.div`
  .property--description {
    white-space: pre-wrap;
  }
  .galleryImages {
    position: relative;

    .container--comments-likes {
      background: rgba(0, 0, 0, 0.25);
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      transition: opacity 0.25s;
      opacity: 0;
      font-size: 1.3rem;
      &:hover {
        opacity: 1;
      }
      > div {
        i.fa {
          padding-right: 0.5rem;
        }
        color: ${Color.white};
        margin: 2rem auto 1rem;
        .count--likes,
        .count--comments {
          /*display: none;*/
          margin: 0.5rem 0.5rem 0.5rem;
        }
      }
    }
  }

  @media (min-width: ${Variables.bpSmall}) {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    > h1 {
      width: 100%;
      margin-bottom: 0;
    }
  }
`

const MainColumn = styled.div`
  > header {
    padding-top: 1rem;
    h1 {
      margin-top: 0;
      margin-bottom: 0;
    }

    p {
      margin-top: 0;
      color: ${Color.grey};
    }
  }

  @media (min-width: ${Variables.bpSmall}) {
    width: 60%;

    header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
  }

  @media (min-width: ${Variables.bpSmall}) {
    width: 65%;
  }
`

const SideColumn = styled.div`
  display: none;

  @media (min-width: ${Variables.bpSmall}) {
    display: block;
    width: 40%;
    padding-left: 3rem;
    margin-top: 9.2rem;
  }

  @media (min-width: ${Variables.bpMedium}) {
    width: 35%;
  }
`

const FavouriteIconWrapper = styled.div`
  cursor: pointer;
  border: 1px solid ${Color.green};
  border-radius: 2.5rem;
  display: inline-block;
  box-sizing: border-box;
  padding: 1rem 1.5rem 1rem;
  width: 20rem;
  color: ${Color.green};
  text-align: center;
  font-size: 0.8em;
  transition: background 0.25s, color 0.25s;
  &:hover {
    background: ${mix(0.8, Color.white, Color.green)};
    color: ${mix(0.2, Color.white, Color.green)};
  }
  &.state-on {
    color: ${Color.white};
    background: ${Color.green};
    &:hover {
      background: ${mix(0.2, Color.white, Color.green)};
      color: ${mix(0.8, Color.white, Color.green)};
    }
  }
  .fa {
    /*vertical-align: text-bottom;*/
    margin-right: 1ex;
    /*transition: color ${Variables.defaultSpeed} ${Variables.defaultEasing};*/
  }

  .fa-star {
    color: ${Color.green};
  }
`

const MapWrapper = styled.div`
  position: relative;
  height: 0;
  padding-bottom: 50%;
  margin-bottom: ${Variables.gutter};

  > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const MobileWrapper = styled.div`
  display: block;

  @media (min-width: ${Variables.bpSmall}) {
    display: none;
  }

  ${MapWrapper} {
    padding-bottom: 75%;
  }
`

const HeaderImage = styled.div`
  > div {
    background-position: 50% 50%;
    background-size: cover;
    height: 0;
    padding-bottom: 50%;
    background-color: ${Color.border};
    border-radius: ${Variables.borderRadius};
    overflow: hidden;
    margin-bottom: ${Variables.gutter};
    cursor: pointer;
    @media (min-width: ${Variables.bpSmall}) {
      height: 0;
      padding-bottom: 50%;
    }
  }
`

const ThumbnailWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -1rem;
  margin-right: -1rem;

  > div {
    width: calc(16.666% - ${Variables.gutter});
    margin-left: ${Variables.gutterHalf};
    margin-right: ${Variables.gutterHalf};
    margin-bottom: ${Variables.gutter};
  }
`

const Thumbnail = styled.div`
  > .galleryImages {
    > div {
      background-position: 50% 50%;
      background-size: cover;
      height: 0;
      padding-bottom: 100%;
      background-color: ${Color.border};
      border-radius: ${Variables.borderRadius};
      overflow: hidden;
      cursor: pointer;
    }
  }
`

const FacebookShareLink = styled.a`
  text-decoration: none;
  color: ${colors.green};
  display: inline-flex;
  align-items: center;

  span {
    text-decoration: underline;
  }

  img {
    display: block;
    margin-right: 2rem;
  }
`

class PropertyView extends Component {
  static propTypes = {
    error: PropTypes.object,
    housetype: PropTypes.object,
    uploadImage: PropTypes.func,
    clearFileUpload: PropTypes.func,
    handleSubmit: PropTypes.func
  }

  constructor(props) {
    super(props)

    this.state = {
      fileUploadTime: 0
    }

    this._handleFavouriteToggle = this._handleFavouriteToggle.bind(this)
    this._showImage = this._showImage.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.updateComments = this.updateComments.bind(this)
    this._getImageCommentsCount = this._getImageCommentsCount.bind(this)
    this._getImageLikesCount = this._getImageLikesCount.bind(this)
    this._getCurrentProperty = this._getCurrentProperty.bind(this)
    this.refreshProperty = this.refreshProperty.bind(this)
  }
  componentDidMount() {
    var imageId = this.props && this.props.imageId ? this.props.imageId : null
    var propertyId = this.props.property?.lastProperty?.id || null

    if (this.props.getComments && imageId && propertyId) {
      this.props.getComments(propertyId + '/' + imageId)
    }
  }

  componentDidUpdate(prevProps) {
    var imageId = this.props.imageId || undefined
    var prevImageId = prevProps.imageId || undefined

    var prevPropertyId = prevProps.property?.lastProperty?.id
    var propertyId = this.props.property?.lastProperty?.id

    if (
      this.props.getComments &&
      ((imageId && imageId !== prevImageId) || (propertyId && propertyId !== prevPropertyId))
    ) {
      this.props.getComments(propertyId + '/' + imageId)
    }
  }
  refreshProperty() {
    if (this.props && this.props.refreshProperty) {
      this.props.refreshProperty()
    }
  }
  updateComments() {
    var imageId = this.props.imageId
    var propertyId = this.props.property?.lastProperty?.id

    if (imageId && propertyId) {
      this.props.getComments(propertyId + '/' + imageId)
    }
  }

  _showImage(imageId, returnUrl) {
    let id = this.props.property.lastProperty.id
    var url = ''

    if (this.props.country || this.props.city) {
      var _country = this.props.country || 'FI'
      var _city = this.props.city || '-'
      url = `/explore/${_country}/${_city}/property/${id}/${imageId}`
    } else {
      url = `/property/${id}/${imageId}`
    }
    if (url) {
      if (returnUrl) {
        return url
      } else {
        changePage(url)
      }
    }
  }
  _handleFavouriteToggle() {
    if (typeof this.props.toggleFavorite === 'function') {
      let id = this.props.property.lastProperty.id
      this.props.toggleFavorite(id)
    }
  }

  checkHasFavourite(id) {
    return (this.props.favourites?.favourites || []).some((fav) => fav.id === id)
  }
  closeModal() {
    let id = this.props.property.lastProperty.id

    if (this.props.country || this.props.city) {
      var _country = this.props.country || 'FI'
      var _city = this.props.city || '-'
      changePage(`/explore/${_country}/${_city}/property/${id}`)
    } else {
      changePage(`/property/${id}`)
    }
  }
  normalizeHouseTypes(housetype) {
    let housetypes = housetype.housetypes
    let returnArr = []
    for (let i in housetypes) {
      let obj = housetypes[i]
      returnArr[obj.id] = obj
    }
    return returnArr
  }
  _getCurrentProperty() {
    return this.props.property?.lastProperty
  }
  _getImageCommentsCount(id) {
    const property = this._getCurrentProperty()
    let commentCount = 0

    if (property && property.imageComments) {
      for (let comment of property.imageComments) {
        if (comment.entityId && comment.entityId.includes('/' + id)) {
          commentCount++
        }
      }
    }

    if (commentCount > 0) {
      return (
        <span className="count--comments">
          <i className="fa fa-comment" aria-hidden="true" />
          {commentCount}
        </span>
      )
    } else {
      return null
    }
  }
  _getImageLikesCount(id) {
    const property = this._getCurrentProperty()
    let likeCount = 0

    if (property && property.imageLikes) {
      for (let like of property.imageLikes) {
        if (like.entityId && like.entityId.includes('/' + id)) {
          likeCount++
        }
      }
    }

    if (likeCount > 0) {
      return (
        <span className="count--likes">
          <i className="fa fa-heart" aria-hidden="true" />
          {likeCount}
        </span>
      )
    } else {
      return null
    }
  }

  onFbShare = (e) => {
    e.preventDefault()

    let shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      window.location.href
    )}`

    window.open(shareUrl, 'Facebook Share')
  }

  render() {
    const { me, property, housetype } = this.props
    let lastProperty = property.lastProperty

    if (property.loadError) {
      return (
        <Wrapper>
          <NotFoundProperty />
        </Wrapper>
      )
    }

    if (!lastProperty) {
      return <LoadingIndicator />
    }

    let houseTypes = this.normalizeHouseTypes(housetype)

    let houseTypeName = ''
    if (houseTypes[lastProperty.houseType]) {
      houseTypeName = translate('houseType.' + houseTypes[lastProperty.houseType].name)
    }

    let headerImage = null
    let headerImageStyle
    let gallery = null
    let _this = this
    let showGallery = false

    let images = orderBy(lastProperty?.images || [], 'order', ['asc'])
    let headerId

    if (images[0]) {
      headerImageStyle = {
        backgroundImage: `url("${getFileUrl(images[0].filename)}"`
      }

      headerId = images[0].id

      headerImage = (
        <div>
          <HeaderImage>
            <div
              onClick={(e) => {
                _this._showImage(headerId, false)
              }}
              rel="galleryImages"
              className="galleryImages"
              style={headerImageStyle}>
              <div className="container--comments-likes">
                <div>
                  {_this._getImageCommentsCount(images[0].id)}
                  {_this._getImageLikesCount(images[0].id)}
                </div>
              </div>
            </div>
          </HeaderImage>
        </div>
      )
    }

    gallery = []

    for (let img of images) {
      if (img.id === headerId) {
        continue
      }

      let galleryImageStyle = {
        backgroundImage: `url("${getFileUrl(img.filename)}"`
      }

      let _id = img.id
      let galleryImage = (
        <Thumbnail key={`gallery_${_id}`}>
          <div
            onClick={(e) => {
              _this._showImage(_id, false)
            }}
            rel="galleryImages"
            className="galleryImages">
            <div style={galleryImageStyle}>
              <div className="container--comments-likes">
                <div>
                  {_this._getImageCommentsCount(_id)}
                  {_this._getImageLikesCount(_id)}
                </div>
              </div>
            </div>
          </div>
        </Thumbnail>
      )
      showGallery = true
      gallery.push(galleryImage)
    }

    let favouriteIcon = null

    if (lastProperty) {
      let hasFavorite = this.checkHasFavourite(lastProperty.id)

      favouriteIcon = (
        <FavouriteIconWrapper
          className={hasFavorite ? 'state-on' : 'state-off'}
          onClick={this._handleFavouriteToggle}>
          <i className={`fa ${hasFavorite ? 'fa-heart' : 'fa-heart-o'}`} aria-hidden="true" />
          <span>
            {hasFavorite ? translate('common.removeFavourite') : translate('common.addFavourite')}
          </span>
        </FavouriteIconWrapper>
      )
    }

    let commentSection = null

    let commentButtonText =
      lastProperty && lastProperty.isForSale
        ? translate('dashboard.property.contactSeller')
        : translate('dashboard.property.contactOwner')

    if (me && lastProperty.owner?.id && me.id !== lastProperty.owner?.id) {
      commentSection = (
        <div>
          <Spacer />
          <h4>{translate('dashboard.property.infoAboutPropertySeller')}:</h4>
          <CommentToSeller
            hideTitle={true}
            me={me}
            seller={lastProperty.owner}
            property={lastProperty}
            onSubmit={this.props.sendMessage}
            messagesReducer={this.props.messagesReducer}
            resetMessagesSent={this.props.resetMessagesSent}
          />
        </div>
      )
    } else if (!me) {
      commentSection = (
        <div>
          <Spacer />
          <LoginButton
            isButton={true}
            label={commentButtonText}
            redirectTo={`/property/${lastProperty.id}`}
          />
        </div>
      )
    }

    let propertyImageContainer = null
    let imageId = this.props && this.props.imageId ? this.props.imageId : null

    if (imageId) {
      var _imgObj = find(images || [], { id: imageId })

      if (!_imgObj) {
        imageId = null
      }
    }

    if (headerImage || showGallery) {
      propertyImageContainer = (
        <div>
          {headerImage}
          {showGallery ? <ThumbnailWrapper>{gallery}</ThumbnailWrapper> : null}
        </div>
      )
    }

    let propertyDetails = (
      <>
        <PropertyAttribute iconSrc={houseType} description={houseTypeName} />
        <PropertyAttribute iconSrc={houseSize} description={lastProperty.size + ' m²'} />
        {lastProperty.isForSale && (
          <PropertyAttribute
            iconFA={<i className="fa fa-euro" aria-hidden="true" />}
            description={`${numberWithCommas(lastProperty.price)} ${getCurrencySymbol(
              lastProperty.currency
            )}`}
          />
        )}
        {lastProperty.externalurl && (
          <PropertyAttribute
            iconFA={<i className="fa fa-link" aria-hidden="true" />}
            description={
              <Link to={lastProperty.externalurl} target="_blank">
                {translate('house.externalurl')}
              </Link>
            }
          />
        )}
        <FacebookShareLink href="#" onClick={this.onFbShare}>
          <img src={fbBlue} alt="Facebook" />
          <span>{translate('house.fbShare')}</span>
        </FacebookShareLink>
      </>
    )

    return (
      <Wrapper>
        <ImageCommentModal
          me={this.props.me}
          imageId={imageId}
          comments={this.props.comments}
          onClose={_this.closeModal}
          property={this.props.property}
          onSubmit={(e) => {
            _this.props.postComment(e, _this.updateComments)
          }}
          houseTypeName={houseTypeName}
          likeSubmit={(id, value) => {
            _this.props.postLike(id, value, _this.refreshProperty)
          }}
          houseSize={lastProperty.size}
          showImageF={this._showImage}
          images={images}
          removeComment={this.props.removeComment}
        />
        <MainColumn>
          <header>
            <div>
              <h1>
                {lastProperty.locationParts.streetName} {lastProperty.locationParts.streetNumber}
              </h1>
              <p>
                {lastProperty.locationParts.postalCode} {lastProperty.locationParts.city}
              </p>
            </div>
            {me &&
              (!lastProperty.owner || (lastProperty.owner && me.id !== lastProperty.owner.id)) &&
              favouriteIcon}
          </header>
          <MobileWrapper>
            {propertyDetails}
            {lastProperty.geolocation && (
              <MapWrapper>
                <GoogleMapProvider>
                  <GoogleMapView center={lastProperty.geolocation} />
                </GoogleMapProvider>
              </MapWrapper>
            )}
          </MobileWrapper>
          {propertyImageContainer}
          <h3>{translate('dashboard.property.infoAboutProperty')}:</h3>
          <p className="property--description">
            {lastProperty.description || translate('dashboard.property.noDescriptionProperty')}
          </p>
        </MainColumn>
        <MobileWrapper>{commentSection}</MobileWrapper>
        <SideColumn>
          {lastProperty.geolocation && (
            <MapWrapper>
              <GoogleMapProvider>
                <GoogleMapView center={lastProperty.geolocation} />
              </GoogleMapProvider>
            </MapWrapper>
          )}
          {propertyDetails}
          {commentSection}
        </SideColumn>
      </Wrapper>
    )
  }
}

export default PropertyView
