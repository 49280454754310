import React, { Component } from 'react'

import styled from 'styled-components/macro'

import Color from '../../styles/color'
import Variables from '../../styles/variables'

const FieldContainer = styled.div`
  margin-bottom: ${Variables.gutterHalf};

  > div {
    position: relative;

    select {
      border: 0.1rem solid ${Color.border};
      display: block;
      padding: 1.5rem;
      width: 100%;
      font-size: ${Variables.bodyFontSize};
      line-height: ${Variables.bodyLineHeight};
      border-radius: ${Variables.borderRadius};
    }
  }
`

export default class SelectField extends Component {
  static defaultProps = {
    showLabel: true
  }

  render() {
    const { showLabel, label, required, children, input } = this.props

    return (
      <FieldContainer>
        {showLabel && <label>{label}</label>}
        <div>
          <select {...input} required={required || false}>
            {children}
          </select>
        </div>
      </FieldContainer>
    )
  }
}
