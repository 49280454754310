import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import Link from './Link'
import Avatar from './Avatar'

import Color from '../../styles/color'
import Variables from '../../styles/variables'
import messageLetter from '../../images/message-letter.svg'

const Wrapper = styled.div`
  margin: ${Variables.gutter} 0;
  width: 100%;
  a {
    width: 100%;
    text-decoration: none;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;
  border: 0.1rem solid ${Color.border};
  border-radius: ${Variables.borderRadius};
  padding: 1rem 5rem 1rem 1rem;

  a {
    text-decoration: none;
  }

  > strong {
    margin-left: ${Variables.gutterHalf};
  }

  > div:last-of-type {
    width: 2rem;
    position: absolute;
    top: 50%;
    right: 2rem;
    transform: translateY(-50%);

    > img {
      width: 100%;
      height: auto;
      vertical-align: top;
    }
  }
`

export default class UserListItem extends Component {
  static propTypes = {
    avatarSrc: PropTypes.string,
    linkUrl: PropTypes.string,
    name: PropTypes.string
  }

  render() {
    const { avatarSrc, linkUrl, name } = this.props

    const content = (
      <ContentWrapper>
        {avatarSrc && <Avatar src={avatarSrc} />}
        {name && <strong>{name}</strong>}
        <div>
          <img src={messageLetter} />
        </div>
      </ContentWrapper>
    )

    return <Wrapper>{(linkUrl && <Link to={linkUrl}>{content}</Link>) || content}</Wrapper>
  }
}
