import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import Measure from 'react-measure'

import Variables from '../../styles/variables'

const Wrapper = styled.div`
  overflow: hidden;
  max-height: ${(props) => (props.open ? props.maxHeight : 0)}px;
  opacity: ${(props) => (props.open ? 1 : 0)};
  transition: all ${Variables.slowSpeed} ${Variables.defaultEasing};
`

export default class Collapsible extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dimensions: {
        width: -1,
        height: -1
      }
    }
  }

  static propTypes = {
    open: PropTypes.bool
  }

  render() {
    const { height } = this.state.dimensions
    const { children, open } = this.props

    return (
      <Wrapper open={open} maxHeight={height}>
        <Measure
          onMeasure={(dimensions) => {
            this.setState({ dimensions })
          }}>
          <div>{children}</div>
        </Measure>
      </Wrapper>
    )
  }
}
