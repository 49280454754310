import { createApiRequest } from '../utils/Api'
import { fetchStart, fetchEnd } from './FetchActions'
import * as ActionTypes from '../constants/ActionTypes'

export function getImageCommentById(entityId) {
  let fetchId = 'comment_by_id'
  let URL = `/comments/?entity=image&entityId=${entityId}`

  return (dispatch, getState) => {
    let apiRequest = createApiRequest(getState().auth.accessToken)

    dispatch(fetchStart(fetchId))

    return apiRequest({ path: URL })
      .then((json) => {
        dispatch({ type: ActionTypes.COMMENTS_GET_SUCCESS, data: json.data })
        dispatch(fetchEnd(fetchId))
      })
      .catch((error) => {
        dispatch(fetchEnd(fetchId))
      })
  }
}

export function createImageComment(data, onSuccess) {
  let fetchId = 'create_image_comment'
  let URL = `/comments/?entity=image&entityId=${data.entityId}`

  return (dispatch, getState) => {
    let apiRequest = createApiRequest(getState().auth.accessToken)

    dispatch(fetchStart(fetchId))

    return apiRequest({ path: URL, method: 'POST', json: data })
      .then((json) => {
        if (onSuccess && typeof onSuccess === 'function') {
          onSuccess(json)
        }

        dispatch(fetchEnd(fetchId))
      })
      .catch((error) => {
        dispatch(fetchEnd(fetchId))
      })
  }
}

export function createImageLike(id, value, onSuccess) {
  let fetchId = 'create_image_like'
  let URL = `/likes/?entity=image&entityId=${id}`

  let data = { entity: 'image', entityId: id, value: value }

  return (dispatch, getState) => {
    let apiRequest = createApiRequest(getState().auth.accessToken)

    dispatch(fetchStart(fetchId))

    return apiRequest({ path: URL, method: 'POST', json: data })
      .then((json) => {
        if (onSuccess && typeof onSuccess === 'function') {
          onSuccess(json)
        }

        dispatch(fetchEnd(fetchId))
      })
      .catch((error) => {
        dispatch(fetchEnd(fetchId))
      })
  }
}
