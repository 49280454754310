import React, { Component } from 'react'
import translate from 'counterpart'
import moment from 'moment'
import { orderBy } from 'lodash'
import { Link } from 'react-router'

import Color from '../../styles/color'
import Variables from '../../styles/variables'
import styled from 'styled-components/macro'

const Wrapper = styled.div`
  padding: 1.5rem;
  border: 0.1rem solid ${Color.border};
  border-radius: ${Variables.borderRadius};
  margin-bottom: ${Variables.gutterHalf};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

export default class LicensesList extends Component {
  constructor(props) {
    super(props)
    this.cancelSubscription = this.cancelSubscription.bind(this)
  }

  cancelSubscription(id) {
    if (confirm(translate('profile.license.confirm'))) {
      this.props.cancelSubscription(id)
    }
  }

  render() {
    let licenses = []
    let expireLink = <Link to="/subscribe/profile">{translate('common.subscribe')}</Link>

    if (this.props.license && this.props.license?.userLicenses) {
      licenses = orderBy(this.props.license.userLicenses, 'expires', 'desc')
      let newestLicense = licenses[0]

      if (newestLicense) {
        let payment = newestLicense.payment

        let now = moment()
        let expiresTime = moment(newestLicense.expires)

        if (expiresTime.isAfter(now)) {
          let cancelLink = null

          if (payment && payment.id && payment.status !== 'cancelled') {
            cancelLink = (
              <a
                style={{ display: 'block', textAlign: 'right' }}
                href="#"
                onClick={(e) => {
                  e.preventDefault()
                  this.cancelSubscription(payment.id)
                }}>
                {translate('profile.license.cancel')}
              </a>
            )
          }

          expireLink = (
            <>
              <span>
                {translate('profile.license.paid')}{' '}
                <small>
                  ({translate('profile.license.expires')} {moment(expiresTime).format('DD.MM.YYYY')}
                  )
                </small>
              </span>
              {cancelLink}
            </>
          )
        }
      }
    }

    return (
      <Wrapper>
        <div>{translate('profile.license.license')}&nbsp;</div>
        <div>{expireLink}</div>
      </Wrapper>
    )
  }
}
