import React, { Component } from 'react'
import { connect } from 'react-redux'

import NotFoundPage from '../components/notfound/NotFoundPage'

import LoggedInContainer from './LoggedInContainer'
import LoggedOutContainer from './LoggedOutContainer'

class NotFoundContainer extends Component {
  getElementByAction(props) {
    const { auth, route } = props

    if (auth?.user) {
      return (
        <LoggedInContainer navigationRoute={route || null} maxWidth="col">
          <NotFoundPage />
        </LoggedInContainer>
      )
    } else {
      return (
        <LoggedOutContainer navigationRoute={route || null} maxWidth="col">
          <NotFoundPage />
        </LoggedOutContainer>
      )
    }
  }

  render() {
    let obj = this.getElementByAction(this.props)

    return <div>{obj}</div>
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth
  }
}

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(NotFoundContainer)
