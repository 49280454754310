import React, { Component } from 'react'
import styled from 'styled-components/macro'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import translate from 'counterpart'

import Button from '../shared/Button'
import ErrorMessage from '../shared/ErrorMessage'
import InputField from '../shared/InputField'
import Spacer from '../shared/Spacer'

const Wrapper = styled.div``

const ColWrapper = styled.div`
  width: 25rem;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
`
class CommentForm extends Component {
  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    this.props.initialize({ entity: this.props.entity, entityId: this.props.entityId })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.entity !== nextProps.entity) {
      nextProps.change('entity', nextProps.entity)
    }
    if (this.props.entityId !== nextProps.entityId) {
      nextProps.change('entityId', nextProps.entityId)
    }
  }

  handleSubmit(e) {
    if (this.props.handleSubmit) {
      this.props.handleSubmit(e)
      this.props.change('comment', '')
    }
  }

  render() {
    let errorMsg

    if (this.state && this.state.error) {
      errorMsg = (
        <div className="extraMarginForGenericForms">
          <ErrorMessage>{this.state.error}</ErrorMessage>
        </div>
      )
    }

    return (
      <Wrapper>
        <div>
          <form onSubmit={this.handleSubmit}>
            <Field
              id="comment"
              name="comment"
              className={`input-externalurl`}
              placeholder={translate('comment.commentPlaceholder')}
              component={InputField}
              type="text"
              required={true}
            />

            {errorMsg}
            <ColWrapper>
              <Spacer />
              <Button text={translate('comment.submit')} type="submit" />
            </ColWrapper>
          </form>
        </div>
      </Wrapper>
    )
  }
}

CommentForm = reduxForm({
  form: 'commentForm'
})(CommentForm)

CommentForm = connect((state) => {
  return {}
})(CommentForm)

export default CommentForm
