import React, { Component } from 'react'
import styled from 'styled-components/macro'

import translate from 'counterpart'

import EmptyFavourite from './items/EmptyFavourite'
import PropertyBlock from './items/PropertyBlock'

import Color from '../../styles/color'
import Variables from '../../styles/variables'

const GridWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -1rem;
  margin-right: -1rem;
`

const PropertyBlockWrapper = styled.div`
  margin-bottom: ${Variables.gutter};
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;

  @media (min-width: ${Variables.bpSmall}) {
    width: 50%;
  }
`

const FavoritesWrapper = styled.div`
  padding-top: 1.5rem;
`

export default class FavouritesFrontpage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      myPropertiesRequested: false,
      dropDownSelected: null
    }

    this.requestMyProperties = this.requestMyProperties.bind(this)
  }

  componentDidMount() {
    this.requestMyProperties()
  }

  requestMyProperties() {
    this.props.getMyFavourites()
  }

  renderWatchResults(results) {
    let returnArr = []
    for (let i in results) {
      let property = results[i]
      returnArr.push(
        <PropertyBlockWrapper key={`property_${i}`}>
          <PropertyBlock property={property} imageSize={1024} houseType={this.props.housetype} />
        </PropertyBlockWrapper>
      )
    }
    return returnArr
  }

  render() {
    let favs = this.props.favourites?.favourites || []

    return (
      <FavoritesWrapper>
        {favs.length !== 0 ? (
          <>
            <h2>
              {translate('shared.navigation.favourites')}{' '}
              <span style={{ color: Color.green }}>{favs.length}</span>
            </h2>
            <GridWrapper>{this.renderWatchResults(favs)}</GridWrapper>
          </>
        ) : (
          <EmptyFavourite />
        )}
      </FavoritesWrapper>
    )
  }
}
