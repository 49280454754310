import React, { Component } from 'react'
import styled from 'styled-components/macro'
import Color from '../../styles/color'
import Variables from '../../styles/variables'

const TextAreaContainer = styled.div`
  margin-bottom: ${Variables.gutterHalf};

  > div {
    position: relative;

    textarea {
      border: 0.1rem solid ${Color.border};
      display: block;
      padding: 1.5rem;
      width: 100%;
      font-size: ${Variables.bodyFontSize};
      line-height: ${Variables.bodyLineHeight};
      border-radius: ${Variables.borderRadius};
      resize: vertical;
      font-family: ${Variables.fontFamily};
    }
  }
`

export default class TextArea extends Component {
  static defaultProps = {
    showLabel: true,
    rows: 6
  }

  render() {
    const props = this.props

    return (
      <TextAreaContainer>
        {props.showLabel && <label>{props.label}</label>}
        <div>
          <textarea
            {...props.input}
            className={`generic-input ${props.className}`}
            placeholder={props.placeholder}
            type={props.type}
            rows={props.rows}
          />
        </div>
      </TextAreaContainer>
    )
  }
}
