import * as ActionTypes from '../constants/ActionTypes'

const initialState = {
  area: null,
  lastArea: null,
  areaPrice: null,
  updateTime: 0
}

function AreaReducer(state = initialState, action) {
  var date = new Date()
  var now = date.getTime()
  if (action) {
    switch (action.type) {
      case ActionTypes.GET_AREA:
        return Object.assign({}, state, {
          lastArea: action.data,
          updateTime: now
        })
      case ActionTypes.GET_AREA_ALL:
        return Object.assign({}, state, {
          area: action.data,
          updateTime: now
        })
      case ActionTypes.GET_AREA_PRICE:
        return Object.assign({}, state, {
          areaPrice: action.data,
          updateTime: now
        })
      case ActionTypes.LOGOUT_SUCCESS:
        return Object.assign({}, state, {
          area: null,
          lastArea: null,
          areaPrice: null,
          updateTime: 0
        })
      default:
        return state
    }
  }
}

export default AreaReducer
