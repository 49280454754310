import React from 'react'

import Color from '../../styles/color'
import Logo from './Logo'

import styled from 'styled-components/macro'

import Variables from '../../styles/variables'

const UserHeaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: ${Variables.navigationHeight};
  width: 100%;
  border-bottom: 1px solid ${Color.border};
  background-color: ${Color.white};
  z-index: 800;

  > div {
    position: relative;
    max-width: ${Variables.maxWidthPage};
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
  }
`

const LogoWrapper = styled.div`
  position: absolute;
  left: ${Variables.gutter};
`

const UserHeader = ({ children }) => {
  return (
    <UserHeaderContainer className="userHeader">
      <div>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
        {children}
      </div>
    </UserHeaderContainer>
  )
}

export default UserHeader
