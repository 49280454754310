import * as ActionTypes from '../constants/ActionTypes'

const initialState = {
  property: null,
  lastUser: null,
  me: null,
  updateTime: 0,
  notifications: null,
  activation: null
}

function UserReducer(state = initialState, action) {
  var date = new Date()
  var now = date.getTime()

  if (action) {
    switch (action.type) {
      case ActionTypes.SET_USER:
        return Object.assign({}, state, {
          lastUser: action.data,
          updateTime: now
        })

      case ActionTypes.LOGOUT_SUCCESS:
        return Object.assign({}, state, {
          property: null,
          lastUser: null,
          me: null,
          updateTime: 0,
          notifications: null
        })
      default:
        return state
    }
  }
}

export default UserReducer
