import translate from 'counterpart'
import React from 'react'
import { REACT_APP_AUTH_WEB_PATH } from '../../constants'
import Link from './Link'
import styled from 'styled-components/macro'

const LoginLink = styled(Link)`
  flex: none;

  > span {
    padding: 0.75rem 1.5rem;
    font-size: 1.2rem;
  }
`

const LoginButton = ({
  label = translate('frontpage.registerOrLogin'),
  isButton,
  redirectTo = location.pathname,
  style,
  className,
  size
}) => {
  return (
    <LoginLink
      type={isButton ? 'button' : 'menu'}
      style={style}
      size={size}
      className={className}
      to={`${REACT_APP_AUTH_WEB_PATH}?redirectToPath=${redirectTo}`}>
      {label}
    </LoginLink>
  )
}

export default LoginButton
