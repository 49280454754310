import React, { Component } from 'react'

import styled from 'styled-components/macro'
import translate from 'counterpart'
import { changePage } from '../../actions/PageActions'
import Button from '../shared/Button'
import Spacer from '../shared/Spacer'
import userNoProperty from '../../images/user-no-property.svg'

const Wrapper = styled.div`
  text-align: center;
  padding-top: 2rem;
  margin: 0 auto;
`

const ImageWrapper = styled.div`
  width: 10rem;
  display: inline-block;

  img {
    max-width: 100%;
    height: auto;
  }
`

export default class EmptyWatcher extends Component {
  render() {
    return (
      <Wrapper>
        <ImageWrapper>
          <img src={userNoProperty} />
        </ImageWrapper>
        <h1>{translate('dashboard.searchWatcher.addTitle')}</h1>
        <p>{translate('dashboard.searchWatcher.addDescription')}</p>
        <Spacer multiplier={2} />
        <div>
          <Button
            text={translate('dashboard.searchWatcher.addButton')}
            type="button"
            onClick={(e) => {
              changePage('/find/new')
            }}
          />
        </div>
      </Wrapper>
    )
  }
}
