import React, { useState, useCallback, useEffect, useMemo } from 'react'
import GoogleMapAreas from '../googlemap/GoogleMapAreas'
import translate from 'counterpart'
import Button from '../shared/Button'
import styled from 'styled-components/macro'
import Variables from '../../styles/variables'
import Color from '../../styles/color'
import Collapsible from '../shared/Collapsible'
import { InfoIcon } from '../shared/icons'

const GoogleMapWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  @media (min-width: ${Variables.bpSmall}) {
    max-height: 85vh;
  }
`

const InfoWrapper = styled.div`
  position: absolute;
  bottom: ${Variables.gutter};
  left: 0;
  background-color: ${Color.white};
  border-radius: ${Variables.borderRadius};
  padding: ${Variables.gutter};
  margin: 0 ${Variables.gutter} 0;
  width: calc(100% - (${Variables.gutter} * 2));
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  @media (min-width: ${Variables.bpSmall}) {
    display: block;
    top: 7rem;
    bottom: auto;
    right: ${Variables.gutter};
    left: auto;
    width: 28rem;
  }

  p {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-size: 1.15rem;
  }

  header {
    margin-bottom: ${Variables.gutterHalf};

    h3 {
      margin-bottom: 0;
    }

    &:first-of-type {
      h3 {
        margin-top: 0;
      }
    }
  }

  footer {
    display: flex;
    margin-left: -0.5rem;
    margin-right: -0.5rem;

    > div {
      width: calc(100% - 1rem);
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
  }
`

const ActionButton = styled(Button)``

const InfoButton = styled.button`
  background: transparent;
  border: none;
  padding: 0;
  display: block;
  cursor: pointer;
`

const InstructionsWrapper = styled.div`
  display: flex;
`

const ActionContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.75rem;
`

const isTouch =
  'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0

function SearchWatcherMap({ onDone, circles, maxCircles }) {
  let [showInstructions, setShowInstructions] = useState(false)
  let [tempCircles, setTempCircles] = useState(circles || [])

  let doneDisabled = useMemo(() => tempCircles.length === 0, [tempCircles])

  useEffect(() => {
    setTempCircles(circles)
  }, [circles])

  let addCircle = useCallback(
    (circleConfig) => {
      setTempCircles((currentCircles) => {
        // Limit circles to MAX_CIRCLES
        if (currentCircles.length >= maxCircles) {
          return currentCircles
        }

        return [...currentCircles, circleConfig]
      })
    },
    [maxCircles]
  )

  let removeCircle = useCallback((circleConfig) => {
    setTempCircles((currentCircles) => {
      let circleIndex = currentCircles.findIndex(
        (circle) => circle.lat === circleConfig.lat && circle.lng === circleConfig.lng
      )

      if (circleIndex === -1) {
        return currentCircles
      }

      let nextMapCircles = [...currentCircles]
      nextMapCircles.splice(circleIndex, 1)
      return nextMapCircles
    })
  }, [])

  return (
    <GoogleMapWrapper className="googleMapWrapper">
      <GoogleMapAreas removeCircle={removeCircle} addCircle={addCircle} circles={tempCircles} />
      <InfoWrapper>
        <div style={{ marginBottom: '0.5rem' }}>
          {translate('dashboard.searchWatcher.circles')}:{' '}
          <strong style={{ color: tempCircles.length === maxCircles ? Color.green : 'black' }}>
            {tempCircles.length} / {maxCircles}
          </strong>
        </div>

        <InstructionsWrapper>
          <div>
            <p>{translate('dashboard.searchWatcher.searchWatcherCirclesInstructionsIntro')}</p>
            <Collapsible open={showInstructions}>
              <p>
                {translate(
                  isTouch
                    ? 'dashboard.searchWatcher.searchWatcherCirclesInstructionsTouch'
                    : 'dashboard.searchWatcher.searchWatcherCirclesInstructionsMouse'
                )}
              </p>
            </Collapsible>
          </div>
          <div>
            <InfoButton onClick={() => setShowInstructions((current) => !current)}>
              <InfoIcon />
            </InfoButton>
          </div>
        </InstructionsWrapper>
        <ActionContainer>
          <ActionButton
            size="small"
            disabled={doneDisabled}
            text={translate('common.ready')}
            type="button"
            onClick={() => onDone(tempCircles)}
          />
          <ActionButton
            size="small"
            layout="outline"
            text={translate('common.cancel')}
            type="button"
            onClick={() => {
              setTempCircles(circles)
              onDone()
            }}
          />
        </ActionContainer>
      </InfoWrapper>
    </GoogleMapWrapper>
  )
}

export default SearchWatcherMap
