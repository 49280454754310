import * as ActionTypes from '../constants/ActionTypes'
import { createApiRequest } from '../utils/Api'
import { fetchStart, fetchEnd } from './FetchActions'
import * as PageActions from './PageActions'
import { getFileUrl } from '../utils/getFileUrl'
import { fetchCurrentUser } from './AuthActions'
import { signOut } from 'supertokens-auth-react/recipe/thirdpartypasswordless'

export function receiveUnloadUser() {
  return {
    type: ActionTypes.SET_USER,
    data: null
  }
}

export function receiveGetUser(data) {
  return {
    type: ActionTypes.SET_USER,
    data: data
  }
}

export function getUserById(userId) {
  const URL = `/user/${userId}`

  return (dispatch, getState) => {
    let apiRequest = createApiRequest(getState().auth.accessToken)
    dispatch(fetchStart('user'))

    return apiRequest({ path: URL })
      .then((json) => {
        dispatch(receiveGetUser(json.data))
        dispatch(fetchEnd('user'))
      })
      .catch((error) => {
        dispatch(fetchEnd('user'))
      })
  }
}

export function getAvatarUrl(user) {
  if (user?.image) {
    return getFileUrl(user?.image)
  }

  return '/assets/images/user_avatar_placeholder.png'
}

export function receiveGetStats(data) {
  return {
    type: ActionTypes.ADMIN_STATS_RECEIVED,
    data: data
  }
}

export function getAppStats() {
  const URL = '/statistic/findadmin'

  return (dispatch) => {
    let apiRequest = createApiRequest()

    return apiRequest({ path: URL })
      .then((json) => {
        dispatch(receiveGetStats(json.data))
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

export function getUserNameShort(user) {
  return user.nickname || user.name || user.firstName
}

function profileError(error) {
  return {
    type: ActionTypes.PROFILE_FAILURE,
    error: error
  }
}

function profileSuccess() {
  return {
    type: ActionTypes.PROFILE_SUCCESS
  }
}

async function logout(dispatch) {
  dispatch(receiveUnloadUser())
  await signOut()
  window.location.href = '/'
}

export function deleteProfile() {
  return (dispatch) => {
    let apiRequest = createApiRequest()

    return apiRequest({ path: `/delete-user`, method: 'DELETE' })
      .then(() => logout(dispatch))
      .catch(() => logout(dispatch))
  }
}

export function putProfile(formData) {
  return (dispatch) => {
    let apiRequest = createApiRequest()

    return apiRequest({ path: `/update-user`, method: 'PATCH', json: formData })
      .then((response) => {
        if (response.success) {
          dispatch(profileSuccess())
          dispatch(fetchCurrentUser())

          let updatedData = response.data

          // Verify changed email. Email is only set if actually updated by backend logic.
          if (updatedData?.email) {
            PageActions.changePage('/login/verify-email', true)
          } else {
            PageActions.changePage('/profile')
          }
        } else {
          dispatch(profileError(response.error || 'PROFILE_ERROR'))
        }
      })
      .catch((error) => {
        dispatch(profileError(error))
      })
  }
}
