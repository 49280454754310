import Logo from './Logo'
import LoginButton from './LoginButton'
import React from 'react'
import Link from './Link'
import translate from 'counterpart'
import { changeLanguage } from '../../actions/LanguageActions'
import { connect } from 'react-redux'
import styled from 'styled-components/macro'
import Variables from '../../styles/variables'
import Color from '../../styles/color'

const UserHeaderContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  height: ${Variables.navigationHeight};
  width: 100%;
  border-bottom: 1px solid ${Color.border};
  background-color: ${Color.white};

  > div {
    position: relative;
    max-width: ${Variables.maxWidthPage};
    padding: 0 ${Variables.gutter};
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
  }
`

const LogoWrapper = styled.div`
  position: absolute;
  left: ${Variables.gutter};
`

const NavBPSmall = '650px'

const Nav = styled.nav`
  max-width: calc(100% - 10rem);
  font-size: ${Variables.smallFontSize};

  @media (min-width: ${NavBPSmall}) {
    font-size: ${Variables.bodyFontSize};
  }

  > ul {
    list-style: none;
    display: flex;
    align-items: center;
    padding: 0;

    > li {
      padding-left: ${Variables.gutterHalf};
      padding-right: ${Variables.gutterHalf};

      &:last-of-type {
        padding-right: 0;
      }
    }
  }
`

const LoggedOutHeader = ({ onChangeLanguage, language, children }) => {
  const handleChangeLanguage = (to) => (e) => {
    e.preventDefault()
    onChangeLanguage(to)
  }

  return (
    <UserHeaderContainer>
      <div>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
        <Nav>
          <ul>
            {children}
            <li>
              <Link
                type="menu"
                onClick={handleChangeLanguage(language === 'en' ? 'fi' : 'en')}
                to="#">
                {translate('common.changeLanguage')}
              </Link>
            </li>
            <li>
              <LoginButton isButton={true} size="medium" />
            </li>
          </ul>
        </Nav>
      </div>
    </UserHeaderContainer>
  )
}

const mapStateToProps = (state) => {
  return {
    language: state.auth.language
  }
}

const mapDispatchToProps = (dispatch) => ({
  onChangeLanguage: (lang) => {
    dispatch(changeLanguage(lang))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(LoggedOutHeader)
