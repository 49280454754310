import React, { Component } from 'react'
import styled from 'styled-components/macro'
import translate from 'counterpart'
import Color from '../../styles/color'
import Variables from '../../styles/variables'
import Button from '../shared/Button'
import Spacer from '../shared/Spacer'
import ErrorMessage from '../shared/ErrorMessage'
import poweredByStripe from '../../images/secure-payments-powered-by-stripe.gif'
import { CardElement } from '@stripe/react-stripe-js'
import { createApiRequest } from '../../utils/Api'
import { connect } from 'react-redux'
import { confirmLicensePayment } from '../../actions/LicenseActions'

const Wrapper = styled.div`
  max-width: 50rem;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
`

const ColWrapper = styled.div`
  max-width: 25rem;
  margin-left: auto;
  margin-right: auto;
`

const ImgWrapper = styled.div`
  width: 27rem;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: ${Variables.gutter};

  img {
    max-width: 100%;
  }
`

const StripeCardElement = styled(CardElement)`
  border: 0.1rem solid ${Color.border};
  padding: 1.5rem;
  border-radius: ${Variables.borderRadius};
`

const PaymentForm = styled.form`
  ${(p) =>
    p.isLoading
      ? `
  pointer-events: none;
  opacity: 0.5;
  `
      : ''}
`

const stripeStyle = {
  base: {
    color: Color.basic,
    lineHeight: '1.4',
    fontFamily: '"proxima-nova", Helvetica, sans-serif',
    fontSmoothing: 'antialiased',
    fontSize: '14px',
    '::placeholder': {}
  },
  invalid: {
    color: Color.red,
    iconColor: Color.red
  }
}

class GetLicense extends Component {
  constructor(props) {
    super(props)

    this.state = {
      errorText: null,
      isLoading: false
    }
  }

  componentDidMount() {}

  handleStripeChange = (event) => {
    if (event.error) {
      this.setState({ errorText: event.error.message })
    } else {
      this.setState({ errorText: null })
    }
  }

  handleOnSubmit = async (event) => {
    event.preventDefault()

    const { stripe, elements, auth, confirmLicensePayment } = this.props

    let user = auth?.user?.profile

    if (!stripe || !elements || !user) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }

    this.setState({ isLoading: true })

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement)

    let { error: paymentMethodError, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: user.name,
        email: user.email
      }
    })

    if (paymentMethodError) {
      console.log('[paymentMethodError]', paymentMethodError)
      return this.setState({ errorText: paymentMethodError.message, isLoading: false })
    }

    var url = '/license'
    let apiRequest = createApiRequest()

    try {
      var { data: subscription, error: subscriptionError } = await apiRequest({
        path: url,
        method: 'POST',
        json: { paymentMethodId: paymentMethod.id }
      })
    } catch ({ data }) {
      console.log(data)
      subscriptionError = data?.error
    }

    if (subscriptionError) {
      console.log('[subscriptionError]', subscriptionError)

      return this.setState({
        errorText: subscriptionError?.message || subscriptionError,
        isLoading: false
      })
    }

    switch (subscription.status) {
      case 'active':
        confirmLicensePayment(subscription.id)
        break
      case 'incomplete':
        const { error } = await stripe.confirmCardPayment(
          subscription.latest_invoice.payment_intent.client_secret
        )

        if (error) {
          return this.setState({ errorText: error.message, isLoading: false })
        } else {
          confirmLicensePayment(subscription.id)
        }
        break

      default:
        console.log(`Unknown Subscription status: ${subscription.status}`)
    }

    this.setState({ errorText: null, isLoading: false })
  }

  render() {
    let { stripe } = this.props
    let { isLoading = false } = this.state

    return (
      <Wrapper>
        <h1>{translate('subscription.title')}</h1>
        <p>{translate('subscription.description')}</p>
        <PaymentForm isLoading={isLoading} onSubmit={this.handleOnSubmit}>
          <label htmlFor="card-element">{translate('common.creditcardNumber')}</label>
          <StripeCardElement options={{ style: stripeStyle }} />
          <ImgWrapper>
            <img src={poweredByStripe} />
          </ImgWrapper>
          {this.state.errorText && (
            <ErrorMessage id="card-errors">{this.state.errorText}</ErrorMessage>
          )}
          <Spacer />
          <ColWrapper>
            <Button type="submit" text={translate('common.buy')} disabled={!stripe} />
          </ColWrapper>
        </PaymentForm>
      </Wrapper>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth
  }
}

const mapDispatchToProps = (dispatch) => ({
  confirmLicensePayment: (subscriptionId) => dispatch(confirmLicensePayment(subscriptionId))
})

export default connect(mapStateToProps, mapDispatchToProps)(GetLicense)
