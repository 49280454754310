import { REACT_APP_FILESTORAGE_URL } from '../constants'
import properUrlJoin from 'proper-url-join'
import { getApiUrl } from './Api'

export function getFileUrl(fileName) {
  let fileUrl = fileName

  if (fileName && !fileName.startsWith('http')) {
    fileUrl = properUrlJoin(REACT_APP_FILESTORAGE_URL, fileName)
  }

  return fileUrl
}

export function getFileUrlWithStorageId(storageId, asSrc = false) {
  if (!storageId || storageId.startsWith('http')) {
    return storageId
  }

  return getApiUrl(`/storage/${storageId}?src=${asSrc}`)
}
