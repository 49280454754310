import React, { Component } from 'react'
import styled from 'styled-components/macro'

import translate from 'counterpart'
import Variables from '../../styles/variables'
import CommentForm from './CommentForm'
import CommentsList from './CommentsList'
import Measure from 'react-measure'

import * as UserActions from '../../actions/UserActions'
import LoginButton from '../shared/LoginButton'

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  padding: 0 0 0 0rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  .comment--property-info {
    text-align: center;
    padding: 3rem 2rem 6rem;
    flex-grow: 0;
    flex-shrink: 0;

    @media (min-width: ${Variables.bpSmall}) {
      padding: 3rem 5rem 6rem;
    }

    h1 {
      font-size: 3.5rem;
    }
    .comment--property-info-box {
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
      text-align: ${(props) => (props.width < 375 ? 'left' : 'center')};
      justify-content: space-between;

      .comment--info-box {
        margin: 0 0 0.5rem;
        width: ${(props) => (props.width < 375 ? '100%' : 'auto')};
      }
      i.fa {
        opacity: 0.25;
        padding: 0 0.5rem;
        box-sizing: border-box;
      }
    }
  }
  .comment--list {
    flex-grow: 1;
    flex-shrink: 1;
    overflow-y: auto;
    padding: 0 2rem 0;
    box-sizing: border-box;

    @media (min-width: ${Variables.bpSmall}) {
      padding: 0 5rem 0;
    }
  }
  .comment--form {
    flex-grow: 0;
    flex-shrink: 0;
    padding: 0 2rem 2rem;

    @media (min-width: ${Variables.bpSmall}) {
      padding: 0 5rem 2rem;
    }

    form {
      > div {
        > label {
          display: none;
        }
      }
    }
  }
`

class CommentArea extends Component {
  constructor(props) {
    super(props)
    this.getPropertyName = this.getPropertyName.bind(this)
    this.state = { dimensions: { width: 1200, height: 900 } }
  }

  getPropertyName(props) {
    var propertyName = ''

    if (props.property?.lastProperty?.locationParts) {
      var locationParts = props.property.lastProperty.locationParts

      if (locationParts) {
        if (locationParts.area && locationParts.city) {
          propertyName = locationParts.area + ', ' + locationParts.city
        } else if (locationParts.area) {
          propertyName = locationParts.area
        } else if (locationParts.city && locationParts.postalCode) {
          propertyName = locationParts.postalCode + ' ' + locationParts.city
        } else if (locationParts.city) {
          propertyName = locationParts.city
        }
      }
    }

    return propertyName
  }

  getUserNameShort(userId) {
    return UserActions.getUserNameShort(userId)
  }

  getUserName(user) {
    return user.name
  }

  render() {
    const { width } = this.state.dimensions
    var propertyName = this.getPropertyName(this.props)

    var houseOwner = ''

    //console.log(["-- render --", this.props])
    if (this.props.property?.lastProperty?.owner) {
      houseOwner = this.getUserNameShort(this.props.property.lastProperty.owner)
    }
    //console.log(["-- dimensions --", this.state.dimensions.width])
    return (
      <Wrapper width={width || 1200}>
        <Measure
          onMeasure={(dimensions) => {
            this.setState({ dimensions })
          }}>
          <div>
            <div className="comment--property-info">
              <h1>{propertyName}</h1>
              <div className="comment--property-info-box">
                <div className="comment--housetype comment--info-box">
                  <i className="fa fa-home" aria-hidden="true"></i>
                  {this.props.houseTypeName}
                </div>
                <div className="comment--housesize comment--info-box">
                  <i className="fa fa-square" aria-hidden="true"></i>
                  {this.props.houseSize} m²
                </div>
                <div className="comment--houseowner comment--info-box">
                  <i className="fa fa-user" aria-hidden="true"></i>
                  {houseOwner}
                </div>
              </div>
            </div>

            <div className="comment--list">
              <CommentsList {...this.props} removeComment={this.props.removeComment} />
            </div>
            <div className="comment--form">
              {this.props.star || null}
              {this.props.me?.id && (
                <CommentForm
                  entityId={this.props.entityId}
                  entity={this.props.entity}
                  onSubmit={this.props.onSubmit}
                />
              )}
              {!this.props.me?.id && (
                <LoginButton label={translate('wizard.registerLogin')} isButton={true} />
              )}
            </div>
          </div>
        </Measure>
      </Wrapper>
    )
  }
}

export default CommentArea
