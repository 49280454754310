import React from 'react'
import styled from 'styled-components/macro'
import translate from 'counterpart'
import Button from './Button'
import Color from '../../styles/color'
import { Notice } from './Notice'
import UploadImageField from './UploadImageField'

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2rem;
`

const AvatarImageField = (props) => {
  return (
    <UploadImageField {...props} isRound={true}>
      {({ hasImage, name, selectImage, removeImage }) => (
        <>
          <ButtonRow>
            <Button
              text={translate('profile.changeProfilePicture')}
              type="button"
              layout="outline"
              onClick={selectImage}
              data-elementname={name}
            />
            {hasImage && (
              <Button
                text={translate('profile.removeProfilePicture')}
                type="button"
                layout="link"
                style={{ color: Color.red }}
                onClick={removeImage}
                data-elementname={name}
              />
            )}
          </ButtonRow>
          <Notice>{translate('common.imageRequirements')}</Notice>
        </>
      )}
    </UploadImageField>
  )
}

export default AvatarImageField
