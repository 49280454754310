import * as ActionTypes from '../constants/ActionTypes'

const initialState = {
  comments: null,
  timestamp: 0
}

function CommentsReducer(state = initialState, action) {
  var date = new Date()
  var now = date.getTime()
  if (action) {
    switch (action.type) {
      case ActionTypes.COMMENTS_GET_SUCCESS:
        return Object.assign({}, state, {
          comments: action.data,
          timestamp: now
        })
      case ActionTypes.COMMENTS_POST_SUCCESS:
        return Object.assign({}, state, {})
      case ActionTypes.LOGOUT_SUCCESS:
        return Object.assign({}, state, {
          comments: null,
          timestamp: 0
        })
      default:
        return state
    }
  }
}

export default CommentsReducer
