import React, { Component } from 'react'
import styled from 'styled-components/macro'
import Measure from 'react-measure'
import translate from 'counterpart'
import moment from 'moment'
import { Link as ReactLink } from 'react-router'

import Color from '../../styles/color'
import Variables from '../../styles/variables'
import arrowRight from '../../images/arrow-right.svg'

const Wrapper = styled.div`
  border-bottom: 1px solid ${Color.border};
  background-color: ${(props) => (props.read ? 'transparent' : Color.lightGray)};

  a {
    text-decoration: none;
  }
`

const bpWidth = 500

const InnerWrapper = styled.div`
  padding: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div:first-of-type {
    padding-right: ${Variables.gutter};
    flex: 1;
    display: ${(props) => (props.width < bpWidth ? 'block' : 'flex')};
    max-width: calc(100% - 6rem);

    > strong {
      display: block;
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    > span {
      display: block;
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    > time {
      display: block;
      text-align: ${(props) => (props.width < bpWidth ? 'left' : 'right')};
      padding-left: ${(props) => (props.width < bpWidth ? 0 : Variables.gutter)};
    }
  }
`

const IconWrapper = styled.div`
  width: 2.6rem;
  text-align: center;

  > img {
    width: 2rem;
    height: auto;
    vertical-align: middle;
  }
`

export default class NotificationItem extends Component {
  constructor(props) {
    super(props)

    this.state = {
      dimensions: {
        width: -1,
        height: -1
      }
    }
  }

  buildNotification(notification) {
    let entityIds = notification.entityId?.split('/')

    if (!entityIds || entityIds.length === 0) {
      return null
    }

    switch (`${notification.action}-${notification.entity}`) {
      case 'favorite-house':
        return {
          content: translate('notification.favorite'),
          linkUrl: `/property/${entityIds[0]}`,
          timestamp: notification.createdAt
        }
      case 'match-house/watch':
        return {
          content: translate('notification.houseFound'),
          linkUrl: `/property/${entityIds[0]}`,
          timestamp: notification.createdAt
        }
      case 'match-watch/house':
        return {
          content: translate('notification.watchFound'),
          linkUrl: `/buyer/${entityIds.join('/')}`,
          timestamp: notification.createdAt
        }
      case 'like-image':
        return {
          content: translate('notification.liked'),
          linkUrl: `/property/${entityIds.join('/')}`,
          timestamp: notification.createdAt
        }
      case 'comment-image':
        return {
          content: translate('notification.commented'),
          linkUrl: `/property/${entityIds.join('/')}`,
          timestamp: notification.createdAt
        }
    }
    return null
  }

  onClickNotification = () => {
    var notification = this.props.notification

    if (!notification.read) {
      this.props.markAsRead(notification.id)
    }
  }

  render() {
    const { width } = this.state.dimensions
    const { notification } = this.props
    const contentObj = this.buildNotification(notification)

    if (!contentObj) {
      return null
    }

    return (
      <Measure
        onMeasure={(dimensions) => {
          this.setState({ dimensions })
        }}>
        <Wrapper read={notification.read}>
          <ReactLink
            to={contentObj && contentObj.linkUrl ? contentObj.linkUrl : null}
            onClick={() => {
              this.onClickNotification()
            }}>
            <InnerWrapper width={width}>
              <div>
                {!notification.read ? (
                  <strong>{contentObj.content}</strong>
                ) : (
                  <span>{contentObj.content}</span>
                )}
                <time>{moment(contentObj.timestamp).fromNow()}</time>
              </div>
              <IconWrapper>
                <img src={arrowRight} />
              </IconWrapper>
            </InnerWrapper>
          </ReactLink>
        </Wrapper>
      </Measure>
    )
  }
}
