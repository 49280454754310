import React, { Component } from 'react'
import translate from 'counterpart'

export default class NotFoundPage extends Component {
  render() {
    return (
      <div>
        <h1>{translate('notFound.pageTitle')}</h1>
        <p>{translate('notFound.description')}</p>
      </div>
    )
  }
}
