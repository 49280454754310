import React, { Component } from 'react'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import styled from 'styled-components/macro'
import translate from 'counterpart'

import Button from '../shared/Button'
import TextArea from '../shared/TextArea'
import Spacer from '../shared/Spacer'

import ChatBubble from './ChatBubble'
import Variables from '../../styles/variables'

const ChatThreadWrapper = styled.div`
  padding-top: ${Variables.gutter};
`

class ChatThreadView extends Component {
  constructor(props) {
    super(props)
    this._handleSubmit = this._handleSubmit.bind(this)
  }

  componentDidMount() {
    if (this.props.thread?.user) {
      let receiverUserId = this.props.thread.user.id
      this.props.initialize({ receiverUserId })
      this.props.markAsRead(receiverUserId)
    }
  }

  componentDidUpdate(prevProps) {
    let userId = this.props.thread?.user?.id

    if (userId && userId !== prevProps.thread?.user?.id) {
      this.props.initialize({ receiverUserId: userId })
    }
  }

  _handleSubmit(e) {
    const msg = this.props.message

    if (msg) {
      this.props.handleSubmit(e)
      this.props.change('message', '')
    } else {
      alert(translate('comment.messageNotEmpty'))
      return false
    }
  }

  render() {
    const thread = this.props.thread

    return (
      <ChatThreadWrapper>
        {(thread?.messages || []).map((message) => (
          <ChatBubble key={`message_${message.id}`} message={message} />
        ))}
        <Spacer multiplier={2} />
        <form onSubmit={this._handleSubmit}>
          <Field
            name="message"
            label={translate('comment.message')}
            placeholder={translate('comment.message')}
            component={TextArea}
            type="text"
            className="input"
            required={true}
          />
          <Spacer />
          <Button text={translate('comment.sendMessage')} type="submit" />
        </form>
      </ChatThreadWrapper>
    )
  }
}

const selector = formValueSelector('chatthreadview') // <-- same as form name

ChatThreadView = connect((state) => {
  const message = selector(state, 'message')

  return {
    message
  }
})(ChatThreadView)

export default reduxForm({
  form: 'chatthreadview'
})(ChatThreadView)
