import React from 'react'
import { nl2p } from '../../utils/Text'
import translate from 'counterpart'

import * as UserActions from '../../actions/UserActions'
import * as MessageActions from '../../actions/MessageActions'

import PropertyBlock from '../property/items/PropertyBlock'
import UserBlockItem from '../shared/UserBlockItem'
import Spacer from '../shared/Spacer'

import moment from 'moment'

import CommentToSeller from '../shared/CommentToSeller'

const BuyerView = ({
  watcher,
  me,
  messagesReducer,
  property,
  housetype,
  sendMessage,
  resetMessagesSent
}) => {
  const user = watcher?.user

  if (!user) {
    return null
  }

  const userName = user.name

  const userMemberDescription =
    translate('profile.userSinceStart') +
    ' ' +
    moment(user.createdAt).format('MMMM Y') +
    ' ' +
    translate('profile.userSinceEnd')

  let threads = {}

  if (messagesReducer?.messages && messagesReducer.messages.length !== 0) {
    threads = MessageActions.getThreads(messagesReducer.messages, me)
  }

  let userId = user.id
  let relatedProperty

  if (property?.lastProperty?.id) {
    relatedProperty = (
      <div>
        <h3>{translate('buyer.matchingProperty')}</h3>
        <PropertyBlock property={property.lastProperty} imageSize={2048} houseType={housetype} />
      </div>
    )
  }

  return (
    <div>
      <UserBlockItem
        avatarSrc={UserActions.getAvatarUrl(user)}
        name={userName}
        memberDescription={userMemberDescription}
        description={nl2p(watcher.description)}
      />
      <Spacer />
      {relatedProperty}
      <Spacer multiplier={2} />
      <CommentToSeller
        seller={user}
        me={me}
        onSubmit={sendMessage}
        isBuyer={true}
        messagesReducer={messagesReducer}
        resetMessagesSent={resetMessagesSent}
        thread={threads[userId] || null}
      />
    </div>
  )
}

export default BuyerView
