import React from 'react'
import SuperTokens from 'supertokens-auth-react'
import routes from './routes'
import { Router, browserHistory, RouterContext } from 'react-router'
import { syncHistoryWithStore } from 'react-router-redux'
import { updateRoute } from './actions/RouteActions'
import ReactGA from 'react-ga'
import { useMemo, useCallback } from 'react'

const App = ({ store }) => {
  const history = useMemo(() => syncHistoryWithStore(browserHistory, store), [store])

  const logPageView = useCallback(() => {
    store.dispatch(updateRoute(window.location.pathname))
    ReactGA.set({ page: window.location.pathname + window.location.search })
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [store])

  return (
    <Router
      render={(props) => {
        if (SuperTokens.canHandleRoute()) {
          // This renders the login UI on the /login route
          return SuperTokens.getRoutingComponent()
        }

        return <RouterContext {...props} />
      }}
      routes={routes}
      history={history}
      onUpdate={logPageView}
    />
  )
}

export default App
