import React, { Component } from 'react'
import styled, { keyframes } from 'styled-components/macro'
import Color from '../../styles/color'
import Variables from '../../styles/variables'

const pulse = keyframes`
  0% {
    transform: scale(0.8);
  }

  50% {
    transform: scale(1.6);
  }

  100% {
    transform: scale(0.8);
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;

  .indicator {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 5rem;
    height: 5rem;

    span {
      display: block;
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 50%;
      background-color: ${Color.toggleGrey};
      animation: ${pulse} 2s ${Variables.defaultEasing} infinite;

      &:nth-child(2) {
        animation-delay: 0.33s;
      }

      &:nth-child(3) {
        animation-delay: 0.66s;
      }
    }
  }
`

export default class LoadingIndicator extends Component {
  render() {
    return (
      <Wrapper>
        <div className="indicator">
          <span />
          <span />
          <span />
        </div>
      </Wrapper>
    )
  }
}
