import React from 'react'
import { connect } from 'react-redux'
import translate from 'counterpart'
import * as MessageActions from '../actions/MessageActions'

import ChatFrontpage from '../components/chat/ChatFrontpage'
import ChatThreadView from '../components/chat/ChatThreadView'

import LoggedInContainer from './LoggedInContainer'

const ChatContainer = ({ messages, auth, route, sendMessage, threadId, markAsRead }) => {
  let threads = MessageActions.getThreads(messages.messages, auth.user)

  console.log(route.action)

  switch (route.action) {
    case 'view':
      return (
        <LoggedInContainer
          navigationRoute={route}
          customTitle={translate('pageHeaders.chat')}
          selected="messages"
          hideNavigation={false}
          maxWidth="page">
          <ChatFrontpage key="frontpage" auth={auth} threads={threads} />
        </LoggedInContainer>
      )
    case 'chat':
      return (
        <LoggedInContainer
          navigationRoute={route}
          customTitle={translate('pageHeaders.chat')}
          selected="messages"
          hideNavigation={false}
          maxWidth="page"
          topPadding={false}
          backUrl="/messages">
          <ChatThreadView
            messages={messages}
            auth={auth}
            thread={threads[threadId]}
            onSubmit={sendMessage}
            markAsRead={markAsRead}
          />
        </LoggedInContainer>
      )
  }

  return null
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
    messages: state.messages,
    threadId: ownProps.params.threadId
  }
}

const mapDispatchToProps = (dispatch) => ({
  sendMessage: (saveData, successF, errorF) =>
    dispatch(MessageActions.sendMessage(saveData, successF, errorF)),
  markAsRead: (receiver) => dispatch(MessageActions.markAsRead(receiver))
})

export default connect(mapStateToProps, mapDispatchToProps)(ChatContainer)
