import React, { useState, useCallback, useMemo, useEffect } from 'react'
import styled from 'styled-components/macro'
import Color from '../../styles/color'
import Variables from '../../styles/variables'
import { useDropzone } from 'react-dropzone'
import iconPlus from '../../images/icon-plus.svg'
import iconMinus from '../../images/icon-minus-red.svg'
import { getFileUrlWithStorageId } from '../../utils/getFileUrl'

const ImageFieldWrapper = styled.div`
  display: block;
  border: 1px solid ${Color.border};
  border-radius: ${(p) => (p.round ? '50%' : Variables.borderRadius)};
  background-color: rgba(239, 239, 239, 0.25);
  position: relative;
  cursor: pointer;
  transition: background-color ${Variables.defaultSpeed} ${Variables.defaultEasing};
  width: ${(p) => (p.round ? '11rem' : 'auto')};
  overflow: ${(p) => (p.round ? 'hidden' : 'visible')};

  &:hover {
    background-color: rgba(239, 239, 239, 0.75);
  }
`

const InnerContent = styled.div`
  position: relative;
  padding-bottom: ${(p) => (p.square ? '100%' : '30%')};
  width: 100%;
  height: 100%;
`

const BackgroundImage = styled.img`
  display: block;
  position: absolute;
  object-fit: cover;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
`

const InputLabel = styled.h3`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  max-width: 80%;
  display: flex;
  align-items: center;
`

const LabelIcon = styled.img`
  width: 2rem;
  display: block;
  margin-right: 1rem;

  &:last-child {
    margin-right: 0;
  }
`

const ImageField = (props) => {
  const {
    input,
    uploadImage,
    removeImage,
    onFileRejected,
    className,
    addImageText,
    removeImageText,
    isSquare = false,
    isRound = false,
    children
  } = props

  let [inputImage, setInputImage] = useState(undefined)

  let hasImage = input.value || inputImage

  const removeCurrentImage = useCallback(() => {
    removeImage(input.name)
    setInputImage(undefined)
  }, [removeImage, input.name])

  const onClickFile = useCallback(
    (e) => {
      if (hasImage) {
        e.preventDefault()
        e.stopPropagation()

        removeCurrentImage()
      }
    },
    [input, removeCurrentImage, hasImage]
  )

  const onFileChange = useCallback(
    (files) => {
      let file = files[0]

      if (file) {
        let fileUrl = URL.createObjectURL(files[0])
        setInputImage(fileUrl)
        uploadImage(files, input.name)
      } else {
        setInputImage(undefined)
      }
    },
    [input]
  )

  let manipulateImage = !addImageText ? (
    <LabelIcon src={iconPlus} />
  ) : (
    <>
      <LabelIcon src={iconPlus} /> <span>{addImageText}</span>
    </>
  )

  if (hasImage) {
    manipulateImage = !removeImageText ? (
      <LabelIcon src={iconMinus} />
    ) : (
      <>
        <LabelIcon src={iconMinus} /> <span>{removeImageText}</span>
      </>
    )

    var manipulateImageStyle = { color: Color.red }
  }

  let { getRootProps, getInputProps, open, fileRejections } = useDropzone({
    onDrop: onFileChange,
    maxFiles: 1,
    accept: '.jpg, .jpeg, .png'
  })

  useEffect(() => {
    if (typeof onFileRejected !== 'function') {
      return
    }

    if (fileRejections.length !== 0) {
      onFileRejected({ fileName: fileRejections[0]?.file?.name, errors: fileRejections[0]?.errors })
    } else {
      onFileRejected(undefined)
    }
  }, [fileRejections, onFileRejected])

  let fileSrc = useMemo(() => {
    if (input.value) {
      return getFileUrlWithStorageId(input.value, true)
    }

    return inputImage
  }, [input.value, inputImage])

  useEffect(
    () => () => {
      if (inputImage) {
        URL.revokeObjectURL(inputImage)
      }
    },
    []
  )

  return (
    <>
      <ImageFieldWrapper {...getRootProps({ className })} round={isRound}>
        <input {...getInputProps()} />
        <InnerContent square={isSquare || isRound} onClick={onClickFile}>
          {fileSrc && <BackgroundImage src={fileSrc} />}
          <InputLabel style={manipulateImageStyle}>{manipulateImage}</InputLabel>
        </InnerContent>
      </ImageFieldWrapper>
      {typeof children === 'function'
        ? children({
            name: input.name,
            hasImage,
            selectImage: open,
            removeImage: removeCurrentImage
          })
        : !!children
        ? children
        : null}
    </>
  )
}

export default ImageField
