import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Color from '../../styles/color'
import Variables from '../../styles/variables'

import styled from 'styled-components/macro'
import fbLogo from '../../images/icon-facebook-logo-blue.png'
import googleLogo from '../../images/icon-google-logo.png'

const Wrapper = styled.div`
  margin-bottom: ${Variables.gutterHalf};

  > div {
    position: relative;
    padding: 1.5rem 5.5rem 1.5rem 1.5rem;
    border: 0.1rem solid ${(props) => (props.checked ? Color.green : Color.border)};
    border-radius: ${Variables.borderRadius};
    cursor: pointer;
  }

  img {
    vertical-align: text-bottom;
    width: 1.8rem;
    height: auto;
    margin-right: 1rem;
  }
`

const Toggle = styled.span`
  position: absolute;
  top: 50%;
  right: 1.5rem;
  width: 3.3rem;
  height: 2rem;
  border-radius: 1rem;
  background-color: ${(props) => (props.checked ? Color.green : Color.lightGray)};
  margin-top: -1rem;

  > span {
    position: absolute;
    top: 50%;
    left: ${(props) => (props.checked ? '50%' : '0%')};
    width: 1.8rem;
    height: 1.8rem;
    margin-top: -0.9rem;
    margin-left: 0.1rem;
    background-color: ${Color.white};
    border: 0.1rem solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 0.3rem 0.8rem rgba(0, 0, 0, 0.15);
    border-radius: 50%;
    transition: left ${Variables.defaultSpeed} ${Variables.defaultEasing};
  }
`

export default class ToggleField extends Component {
  static propTypes = {
    label: PropTypes.string,
    checked: PropTypes.bool,
    type: PropTypes.string
  }

  static defaultProps = {
    checked: false
  }

  constructor(props) {
    super(props)

    this.onClick = this.onClick.bind(this)
  }

  onClick(e) {
    if (this.props.onClick) {
      this.props.onClick()
    }
  }

  render() {
    const { label, checked, type } = this.props

    return (
      <Wrapper checked={checked}>
        <div onClick={this.onClick}>
          {type === 'facebook' && <img src={fbLogo} />}
          {type === 'google' && <img src={googleLogo} />}
          {label}
          <Toggle checked={checked}>
            <span></span>
          </Toggle>
        </div>
      </Wrapper>
    )
  }
}
