import React from 'react'
import styled from 'styled-components/macro'
import Color from '../../styles/color'
import Variables from '../../styles/variables'

const Wrapper = styled.div`
  background-color: ${Color.lightGray};
  padding: ${(props) => (props.iconSrc ? Variables.gutterHalf : Variables.gutter)};
  display: flex;
  align-items: ${(props) => (props.alignItems === 'top' ? 'flex-start' : 'center')};
  justify-content: ${(props) => (props.iconSrc ? 'flex-start' : 'center')};
  border-radius: 1rem;
  color: ${(props) => (props.notice ? Color.red : 'inherit')};
  border: 1px solid ${(props) => (props.notice ? Color.red : 'inherit')};

  h3,
  h4,
  p {
    margin: 0;
  }

  > img {
    height: 4rem;
    width: auto;
    margin-right: ${Variables.gutterHalf};
  }
`

const Alert = ({ children, iconSrc, notice = false, alignItems = 'center' }) => {
  return (
    <Wrapper alignItems={alignItems} notice={notice} iconSrc={iconSrc}>
      {iconSrc && <img src={iconSrc} />}
      <div>{children}</div>
    </Wrapper>
  )
}

export default Alert
