import { createApiRequest } from './Api'

function createUploadFormData(files) {
  var formData = new FormData()
  let filesOk = false

  for (let file of files) {
    formData.append('file', file)
    filesOk = true
  }

  if (!filesOk) {
    return
  }

  formData.append('type', 'image')
  return formData
}

export function uploadImage(uploadFiles) {
  let formData = createUploadFormData(uploadFiles)

  if (!formData) {
    return false
  }

  var URL = '/storage'
  var METHOD = 'POST'

  let apiRequest = createApiRequest()
  return apiRequest({ path: URL, method: METHOD, body: formData }).then((json) => json.data)
}

export function deleteUploadedImage(imageId) {
  var URL = '/storage'
  var METHOD = 'DELETE'

  let apiRequest = createApiRequest()
  return apiRequest({
    path: URL,
    method: METHOD,
    body: {
      id: imageId
    }
  }).then((json) => json.data)
}
