import { createGlobalStyle } from 'styled-components/macro'
import Color from './color'
import Variables from './variables'
import { mix } from 'polished'

export const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html {
    background-color: ${Color.white};
    font-family: ${Variables.fontFamily}, 'Helvetica Neue', Arial, sans-serif;
    font-size: ${Variables.rootFontSize};
    color: ${Color.basic};
    line-height: ${Variables.rootLineHeight};
    letter-spacing: ${Variables.rootLetterSpacing};
  }

  body {
    font-size: ${Variables.bodyFontSize};
    margin: 0;
    padding: 0;
  }

  h1 {
    font-size: ${Variables.h1FontSizeMobile};
    line-height: ${Variables.titleLineHeight};

    &.smaller {
      font-size: ${Variables.h1FontSizeMobileSmaller};
    }

    @media (min-width: ${Variables.bpSmall}) {
      font-size: ${Variables.h1FontSize};
      &.smaller {
        font-size: ${Variables.h1FontSizeSmaller};
      }
    }
  }

  h2 {
    font-size: ${Variables.h2FontSizeMobile};
    line-height: ${Variables.titleLineHeight};

    @media (min-width: ${Variables.bpSmall}) {
      font-size: ${Variables.h2FontSize};
    }
  }

  h3 {
    font-size: ${Variables.h3FontSize};
  }

  h4 {
    text-transform: uppercase;
    font-size: ${Variables.smallFontSize};
  }

  a {
    color: ${Color.basic};
    text-decoration: underline;
    transition: color ${Variables.defaultSpeed} ${Variables.defaultEasing};

    &:hover {
      color ${mix(0.7, Color.basic, Color.white)};
    }
  }

  label {
    display: block;
    font-weight: 700;
    font-size: ${Variables.smallFontSize};
    text-transform: uppercase;
    margin-top: ${Variables.gutter};
    margin-bottom: ${Variables.gutterHalf};
  }

  select {
    margin-bottom: ${Variables.gutterHalf};
    appearance: none;
    border: 0.1rem solid ${Color.border};
    border: 0.1rem solid ${Color.border};
    display: block;
    padding: 1.5rem;
    width: 100%;
    font-size: ${Variables.bodyFontSize};
    line-height: ${Variables.bodyLineHeight};
    border-radius: ${Variables.borderRadius};
    background: url(../images/dropdown-caret.png) no-repeat right 1.5rem center;
  }

  hr {
    border: 0.1rem solid ${Color.border};
    margin-top: ${Variables.gutter};
    margin-bottom: ${Variables.gutter};
  }

  .hidden {
    display: none;
  }

  button,
  input {
    font-family: ${Variables.fontFamily};
  }

  .icon-indicator {
    &.addressIsInvalid {
      color: ${Color.red};
    }

    &.addressIsValid {
      color: ${Color.green};
    }
  }

  .cardPaymentArea .cardPaymentArea{
    margin-bottom: 2rem;
  }
  .cardPaymentArea .clearBoth{
    clear:both;
    height:1px;
    overflow:hidden;
  }
  .cardPaymentArea .form-row{
    margin-bottom:2rem;
  }
  .cardPaymentArea label input{
    border:1px solid #f0f;
  }
`
