import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components/macro'
import { reduxForm } from 'redux-form'
import Measure from 'react-measure'
import translate from 'counterpart'

import { connect } from 'react-redux'

import Color from '../../styles/color'
import Variables from '../../styles/variables'
import * as PageActions from '../../actions/PageActions'
import Link from '../shared/Link'
import Alert from '../shared/Alert'

import PropertyBlock from './items/PropertyBlock'
import AreaBlock from './items/AreaBlock'
import * as AreaActions from '../../actions/AreaActions'

import SearchField from '../shared/SearchField'

import infoBuyers from '../../images/info-potential-buyers.svg'
import LookingApartmentBanner from '../shared/LookingApartmentBanner'

let delayTimer = null

const SectionContent = styled.div`
  /*max-width: ${Variables.maxWidthPage};*/
  padding: 6rem 0; /*${Variables.gutter};*/
  margin-left: auto;
  margin-right: auto;

  ${(props) =>
    props.centeredText &&
    css`
      text-align: center;
    `}

  h3 {
    margin: 0;
    font-size: 2rem;
    padding: 4.75rem 0 3.75rem;
  }
`

const bpWidth2 = 900
const bpWidth1 = 555
const Wrapper = styled.div`
  > div {
    max-width: ${Variables.maxWidthPage};
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
  }
  padding: 2rem;
  margin: -2rem;
  box-sizing: border-box;

  h1 {
    text-align: center;
    font-size: 4rem;
  }
  .house--list {
    flex-flow: row;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.75rem 0;
    > h3 {
      width: 100%;
      padding-left: 0.75rem;
      padding-right: 0.75rem;
    }
    > .propertyBlock--block {
      width: ${(props) =>
        props.width > bpWidth2 ? '33.333%' : props.width > bpWidth1 ? '50%' : '100%'};
      padding: 0 0.75rem 0;
      box-sizing: border-box;
      margin: 0 0 5rem;
    }
  }
  .block-list {
    > .title-row {
      width: 100%;
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      padding: 3rem 0.75rem 2rem;

      > h3 {
        padding: 0;
        font-size: 2rem;
        /*width: 100%;*/
      }
      a {
        text-decoration: none;
        color: ${Color.green};
        font-size: 1.3rem;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .area--list {
    flex-flow: row;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.75rem 0;

    > .title-row {
      width: 100%;
      display: flex;
      flex-flow: row;
      flew-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      padding: 3rem 0.75rem 2rem;

      > h3 {
        padding: 0;
        font-size: 2rem;
        /*width: 100%;*/
      }
    }
    > .propertyBlock--block {
      width: ${(props) =>
        props.width > bpWidth2 ? '50%' : props.width > bpWidth1 ? '50%' : '100%'};
      padding: 0 0.75rem 0;
      box-sizing: border-box;
      margin: 0 0rem 1.5rem;

      .content--div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 0.1rem solid ${Color.primary};
        padding: 0.8rem 1rem;
        width: 20rem;
        margin: 0.25rem auto;
        background-color: ${Color.primary};
        border-radius: 3.5rem;

        h3 {
          margin: 0.25rem auto 0.25rem;
          text-align: center;
          font-size: 2.5rem;
          line-height: 1;
        }
        p {
          line-height: 1;
          margin: 0.15rem auto 0.25rem;
          text-align: center;
          font-size: 1.3rem;
        }
      }
    }
  }
  > div {
    > header {
      h1 {
        margin-bottom: 0;
      }

      p {
        margin-top: 0;
        color: ${Color.grey};
      }
    }
  }
`

class ExploreView extends Component {
  static propTypes = {
    error: PropTypes.object
  }

  constructor(props) {
    super(props)

    this.state = { dimensions: { width: 1200, height: 600 } }
    this.createList = this.createList.bind(this)
    this.createListArea = this.createListArea.bind(this)
    this.doSearch = this.doSearch.bind(this)
    this.getGeocodingAddress = this.getGeocodingAddress.bind(this)
    this.autoCompleteChanged = this.autoCompleteChanged.bind(this)
    this.getGeocoding = this.getGeocoding.bind(this)
    this.assignAddressFormElements = this.assignAddressFormElements.bind(this)
    this.geocodeResultArray = this.geocodeResultArray.bind(this)
    this._scrollTop = this._scrollTop.bind(this)

    const google = window?.google

    if (google) {
      this.geocoder = new google.maps.Geocoder()
    }
  }

  componentDidMount(nextProps) {
    let input = document.getElementById('map-address')
    let options = {
      types: ['geocode']
    }

    const google = window?.google

    if (google) {
      this.autocomplete = new google.maps.places.Autocomplete(input, options)
      this.autocomplete.addListener('place_changed', this.autoCompleteChanged)
    } else {
      console.error('Google Maps not loaded for ExploreView!')
    }

    this.updateFeaturedProperties()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.country !== this.props.country || prevProps.city !== this.props.city) {
      this.updateFeaturedProperties()
    }
  }

  updateFeaturedProperties = () => {
    if (typeof this.props.getFeatured === 'function') {
      let country = this.props.country || 'FI'
      let city = this.props.city || null

      this.props.getFeatured(country, city)
    }
  }

  geocodeResultArray(resultArray) {
    if ((resultArray || [])[0]) {
      let result = resultArray[0]

      if (this.props && this.props.changeFormValue) {
        this.props.changeFormValue('address', result.formatted_address)
      }

      this.assignAddressFormElements(result)
    }
  }

  assignAddressFormElements(result) {
    this.setState({ result: result })

    if (result && result.address_components && result.address_components) {
      let city =
        AreaActions.getGoogleJsonType(result.address_components, 'administrative_area_level_3') ||
        AreaActions.getGoogleJsonType(result.address_components, 'locality')

      let country =
        AreaActions.getGoogleJsonType(result.address_components, 'country', true) || 'FI'

      if (city && country) {
        this.props.change('map-address', city + ', ' + country)
        document.getElementById('map-address').value = city + ', ' + country

        PageActions.changePage('/explore/' + country + '/' + city)
      }
    }
  }
  autoCompleteChanged() {
    let place = this.autocomplete?.getPlace()

    if (!place.geometry) {
      return
    }

    this.assignAddressFormElements(place)
  }

  doSearch() {
    clearTimeout(delayTimer)
    delayTimer = setTimeout(this.getGeocodingAddress, 1000)
  }

  getGeocodingAddress() {
    if (document.getElementById('map-address')) {
      let address = document.getElementById('map-address').value
      this.getGeocoding(address)
    }
  }

  getGeocoding(address) {
    if (address) {
      this.geocoder?.geocode({ address: address }, this.geocodeResultArray)
    }
  }

  createList(listName, count) {
    const returnJSX = []
    const props = this.props

    const _country = props.country || 'FI'
    const _city = props.city || '-'

    if (props.property?.featured[listName]) {
      const list = props.property.featured[listName]

      if (list.length !== 0) {
        let ii = 0

        for (let item of list) {
          returnJSX.push(
            <PropertyBlock
              key={`${listName}_${ii}`}
              property={item}
              houseType={this.props.housetype}
              urlPrefix={`/explore/${_country}/${_city}`}
            />
          )

          ii++

          if (count && ii >= count) {
            break
          }
        }
      } else {
        returnJSX.push(
          <Alert key="nohits" iconSrc={infoBuyers}>
            <p>{translate('explore.searchNoHits')}&nbsp;</p>
          </Alert>
        )
      }
    }
    return returnJSX
  }

  createListArea(listName, count) {
    let props = this.props
    const returnJSX = []

    if (props.property?.featured[listName]) {
      const list = props.property.featured[listName]
      let ii = 0

      for (let item of list) {
        if (item && item.city && item.numberOfPublicHomes) {
          returnJSX.push(
            <AreaBlock
              key={`${listName}_${ii}`}
              title={item.city}
              subtitle={
                <>
                  <span style={{ fontWeight: 'bold' }}>
                    {translate('explore.numOfPublicHouses', {
                      count: item.numberOfPublicHomes || 0
                    })}
                  </span>
                  {!!item.numberOfPrivateHomes && (
                    <>
                      <br />
                      <span
                        style={{
                          fontSize: '1.15rem',
                          marginTop: '0.25rem',
                          display: 'inline-block'
                        }}>
                        (
                        {translate('explore.numOfPrivateHouses', {
                          count: item.numberOfPrivateHomes || 0
                        })}
                        )
                      </span>
                    </>
                  )}
                </>
              }
              urlPrefix={'explore'}
              item={item}
              housetype={this.props.housetype}
              image={item.thumbnail}
            />
          )

          ii++

          if (count && ii >= count) {
            break
          }
        }
      }
    }

    return returnJSX
  }

  _scrollTop() {
    window.scrollTo(0, 0)
  }

  render() {
    const country = this.props.country || 'FI'
    const city = this.props.city || '-'
    let cityTitle = null

    const cityUrl = `/explore/${country}/${city}`
    const countryUrl = `/explore/${country}/-`

    const command = this.props.command || null
    const numberOfHomesVisible = 3

    if (city !== '-') {
      cityTitle = city
    }

    return (
      <Measure
        onMeasure={(dimensions) => {
          this.setState({ dimensions })
        }}>
        <div>
          <Wrapper width={this.state.dimensions.width}>
            <div>
              <div className="search-area">
                {this.props.city && <h1>{cityTitle}</h1>}

                <SearchField
                  id="map-address"
                  name="map-address"
                  showLabel={false}
                  label={translate('common.moveMapAddress')}
                  placeholder={translate('explore.search')}
                  component={SearchField}
                  type="text"
                  onBlur={this.doSearch}
                  onChange={this.doSearch}
                />
              </div>
              {!command && (
                <>
                  <div className="exploreHouses--most-liked house--list block-list">
                    <div className="title-row">
                      <h3>{translate('explore.mostLikedHomes')}</h3>
                      <Link to={`${cityUrl}/liked`} onClick={this._scrollTop}>
                        {translate('explore.showMore')}
                      </Link>
                    </div>
                    {this.createList('liked', numberOfHomesVisible)}
                  </div>
                  <div className="exploreHouses--most-commented house--list block-list">
                    <div className="title-row">
                      <h3>{translate('explore.mostCommentedHomes')}</h3>
                      <Link to={`${cityUrl}/commented`} onClick={this._scrollTop}>
                        {translate('explore.showMore')}
                      </Link>
                    </div>
                    {this.createList('commented', numberOfHomesVisible)}
                  </div>
                  <div key="area" className="exploreAreas--most-houses area--list block-list">
                    <div className="title-row">
                      <h3>{translate('explore.hottestAreas')}</h3>
                      <Link to={`${countryUrl}/hottest-areas`} onClick={this._scrollTop}>
                        {translate('explore.showMore')}
                      </Link>
                    </div>
                    {this.createListArea('houses', 2)}
                  </div>
                  <SectionContent key="looking">
                    <LookingApartmentBanner showBackground={true} />
                  </SectionContent>
                </>
              )}

              {command === 'liked' && (
                <div className="exploreHouses--most-liked house--list block-list">
                  <div className="title-row">
                    <h3>{translate('explore.mostLikedHomes')}</h3>
                    <Link to={`${cityUrl}`} onClick={this._scrollTop}>
                      {translate('explore.showLess')}
                    </Link>
                  </div>
                  {this.createList('liked', 90)}
                </div>
              )}

              {command === 'commented' && (
                <div className="exploreHouses--most-commented house--list block-list">
                  <div className="title-row">
                    <h3>{translate('explore.mostCommentedHomes')}</h3>
                    <Link to={`${cityUrl}`} onClick={this._scrollTop}>
                      {translate('explore.showLess')}
                    </Link>
                  </div>
                  {this.createList('commented', 90)}
                </div>
              )}

              {command === 'hottest-areas' && (
                <div className="exploreAreas--most-houses area--list block-list">
                  <div className="title-row">
                    <h3>{translate('explore.hottestAreas')}</h3>
                    <Link to={`${countryUrl}`} onClick={this._scrollTop}>
                      {translate('explore.showLess')}
                    </Link>
                  </div>
                  {this.createListArea('houses', 100)}
                </div>
              )}
            </div>
          </Wrapper>
          <br />
        </div>
      </Measure>
    )
  }
}

export default connect(() => {
  return {}
})(
  reduxForm({
    form: 'exploreform'
  })(ExploreView)
)
