import React, { useCallback, useState } from 'react'
import styled from 'styled-components/macro'
import translate from 'counterpart'
import Link from './Link'
import { Link as ReactLink } from 'react-router'
import NotificationIndicator from './NotificationIndicator'

import * as MessageActions from '../../actions/MessageActions'

import Color from '../../styles/color'
import Variables from '../../styles/variables'
import messageLetterBlack from '../../images/message-letter-black.svg'
import iconGlobe from '../../images/icon-globe.svg'
import iconBurger from '../../images/burger.svg'
import iconClose from '../../images/close-button.svg'

const NavigationWrapper = styled.nav`
  position: fixed;
  z-index: 999;
  padding: 5rem;
  margin: 0;
  background-color: ${Color.white};
  top: 0;
  right: 0;
  bottom: 0;
  max-width: 100%;
  min-width: 35rem;
  border-left: 0.1rem solid ${Color.border};

  font-size: ${Variables.navMobileFontSize};
  transform: ${(props) => (props.menuOpen ? 'translateX(0%)' : 'translateX(100%)')};
  transition: transform ${Variables.defaultSpeed} ${Variables.defaultEasing};

  @media (min-width: ${Variables.bpMobile}) {
    padding: 0 ${Variables.gutter} 0 0;
    position: static;
    background-color: transparent;
    border-left: 0 none;
    font-size: ${Variables.navFontSize};
    min-width: 0;
    transform: translateX(0);
  }

  > ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (min-width: ${Variables.bpMobile}) {
      flex-direction: row;
      align-items: flex-end;
    }

    > li {
      padding-left: ${Variables.gutterHalf};
      padding-right: ${Variables.gutterHalf};
      margin-bottom: 2rem;

      @media (min-width: ${Variables.bpMobile}) {
        margin-bottom: 0;
      }
    }
  }
`

const MobiQuickNav = styled.ul`
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  list-style: none;

  @media (min-width: ${Variables.bpMobile}) {
    display: none;
  }

  li {
    margin-right: 1.5rem;

    &:last-of-type {
      img {
        width: 2rem;
      }
    }
  }

  a {
    display: flex;
    min-width: 3.5rem;
    height: 3rem;
    text-decoration: none;
    position: relative;
    justify-content: center;
    align-items: center;
  }

  img {
    display: block;
    width: 2.2rem;
  }
`

const MenuBurgerWrapper = styled.div`
  display: block;
  width: 4rem;
  height: 4rem;
  cursor: pointer;
  position: relative;
  z-index: 1001;

  @media (min-width: ${Variables.bpMobile}) {
    display: none;
  }

  > img {
    width: 2.2rem;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity ${Variables.defaultSpeed} ${Variables.defaultEasing};
  }

  > img:first-of-type {
    opacity: ${(props) => (props.menuOpen ? 0 : 1)};
  }

  > img:last-of-type {
    opacity: ${(props) => (props.menuOpen ? 1 : 0)};
  }
`

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const Navigation = ({ user, selected, messages, notifications, navigationRoute }) => {
  let [isOpen, setIsOpen] = useState(false)

  const toggleMenu = useCallback((e) => {
    setIsOpen((current) => !current)
  }, [])

  const unreadMessages = MessageActions.getUnreadMessagesCount(messages.messages, user)

  let selectedPage = selected
  let notificationsTotal = 0

  if ((notifications?.notifications || []).length !== 0) {
    notificationsTotal += notifications.notifications.filter((n) => !n.read).length
  }

  const onClickLink = useCallback((e) => {
    setIsOpen(false)
  }, [])

  if (!user) {
    return null
  }

  if (navigationRoute) {
    if (selectedPage === 'property') {
      if (
        navigationRoute.action === 'searchwatcher' ||
        navigationRoute.action === 'searchwatcher-add' ||
        navigationRoute.action === 'searchwatcher-edit'
      ) {
        selectedPage = 'watcher'
      }
      if (navigationRoute.action === 'favourites') {
        selectedPage = 'favourites'
      }
      if (navigationRoute.action === 'messages') {
        selectedPage = 'messages'
      }
    }
  }

  return (
    <Wrapper>
      <MobiQuickNav>
        <li>
          <ReactLink onClick={onClickLink} to={'/messages'}>
            <img src={messageLetterBlack} />
            <NotificationIndicator size="tiny" count={unreadMessages} />
          </ReactLink>
        </li>
        <li>
          <ReactLink onClick={onClickLink} to={'/notifications'}>
            <img src={iconGlobe} />
            <NotificationIndicator size="tiny" count={notificationsTotal} />
          </ReactLink>
        </li>
      </MobiQuickNav>
      <NavigationWrapper menuOpen={isOpen}>
        <ul>
          <li>
            <Link
              selected={selectedPage === 'property'}
              type="menu"
              onClick={onClickLink}
              to="/property">
              {translate('shared.navigation.property')}
            </Link>
          </li>
          <li>
            <Link
              selected={selectedPage === 'watcher'}
              type="menu"
              onClick={onClickLink}
              to="/find">
              {translate('shared.navigation.searchwatcher')}
            </Link>
          </li>
          <li>
            <Link
              selected={selectedPage === 'explore'}
              type="menu"
              onClick={onClickLink}
              to="/explore">
              {translate('shared.navigation.explore')}
            </Link>
          </li>
          <li>
            <Link
              selected={selectedPage === 'favourites'}
              type="menu"
              onClick={onClickLink}
              to="/favourites">
              {translate('shared.navigation.favourites')}
            </Link>
          </li>
          <li>
            <Link
              selected={selectedPage === 'messages'}
              type="menu"
              onClick={onClickLink}
              to="/messages">
              {translate('shared.navigation.messages')}
              <NotificationIndicator count={unreadMessages} />
            </Link>
          </li>
          <li>
            <Link
              selected={selectedPage === 'notifications'}
              type="menu"
              onClick={onClickLink}
              to="/notifications">
              {translate('shared.navigation.notifications')}
              <NotificationIndicator count={notificationsTotal} />
            </Link>
          </li>
          <li>
            <Link
              selected={selectedPage === 'profile'}
              type="menu"
              onClick={onClickLink}
              to="/profile">
              {translate('shared.navigation.profile')}
            </Link>
          </li>
        </ul>
      </NavigationWrapper>
      <MenuBurgerWrapper onClick={toggleMenu} menuOpen={isOpen}>
        <img src={iconBurger} />
        <img src={iconClose} />
      </MenuBurgerWrapper>
    </Wrapper>
  )
}

export default Navigation
