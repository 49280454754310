import { browserHistory } from 'react-router'
import { routerMiddleware, push } from 'react-router-redux'

import { createStore, applyMiddleware } from 'redux'
import reducer from '../reducers'

/**
 *
 */
// Apply the middleware to the store
const routingMiddleware = routerMiddleware(browserHistory)
const store = createStore(reducer, applyMiddleware(routingMiddleware))

export function changePage(url, reload) {
  if (!reload) {
    setTimeout(function () {
      // using setTimeout to not conflict with dispatchers
      store.dispatch(push(url))
    }, 0)
  } else {
    window.location = url
  }
}

export function goBackF(e) {
  e.preventDefault()
  setTimeout(function () {
    // using setTimeout to not conflict with dispatchers
    browserHistory.goBack()
  }, 0)
}
