import React, { Component, useMemo, useState, useCallback, useEffect, useRef } from 'react'

import translate from 'counterpart'
import styled from 'styled-components/macro'

import Color from '../../styles/color'
import SearchField from '../shared/SearchField'
import { GoogleMap } from './GoogleMap'
import { Circle } from '@react-google-maps/api'
import { compact, uniqBy } from 'lodash'
import { MAX_CIRCLE_RADIUS } from '../../constants'

const Wrapper = styled.div`
  height: 100%;
`

const MapWrapper = styled.div`
  height: calc(100% - 5rem);
`

const WatchSelectGoogleMap = ({ circles, addCircle, removeCircle, onMapLoad }) => {
  let [pendingCircle, setPendingCircle] = useState()
  let [map, setMap] = useState()

  useEffect(() => {
    if (map) {
      /*const center = new window.google.maps.LatLng(DEFAULT_CENTER)
      map.setCenter(center)*/
      onMapLoad(map)
    }
  }, [map, onMapLoad])

  let onCircleClick = useCallback(
    (circle) => (evt) => {
      evt.domEvent.preventDefault()
      removeCircle(circle)
    },
    []
  )

  let markers = useMemo(
    () =>
      uniqBy(compact([...circles, pendingCircle]), (circle) => `${circle.lat}_${circle.lng}`).map(
        (circle) => {
          return (
            <Circle
              options={{
                draggable: false,
                editable: false,
                clickable: !pendingCircle,
                strokeColor: Color.green,
                strokeOpacity: 0.95,
                strokeWeight: 1.5,
                fillColor: Color.green,
                fillOpacity: 0.25
              }}
              onClick={!pendingCircle ? onCircleClick(circle) : undefined}
              key={`${circle.lat}_${circle.lng}`}
              center={{ lat: circle.lat, lng: circle.lng }}
              radius={circle.radius}
            />
          )
        }
      ),
    [pendingCircle, circles]
  )

  let circleInitTimeout = useRef(0)

  let onCircleInit = useCallback((evt) => {
    let touchPoints = Math.max(
      evt.domEvent.changedTouches?.length || 0,
      evt.domEvent.touches?.length || 0,
      1
    )

    if (touchPoints > 1) {
      clearTimeout(circleInitTimeout.current)
      return
    }

    circleInitTimeout.current = setTimeout(() => {
      setPendingCircle({ lat: evt.latLng.lat(), lng: evt.latLng.lng(), radius: 3000 })
    }, 600)
  }, [])

  let onCircleCommit = useCallback(() => {
    clearTimeout(circleInitTimeout.current)

    if (pendingCircle) {
      addCircle(pendingCircle)
      setPendingCircle(undefined)
    }
  }, [pendingCircle])

  let onCircleSize = useCallback(
    (evt) => {
      clearTimeout(circleInitTimeout.current)

      if (pendingCircle) {
        let mouseLatLng = evt.latLng

        setPendingCircle((current) => {
          if (!current || !window?.google) {
            return
          }

          let circleLatLng = new window.google.maps.LatLng(current.lat, current.lng)
          let mouseDistance = window.google.maps.geometry.spherical.computeDistanceBetween(
            circleLatLng,
            mouseLatLng
          )

          let nextCircle = { ...current }
          nextCircle.radius = Math.max(100, Math.min(MAX_CIRCLE_RADIUS, Math.round(mouseDistance)))

          return nextCircle
        })
      }
    },
    [pendingCircle]
  )

  let onDrag = useCallback(() => {
    clearTimeout(circleInitTimeout.current)
    setPendingCircle(undefined)
  }, [])

  return (
    <GoogleMap
      options={{
        gestureHandling: pendingCircle ? 'none' : 'greedy'
      }}
      zoom={13}
      onLoad={setMap}
      onDrag={onDrag}
      onMouseMove={onCircleSize}
      onMouseUp={onCircleCommit}
      onMouseDown={onCircleInit}>
      {markers}
    </GoogleMap>
  )
}

var delayTimer

export default class GoogleMapAreas extends Component {
  constructor(props) {
    super(props)

    this.state = {
      position: { lat: 65, lng: 21 },
      dialogOpen: false
    }

    this.autoCompleteChanged = this.autoCompleteChanged.bind(this)
    this.doSearch = this.doSearch.bind(this)
    this.getGeocodingAddress = this.getGeocodingAddress.bind(this)
    this.assignAddressFormElements = this.assignAddressFormElements.bind(this)
    this.moveMapToPosition = this.moveMapToPosition.bind(this)
    this._handleMapLoad = this._handleMapLoad.bind(this)

    const google = window?.google

    if (google) {
      this.geocoder = new google.maps.Geocoder()
    }
  }

  componentDidMount() {
    const google = window?.google

    if (google) {
      let input = document.getElementById('map-address')
      this.autocomplete = new google.maps.places.Autocomplete(input)
      this.autocomplete.addListener('place_changed', this.autoCompleteChanged)
    }
  }

  autoCompleteChanged() {
    let place = this.autocomplete.getPlace()

    if (!place.geometry) {
      return
    }

    this.assignAddressFormElements(place)
  }

  doSearch() {
    clearTimeout(delayTimer)
    delayTimer = setTimeout(this.getGeocodingAddress, 1000)
  }

  getGeocodingAddress() {
    let address = document.getElementById('map-address').value

    if (this.geocoder) {
      this.geocoder.geocode(address)
    }
  }

  assignAddressFormElements(result) {
    this.moveMapToPosition(result.geometry)
  }

  moveMapToPosition(geo) {
    if (this._mapComponent) {
      let position = geo.location
      this._mapComponent.panTo(position)
      this._mapComponent.fitBounds(geo.viewport)
    }
  }

  _handleMapLoad(map) {
    this._mapComponent = map
  }

  render() {
    return (
      <Wrapper>
        <SearchField
          id="map-address"
          name="map-address"
          showLabel={false}
          label={translate('common.moveMapAddress')}
          placeholder={translate('common.moveMapAddress')}
          component={SearchField}
          type="text"
          onChange={this.doSearch}
        />
        <MapWrapper>
          <WatchSelectGoogleMap
            addCircle={this.props.addCircle}
            removeCircle={this.props.removeCircle}
            onMapLoad={this._handleMapLoad}
            circles={this.props.circles}
          />
        </MapWrapper>
      </Wrapper>
    )
  }
}
