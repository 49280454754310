import React, { Component } from 'react'
import { connect } from 'react-redux'

import NotificationView from '../components/notifications/NotificationView'

import LoggedInContainer from './LoggedInContainer'
import { markAsRead, markAllAsRead } from '../actions/NotificationActions'

class NotificationContainer extends Component {
  getElementByAction(props) {
    const { route, user, notifications, markAsRead, markAllAsRead } = props
    if (!user) {
      return null
    }
    switch (route.action) {
      case 'view':
        return (
          <NotificationView
            notifications={notifications}
            user={user}
            markAsRead={markAsRead}
            markAllAsRead={markAllAsRead}
          />
        )
    }
    return null
  }

  render() {
    var obj = this.getElementByAction(this.props)

    return (
      <LoggedInContainer
        selected="notifications"
        navigationRoute={this.props.route}
        maxWidth="page">
        {obj}
      </LoggedInContainer>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    notifications: state.notifications,
    user: state.user,
    property: state.property,
    sellerId: ownProps.params.sellerId,
    propertyId: ownProps.params.propertyId
  }
}

const mapDispatchToProps = (dispatch) => ({
  markAsRead: (id) => dispatch(markAsRead(id)),
  markAllAsRead: () => dispatch(markAllAsRead())
})

export default connect(mapStateToProps, mapDispatchToProps)(NotificationContainer)
