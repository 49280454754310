import React from 'react'

var App = ({ children }) => {
  return (
    <div className="App">
      <div>{children}</div>
    </div>
  )
}

export default App
