import React, { Component } from 'react'

import Color from '../../styles/color'
import Variables from '../../styles/variables'

import styled from 'styled-components/macro'

const Wrapper = styled.div`
  > label {
    background-color: ${Color.white};
    border-radius: ${Variables.borderRadius};
    position: relative;
    margin: 0;
    cursor: pointer;

    > input {
      display: none;

      + div {
        border: 0.2rem solid ${Color.border};
        border-radius: ${Variables.borderRadius};
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: border-color ${Variables.defaultSpeed} ${Variables.defaultEasing};
      }

      &:checked {
        + div {
          border-color: ${Color.green};
        }
      }
    }

    > img {
      max-width: 100%;
      height: auto;
      display: block;
    }

    > span {
      position: absolute;
      left: 0.2rem;
      right: 0.2rem;
      bottom: 5%;
      font-weight: 700;
      text-align: center;
      color: ${Color.blackish};
      padding: 0.5rem;
      border-radius: ${Variables.borderRadius};
    }
  }
`

export default class RadioIconField extends Component {
  render() {
    const { meta, src, style, id, label, checked, ...other } = this.props

    const hasError = meta && meta.touched && meta.error ? true : false

    return (
      <Wrapper hasError={hasError} style={style}>
        <label>
          <input type="radio" id={id} {...other} {...checked} />
          <div></div>
          {src && <img alt="" src={src} />}
          {label && <span>{label}</span>}
        </label>
      </Wrapper>
    )
  }
}
