import React, { useState, useCallback, useMemo } from 'react'
import styled from 'styled-components/macro'
import colors from '../../styles/color'
import Button from '../shared/Button'
import { createApiRequest } from '../../utils/Api'
import translate from 'counterpart'

const professionalsImg = require('../../images/ammattilaiset.png')
const handshakeImg = require('../../images/handshake.png')
const moneyImg = require('../../images/money.png')
const floorPlanImg = require('../../images/floor-plan.png')
const cameraImg = require('../../images/camera.png')
const checkListImg = require('../../images/checklist.png')

const LeadGeneratorWrapper = styled.div`
  text-align: center;
  width: 100%;
  margin-top: 1.5rem;
  position: relative;
  min-height: 70rem;
  overflow: hidden;
`

const Title = styled.h2`
  margin: ${(p) => (p.large ? '5rem 0 3rem' : '3rem 0')};
  font-size: ${(p) => (p.large ? '3rem' : '2rem')};
  font-weight: bold;
`

const Stage = styled.div`
  background: ${(p) =>
    p.showBackground === false ? 'none' : `url("${professionalsImg}") center bottom no-repeat`};
  background-size: 80%;
  border: 1px solid ${colors.boxBorder};
  pointer-events: ${(p) => (p.isActive ? 'all' : 'none')};
  opacity: ${(p) => (p.isActive ? (p.isLoading ? 0.5 : 1) : 0)};
  transform: ${(p) =>
    p.isActive ? 'translate(0, 0)' : p.next ? 'translate(50%, 0)' : 'translate(-50%, 0)'};
  transition: all ${(p) => (p.isActive ? '0.3s' : '0.3s')} ease-out;
  padding-bottom: 1rem;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  min-height: 38rem;
`

const ActionButton = styled(Button)``

const OptionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;

  @media screen and (min-width: 560px) {
    padding: 0 2rem;
  }
`

const OptionsHelp = styled.p`
  margin-bottom: 1.5rem;
  padding: 0 1rem;
  color: ${(p) => (p.isError ? colors.red : colors.infoText)};
`

const LeadOptionItem = styled.div`
  margin: 0;
  width: calc(50% - 0.5rem);

  @media screen and (min-width: 850px) {
    width: calc(25% - 1rem);
  }
`

const LeadOption = styled.label`
  width: 100%;
  height: 17rem;
  border: 1px solid ${colors.boxBorder};
  background: url('${(p) => p.backgroundSrc}') center 2rem no-repeat;
  background-size: 75%;
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  position: relative;
  margin: 0 0 1rem;

  @media screen and (min-width: 400px) {
    height: 19rem;
    background-size: 50%;
  }

  @media screen and (min-width: 600px) {
    background-size: 50%;
    background-position: center top;
  }

  @media screen and (min-width: 769px) {
    background-size: 60%;
    background-position: center 2rem;
  }

  @media screen and (min-width: 850px) {
    background-size: 75%;
    margin-bottom: 0;
    background-position: center 1.5rem;
    height: 17rem;
  }

  @media screen and (min-width: 1000px) {
    height: 19rem;
    background-size: 70%;
    background-position: center 1rem;
  }
`

const LeadOptionLabel = styled.h4`
  font-weight: 700;
  text-align: center;
  // color: rgb(80, 87, 106);
  padding: 0.5rem 1rem;
  margin: auto 0 0.5rem;
  user-select: none;
  color: inherit;
`

const LeadOptionInput = styled.input`
  opacity: 0;
  position: absolute;
  left: -9999px;

  &:checked + label {
    border-color: ${colors.green};

    &:before {
      content: '';
      display: block;
      background: url('${checkListImg}') center center no-repeat;
      background-size: 6rem;
      position: absolute;
      top: 1rem;
      left: 50%;
      margin-left: -50%;
      width: 100%;
      height: 60%;

      @media screen and (min-width: 400px) {
        top: 2rem;
        background-size: 8rem;
      }

      @media screen and (min-width: 850px) {
        top: 0.5rem;
        background-size: 6rem;
      }

      @media screen and (min-width: 1000px) {
        top: 1rem;
      }
    }
  }
`

const LeadGenerator = ({ propertyId }) => {
  // Translations don't work outside components FOR SOME REASON
  let options = useMemo(
    () => [
      { name: 'kaupanteko', img: handshakeImg, label: translate('leads.optionBarter') },
      { name: 'pohja', img: floorPlanImg, label: translate('leads.optionFloorPlan') },
      {
        name: 'hintaarvio',
        img: moneyImg,
        label: translate('leads.optionPricing')
      },
      { name: 'valokuvat', img: cameraImg, label: translate('leads.optionPhotos') }
    ],
    []
  )

  let defaultFormState = useMemo(
    () =>
      options.reduce((formOpts, { name }) => {
        formOpts[name] = false
        return formOpts
      }, {}),
    []
  )

  let [stage, setStage] = useState(1)
  let [formState, setFormState] = useState({ ...defaultFormState, propertyId })
  let [formError, setError] = useState('')
  let [formLoading, setLoading] = useState(false)

  let nextStage = useCallback(() => {
    setError('')
    setStage((current) => (current === 3 ? 3 : current + 1))
  }, [])

  let onSubmitForm = useCallback(
    (e) => {
      e.preventDefault()
      setLoading(true)

      function handleError() {
        setError(translate('leads.formError'))
      }

      let apiRequest = createApiRequest()

      apiRequest({ path: '/lead', method: 'POST', json: formState })
        .then((data) => {
          if (!data?.success) {
            throw new Error(data?.error?.message || '')
          } else {
            setError('')
            nextStage()
          }
        })
        .catch(handleError)
        .finally(() => {
          setLoading(false)
        })
    },
    [formState]
  )

  return (
    <LeadGeneratorWrapper>
      <form action="#" onSubmit={onSubmitForm}>
        <Stage isActive={stage === 1} next={stage === 3}>
          <Title>{translate('leads.needProfessionalHelp')}</Title>
          <ActionButton onClick={nextStage}>{translate('leads.clickHere')}</ActionButton>
        </Stage>
        <Stage
          isActive={stage === 2}
          showBackground={false}
          next={stage === 1}
          isLoading={formLoading}>
          <Title>{translate('leads.tellUsWhatYouNeed')}</Title>
          <OptionsWrapper>
            {options.map((option) => {
              let { name, img, label } = option
              let optionIsSelected = formState[name] === true

              return (
                <LeadOptionItem key={name}>
                  <LeadOptionInput
                    onChange={() =>
                      setFormState((currentState) => {
                        let nextState = { ...currentState }
                        nextState[name] = !currentState[name]
                        return nextState
                      })
                    }
                    checked={optionIsSelected}
                    type="checkbox"
                    id={name}
                    name={name}
                    value={name}
                  />
                  <LeadOption backgroundSrc={img} htmlFor={name}>
                    <LeadOptionLabel dangerouslySetInnerHTML={{ __html: label }} />
                  </LeadOption>
                </LeadOptionItem>
              )
            })}
          </OptionsWrapper>
          <OptionsHelp isError={!!formError}>
            {formError ||
              (formLoading ? translate('leads.loading') : translate('leads.chooseOptions'))}
          </OptionsHelp>
          <ActionButton disabled={formLoading} type="submit">
            {translate('leads.send')}
          </ActionButton>
        </Stage>
        <Stage isActive={stage === 3} next={stage === 2}>
          <Title large>{translate('leads.thankYou')}</Title>
        </Stage>
      </form>
    </LeadGeneratorWrapper>
  )
}

export default LeadGenerator
