import React from 'react'
import styled from 'styled-components/macro'

import Link from './Link'

import Color from '../../styles/color'
import Variables from '../../styles/variables'
import arrowBack from '../../images/arrow-back.svg'

const Wrapper = styled.div`
  left: 0;
  width: 100%;
  background-color: ${Color.white};
  height: ${Variables.backHeaderHeight};
  border-bottom: 1px solid ${Color.border};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 90;
  margin-bottom: 2rem;

  > div {
    position: relative;
    max-width: ${Variables.maxWidthPage};
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
`
const BackLink = styled(Link)`
  position: absolute;
  left: ${Variables.gutter};
  text-decoration: none;

  img {
    width: 2rem;
    height: auto;
    vertical-align: middle;
  }

  > span > span {
    margin-left: 1rem;
  }
`
const Title = styled.h3`
  margin: 0;
`

const BackHeader = (props) => {
  return (
    <Wrapper>
      <div>
        <BackLink to={props.backUrl} onClick={props.goBack}>
          <img src={arrowBack} />
          {props.backLinkText && <span>{props.backLinkText}</span>}
        </BackLink>
        <Title>{props.customTitle}</Title>
      </div>
    </Wrapper>
  )
}

export default BackHeader
