import * as ActionTypes from '../constants/ActionTypes'

export function changeLanguageTo(lang) {
  return {
    type: ActionTypes.CHANGE_LANGUAGE,
    lang: lang
  }
}

export function changeLanguage(lang) {
  return (dispatch, getState) => {
    dispatch(changeLanguageTo(lang))
  }
}
