import React, { Component } from 'react'
import { connect } from 'react-redux'
import BuyerView from '../components/buyer/BuyerView'
import * as HouseActions from '../actions/HouseActions'
import * as SearchWatcherActions from '../actions/SearchWatcherActions'
import * as MessageActions from '../actions/MessageActions'

import LoggedInContainer from './LoggedInContainer'
import translate from 'counterpart'

class BuyerContainer extends Component {
  updateData = (prevProps = {}) => {
    if (this.props.watchId && prevProps.watchId !== this.props.watchId) {
      this.props.getWatchById(this.props.watchId)
    }

    if (!this.props.watchId) {
      this.props.unloadWatcher()
    }

    if (this.props.propertyId && prevProps.propertyId !== this.props.propertyId) {
      this.props.getPropertyById(this.props.propertyId)
    }

    if (!this.props.propertyId) {
      this.props.unloadProperty()
    }
  }

  componentDidMount() {
    this.updateData()
  }

  componentDidUpdate(prevProps) {
    this.updateData(prevProps)
  }

  render() {
    const { auth, route, property, watcher, sendMessage, housetype, messages, resetMessagesSent } =
      this.props

    let side = route.path.split('/')[0]

    return (
      <LoggedInContainer
        navigationRoute={route}
        selected={side}
        hideNavigation={false}
        backUrl="/property"
        backLinkText={translate('common.back')}
        maxWidth="col">
        <BuyerView
          watcher={watcher.lastWatcher}
          me={auth.user}
          property={property}
          housetype={housetype}
          onSubmit={sendMessage}
          sendMessage={sendMessage}
          messagesReducer={messages}
          resetMessagesSent={resetMessagesSent}
        />
      </LoggedInContainer>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    auth: state.auth,
    property: state.property,
    housetype: state.housetype,
    watcher: state.watcher,
    watchId: ownProps.params.watchId,
    propertyId: ownProps.params.propertyId,
    messages: state.messages
  }
}

const mapDispatchToProps = (dispatch) => ({
  getWatchById: (id) => dispatch(SearchWatcherActions.getById(id)),
  unloadWatcher: () => dispatch(SearchWatcherActions.unload()),
  getPropertyById: (id) => dispatch(HouseActions.getPropertyById(id)),
  unloadProperty: () => dispatch(HouseActions.unloadProperty()),
  sendMessage: (data, successF, errorF) =>
    dispatch(MessageActions.sendMessage(data, successF, errorF)),
  resetMessagesSent: () => dispatch(MessageActions.clearMessageSent())
})

export default connect(mapStateToProps, mapDispatchToProps)(BuyerContainer)
