import React, { Component } from 'react'

import { Field, reduxForm, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import translate from 'counterpart'
import styled from 'styled-components/macro'
import { changePage } from '../../actions/PageActions'

import Button from '../shared/Button'
import ErrorMessage from '../shared/ErrorMessage'
import InputField from '../shared/InputField'
import SelectField from '../shared/SelectField'
import Spacer from '../shared/Spacer'
import AvatarImageField from '../shared/AvatarImageField'
import Color from '../../styles/color'
import { defaultPhoneNumberValidator } from 'supertokens-auth-react/lib/build/recipe/passwordless/validators'

const ColWrapper = styled.div`
  width: 25rem;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
`

const EmailLabel = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
`

const EmailVerificationStatus = styled.span`
  display: inline-block;
  margin-left: auto;
  font-size: 1rem;
  font-weight: normal;
  color: ${(p) => (p.isVerified ? Color.green : Color.red)};
`

class ProfileEdit extends Component {
  constructor(props) {
    super(props)

    this.state = {
      error: null
    }

    this.handleDelete = this.handleDelete.bind(this)
    this.checkUser = this.checkUser.bind(this)
    this.removeImage = this.removeImage.bind(this)
    this._handleSubmit = this._handleSubmit.bind(this)
  }

  componentDidMount() {
    this.checkUser()
  }

  handleDelete(e) {
    e.preventDefault()
    let user = this.props.auth?.user

    if (user && confirm(translate('common.confirmDelete')) && this.props.deleteProfile) {
      this.props.deleteProfile()
    }
  }

  checkUser() {
    let user = this.props.auth?.user?.profile

    if (user) {
      let propertyForm = {}
      propertyForm.firstName = user.firstName
      propertyForm.lastName = user.lastName
      propertyForm.email = user.email
      propertyForm.phoneNumber = user.phoneNumber
      propertyForm.language = user.language
      propertyForm.image = user.image

      this.props.initialize(propertyForm)
    }
  }

  removeImage(name) {
    this.props.change(name, '')
  }

  _handleSubmit(e) {
    e.preventDefault()

    if (typeof this.props.handleSubmit === 'function') {
      this.props.handleSubmit()
    }
  }

  render() {
    const { auth } = this.props
    let profile = auth.user?.profile
    let profileError = this.props.auth?.error

    return (
      <div>
        <form onSubmit={this._handleSubmit}>
          <Field
            name="image"
            label={translate('profile.image')}
            placeholder=""
            unit=""
            component={AvatarImageField}
            setFieldValue={this.props.change}
            type="file"
            user={profile}
            removeImage={this.removeImage}
            required={false}
          />

          <Field
            name="firstName"
            label={translate('profile.firstName')}
            placeholder={translate('profile.firstName')}
            unit=""
            component={InputField}
            type="text"
            required={true}
          />

          <Field
            name="lastName"
            label={translate('profile.lastName')}
            placeholder={translate('profile.lastName')}
            unit=""
            component={InputField}
            type="text"
            required={true}
          />

          <Field
            name="email"
            label={
              <EmailLabel>
                <span>{translate('profile.email')}</span>

                <EmailVerificationStatus isVerified={!!profile?.emailVerified}>
                  {profile?.emailVerified ? (
                    translate('profile.emailVerified')
                  ) : (
                    <a href={`/login/verify-email?redirectToPath=/profile`}>
                      {translate('profile.emailNotVerified')}
                    </a>
                  )}
                </EmailVerificationStatus>
              </EmailLabel>
            }
            props={{
              disabled: profile?.loginMethod !== 'passwordless'
            }}
            placeholder={translate('profile.email')}
            unit=""
            component={InputField}
            type="email"
            required={true}
          />

          {profile?.loginMethod === 'passwordless' && (
            <Field
              id="phoneNumber"
              name="phoneNumber"
              label={translate('profile.phoneNumber')}
              placeholder={translate('profile.phoneNumberPlaceholder')}
              unit=""
              validate={(value) => {
                if (value) {
                  let validation = defaultPhoneNumberValidator(value)

                  if (validation) {
                    return translate(`profile.errors.${validation}`)
                  }
                }

                return undefined
              }}
              required={false}
              component={InputField}
            />
          )}

          <Field
            id="language"
            name="language"
            label={translate('profile.languageLabel')}
            component={SelectField}>
            <option value="fi">{translate('profile.language.fi')}</option>
            <option value="en">{translate('profile.language.en')}</option>
          </Field>

          {profileError && (
            <div className="extraMarginForGenericForms">
              <ErrorMessage>
                {translate(`profile.errors.${profileError}`, {
                  fallback: translate('profile.errors.PROFILE_ERROR')
                })}
              </ErrorMessage>
            </div>
          )}

          <ColWrapper>
            <Spacer />
            <Button text={translate('profile.save')} type="submit" />
            <Spacer multiplier={2} />

            <Button
              text={translate('common.cancel')}
              type="button"
              layout="link"
              onClick={(e) => {
                changePage('/profile')
              }}
            />
            <Spacer />
            <p>
              <a href="#" style={{ color: Color.red }} onClick={this.handleDelete}>
                {translate('common.deleteProfile')}
              </a>
            </p>
          </ColWrapper>
        </form>
      </div>
    )
  }
}

ProfileEdit = reduxForm({
  form: 'profileEdit'
})(ProfileEdit)

const selector = formValueSelector('profileEdit') // <-- same as form name

ProfileEdit = connect((state) => {
  const firstName = selector(state, 'firstName')
  const lastName = selector(state, 'lastName')
  const email = selector(state, 'email')
  const phoneNumber = selector(state, 'phoneNumber')
  const language = selector(state, 'language')
  const image = selector(state, 'image')

  let updateTime = firstName + '_' + lastName + '_' + email + '_' + language

  let formValues = {}

  formValues.firstName = firstName
  formValues.lastName = lastName
  formValues.email = email
  formValues.phoneNumber = phoneNumber
  formValues.language = language
  formValues.image = image

  return {
    formValues: formValues,
    updateTime: updateTime,
    auth: state.auth
  }
})(ProfileEdit)

export default ProfileEdit
