import { mix } from 'polished'

const colors = {
  primary: '#1DDE9B',
  basic: '#2F3540',
  white: '#FFFFFF',
  ivoryWhite: '#FBFBFB',
  lightGray: 'rgba(239,239,239,0.25)',
  black: '#000000',
  blackish: '#50576A',
  darkGray: '#50596B',
  darkerGray: '#323232',
  green: '#1DDE9B',
  blue: '#3B5998',
  red: '#FF7C7C',
  chatBubble: '#EFF0F0',
  //chatBubbleMe:   '#6C6C6C',
  chatBubbleMe: '#1DDE9B',
  chatTimeStamp: '#9A9A9A',
  notificationGrey: 'rgba(239,239,239,0.25)',
  boxBorder: 'rgb(239,239,239)', // #EFEFEF
  border: '#EFEFEF',
  borderDark: '#95989A',

  overlayColor: 'rgba(0,0,0,0.8)',

  grey: 'rgba(47,53,64,0.7)', //'#2F3540',
  navBorder: 'rgba(47,53,64,0.05)', //'#2F3540'
  grayLight: mix(0.9, 'rgb(255,255,255)', 'rgb(239,239,239)'),
  google: '#D34836',

  greenRGB1: 'rgba(29,222,155,0.2)',
  greenRGB0: 'rgba(29,222,155,0)',
  infoText: '#95989A',
  toggleGrey: 'rgba(239,239,239,1)' // #EFEFEF
}

export default colors
