import React from 'react'
import Color from '../../styles/color'
import { GoogleMap } from './GoogleMap'
import { Marker } from '@react-google-maps/api'

const googleMapOptions = {
  zoomControl: false,
  scaleControl: false
}

const GoogleMapViewMap = (props) => {
  return (
    <GoogleMap options={googleMapOptions} {...props}>
      {props.markers.map((marker) => (
        <Marker {...marker} />
      ))}
    </GoogleMap>
  )
}

class GoogleMapView extends React.Component {
  state = {
    dialogOpen: false,
    currentMarkerID: 0,
    currentMarker: {}
  }

  handleMapClick = (e) => {}

  handleMarkerClick = (e) => {
    if (this.props.addPostalCode) {
      var postal_code = e.data.postalcode
      this.props.addPostalCode(postal_code)
    }
  }

  _handleMarkerMouseOver = (e) => {
    e.fillOpacity = 0.5
  }

  _handleMarkerMouseLeave = (e) => {
    e.fillOpacity = 0.0
  }

  _handleMarkerRightClick = (e) => {}

  _handleMapLoad = (map) => {
    this._mapComponent = map
  }

  loadMarkers = () => {}
  _handleCenterChanged = () => {}
  _handleDragStarted = () => {}

  _makeMarkersForGoogleMap = () => {
    var markers = []

    if (this.props.center) {
      markers.push(this._createMarkers(this.props.center, 15, 0.25, 0.5))
      markers.push(this._createMarkers(this.props.center, 5, 1))
    }

    return markers
  }

  _createMarkers = (item, scale, alpha, alpha2) => {
    if (!window?.google) {
      return
    }

    var path = window.google.maps.SymbolPath.CIRCLE
    var icon = {
      path: path,
      scale: scale || 20,
      strokeColor: Color.green,
      strokeOpacity: alpha2 || 0.5,
      strokeWeight: 1,
      fillColor: Color.green,
      fillOpacity: alpha || 0.25
    }

    return {
      options: {
        strokeColor: Color.green,
        strokeOpacity: 0.8,
        strokeWeight: 1,
        fillColor: Color.green,
        fillOpacity: 0.35
      },

      icon: icon,
      key: scale,
      center: { lat: item.lat, lng: item.lng },
      position: { lat: item.lat, lng: item.lng }
    }
  }

  render() {
    return (
      <div className="MapView" style={{ height: '100%' }}>
        <GoogleMapViewMap
          containerElement={<div className="map-container" style={{ height: `100%` }} />}
          mapElement={<div className="map-container-element" style={{ height: `100%` }} />}
          onLoad={this._handleMapLoad}
          onClick={this.handleMapClick}
          onCenterChanged={this._handleCenterChanged}
          onMarkerClick={this.handleMarkerClick}
          onMarkerRightClick={this._handleMarkerRightClick}
          onDragStart={this._handleDragStarted}
          onMarkerMouseOver={this._handleMarkerMouseOver}
          onMarkerMouseLeave={this._handleMarkerMouseLeave}
          center={this.props.center}
          markers={this._makeMarkersForGoogleMap(this.props)}
        />
      </div>
    )
  }
}

export default GoogleMapView
