import * as ActionTypes from '../constants/ActionTypes'
import { createApiRequest } from '../utils/Api'

export function receiveAreaPrice(data) {
  return {
    type: ActionTypes.GET_AREA_PRICE,
    data: data
  }
}

export function getAreaPrice(postalCode) {
  if (postalCode === '00101-fi') {
    postalCode = '00100-fi'
  }

  var path = `/statistic/?type=house_area_statistic&identifier=${postalCode}`

  return (dispatch, getState) => {
    let apiRequest = createApiRequest(getState().auth.accessToken)

    return apiRequest({ path })
      .then((result) => {
        dispatch(receiveAreaPrice(result.data))
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

export function numberWithCommas(x) {
  if (x) {
    x = x.toString()
    let pattern = /(-?\d+)(\d{3})/
    while (pattern.test(x)) x = x.replace(pattern, `$1 $2`)
    return x
  }
}

export function roundPrice(price, acc, formatThousands) {
  var factor = Math.pow(10, acc)
  var numbervalue = Math.round(price * factor) / factor
  if (!formatThousands) {
    numbervalue = numberWithCommas(numbervalue)
  }

  return numbervalue
}

export function getCurrencySymbol(currency) {
  switch (currency) {
    case 'EUR':
      return '€'
    default:
      return currency
  }
}

export function getGoogleJsonType(obj, keyValue, useShortName = false) {
  for (let namePart of Object.values(obj)) {
    if (namePart.types.includes(keyValue)) {
      return useShortName && namePart.short_name ? namePart.short_name : namePart.long_name
    }
  }

  return ''
}
