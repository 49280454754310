import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import * as UserActions from '../actions/UserActions'

import ProfileFrontpage from '../components/profile/ProfileFrontpage'
import ProfileEdit from '../components/profile/ProfileEdit'
import ProfileStats from '../components/profile/ProfileStats'
import ProfileEmails from '../components/profile/ProfileEmails'

import LoggedInContainer from './LoggedInContainer'
import translate from 'counterpart'
import { getUserLicenses, cancelSubscription } from '../actions/LicenseActions'
import { receiveLogout } from '../actions/AuthActions'

const ProfileContainer = ({
  route,
  cancelSubscription,
  license,
  getAppStats,
  onLogout,
  auth,
  saveProfile,
  deleteProfile,
  getUserLicenses
}) => {
  useEffect(() => {
    if (auth.user) {
      console.log('Fetching user licenses')
      getUserLicenses()
    }
  }, [auth.user, getUserLicenses])

  switch (route.action) {
    case 'stats':
      return (
        <LoggedInContainer navigationRoute={route} selected="profile" maxWidth="col">
          <ProfileStats getAppStats={getAppStats} key="stats" />
        </LoggedInContainer>
      )

    case 'emails':
      return (
        <LoggedInContainer navigationRoute={route} selected="profile" maxWidth="col">
          <ProfileEmails key="emails" auth={auth} />
        </LoggedInContainer>
      )

    case 'view':
      return (
        <LoggedInContainer navigationRoute={route} selected="profile" maxWidth="col">
          <ProfileFrontpage
            auth={auth}
            onLogout={onLogout}
            key="frontpage"
            cancelSubscription={cancelSubscription}
            license={license}
          />
        </LoggedInContainer>
      )

    case 'edit':
      return (
        <LoggedInContainer
          navigationRoute={route}
          selected="profile"
          backUrl="/profile"
          customTitle={translate('common.editProfile')}
          maxWidth="col">
          <ProfileEdit auth={auth} onSubmit={saveProfile} deleteProfile={deleteProfile} />
        </LoggedInContainer>
      )
  }

  return null
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    license: state.license
  }
}

const mapDispatchToProps = (dispatch) => ({
  deleteProfile: () => dispatch(UserActions.deleteProfile()),
  saveProfile: (saveData) => dispatch(UserActions.putProfile(saveData)),
  cancelSubscription: (id) => dispatch(cancelSubscription(id)),
  getUserLicenses: () => dispatch(getUserLicenses()),
  getAppStats: () => dispatch(UserActions.getAppStats()),
  onLogout: () => dispatch(receiveLogout())
})

export default connect(mapStateToProps, mapDispatchToProps)(ProfileContainer)
