import * as ActionTypes from '../constants/ActionTypes'
import { getApiUrl, createApiRequest } from '../utils/Api'
import { fetchStart } from './FetchActions'

import Moment from 'moment'

export function sendSocketConnection(socket, io) {
  return {
    type: ActionTypes.CHAT_SOCKET_CONNECTION,
    socket,
    io
  }
}

export function receivePostMessage(data) {
  return {
    type: ActionTypes.CHAT_SENT,
    data: data
  }
}

export function receiveMessages(data) {
  return {
    type: ActionTypes.MESSAGES_RECEIVED,
    data: data
  }
}

export function receiveMessage(data) {
  return {
    type: ActionTypes.SINGLE_MESSAGE_RECEIVED,
    data: data
  }
}

export function receiveGetMarkedAsRead(data) {
  return {
    type: ActionTypes.MESSAGE_MARKED_AS_READ,
    data: data
  }
}

export function markAsRead(receiver) {
  var URL = `/message/markasread/${receiver}`
  var METHOD = 'PATCH'

  return (dispatch) => {
    let apiRequest = createApiRequest()

    return apiRequest({ path: URL, method: METHOD })
      .then((json) => dispatch(receiveGetMarkedAsRead(json.data)))
      .catch((error) => console.log(error))
  }
}

export function messageSent(data) {
  return {
    type: ActionTypes.MESSAGE_SENT
  }
}
export function messageSentClear() {
  return {
    type: ActionTypes.MESSAGE_SENT_CLEAR
  }
}
export function clearMessageSent(response) {
  return (dispatch, getState) => {
    dispatch(messageSentClear())
  }
}

function getMessagesPath() {
  // Get the proper pathname with any prefixes.
  let apiUrl = new URL(getApiUrl('/message'))
  return apiUrl.pathname
}

export function initRealtimeMessages(socket, dispatch) {
  getAllMessages(socket, dispatch)
  // Receive new messages through the socket. This depends on the socket having
  // "joined" the room on the server (this is done in the /messages find
  // controller action).
  socket.on(`message`, (msg) => {
    dispatch(receiveMessage(msg))
  })
}

export function sendMessage(formData, onSuccess, onError) {
  return (dispatch, getState) => {
    var state = getState()

    if (state.messages?.socket) {
      let socket = getState().messages.socket
      dispatch(fetchStart('chatSendMessage'))

      socket.put(getMessagesPath(), formData, function serverResponded(body) {
        dispatch(receivePostMessage(body))
        dispatch(messageSent(true))
      })
    } else {
      if (onError) {
        onError()
      }
    }
  }
}

export function getAllMessages(socket, dispatch) {
  if (socket) {
    socket.get(getMessagesPath(), function serverResponded(body, JWR) {
      let messageData = body?.data

      if (messageData) {
        dispatch(receiveMessages(body?.data))
      }
    })
  }
}

export function getThreads(messages, currentUser) {
  let threads = {}

  if (!currentUser || !messages || !Array.isArray(messages) || messages.length === 0) {
    return threads
  }

  for (let msg of messages) {
    let threadUserId = currentUser.id
    let otherUser = undefined

    if (msg.receiverUserId !== currentUser.id) {
      threadUserId = msg.receiverUserId
      otherUser = msg.receiver
    }

    if (msg.senderUserId !== currentUser.id) {
      threadUserId = msg.senderUserId
      otherUser = msg.sender
    }

    msg.me = currentUser.profile
    msg.user = otherUser

    if (!threads[threadUserId]) {
      threads[threadUserId] = {}
      threads[threadUserId].id = threadUserId
      threads[threadUserId].messages = []
      threads[threadUserId].user = otherUser
      threads[threadUserId].me = currentUser
      threads[threadUserId].unreadMessages = 0
    }

    if (!msg.receiverRead && msg.senderUserId !== currentUser.id) {
      threads[threadUserId].unreadMessages++
    }

    threads[threadUserId].timestamp = Moment(msg.createdAt).format('x')
    threads[threadUserId].messages.push(msg)
  }

  return threads
}

export function getUnreadMessagesCount(messages, currentUser) {
  var unreadCount = 0

  if (!currentUser || !messages || !Array.isArray(messages) || messages.length === 0) {
    return unreadCount
  }

  for (let msg of messages) {
    if (!msg.receiverRead && msg.receiverUserId === currentUser.id) {
      unreadCount++
    }
  }

  return unreadCount
}
