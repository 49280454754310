import * as ActionTypes from '../constants/ActionTypes'

export function fetchStart(id) {
  return {
    type: ActionTypes.FETCH_START,
    id: id || 'generic-fetch'
  }
}

export function fetchEnd(id) {
  return {
    type: ActionTypes.FETCH_END,
    id: id || 'generic-fetch'
  }
}
