import React, { Component } from 'react'
import { connect } from 'react-redux'
import Color from '../../styles/color'
import Variables from '../../styles/variables'
import styled from 'styled-components/macro'
import LoadingIndicator from './LoadingIndicator'
import logoApp from '../../images/logo-app.svg'

const Wrapper = styled.div`
  background-color: ${Color.white};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999999;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  opacity: 0;
  transition: opacity ${Variables.defaultSpeed} ${Variables.defaultEasing} 1s;

  &.page-loading {
    transition-delay: 0s;
    opacity: 1;
    transition-duration: 0s;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .logo {
    display: flex;

    img {
      display: block;
      width: 10rem;
      height: auto;
    }

    sup {
      color: ${Color.green};
      margin-left: 0.25em;
      font-size: ${Variables.tinyFontSize};
    }
  }
`
class PageLoader extends Component {
  constructor(props) {
    super(props)

    this.state = {
      routeChanged: false
    }
  }

  render() {
    return (
      <Wrapper>
        <div className="wrapper">
          <div className="logo">
            <img src={logoApp} />
            <sup>Beta</sup>
          </div>
          <LoadingIndicator />
        </div>
      </Wrapper>
    )
  }
}

const mapStateToProps = (state) => ({
  route: state.route.route
})

export default connect(mapStateToProps)(PageLoader)
