import React from 'react'
import { Route } from 'react-router'

import { UserDoesNotHaveLicense } from './utils/Auth'
import FrontPageContainer from './containers/FrontPageContainer'
import PropertyContainer from './containers/PropertyContainer'
import BuyerSellerContainer from './containers/BuyerContainer'
import FreeContainer from './containers/FreeContainer'
import ProfileContainer from './containers/ProfileContainer'
import ChatContainer from './containers/ChatContainer'
import NotificationContainer from './containers/NotificationContainer'
import PaymentContainer from './containers/PaymentContainer'
import NotFoundContainer from './containers/NotFoundContainer'
import App from './containers/App'
import { ThirdPartyPasswordlessAuth } from 'supertokens-auth-react/recipe/thirdpartypasswordless'

const ProtectedComponent = (Component) => (props) => {
  console.log(props)

  return (
    <ThirdPartyPasswordlessAuth
      requireAuth={true}
      onSessionExpired={() => {
        window.location.href = '/'
      }}>
      <Component {...props} />
    </ThirdPartyPasswordlessAuth>
  )
}

const ProtectedRoute = ({ component, ...props }) => (
  <Route component={ProtectedComponent(component)} {...props} />
)

const routes = (
  <Route component={App}>
    <Route path="/" component={FrontPageContainer} />
    <Route path="explore" component={PropertyContainer} action={'explore'} />

    <Route
      path="explore/hot-areas/:country"
      component={PropertyContainer}
      action={'explore-areas'}
    />
    <Route path="explore/:country" component={PropertyContainer} action={'explore'} />

    <Route path="explore/:country/:city" component={PropertyContainer} action={'explore'} />

    <Route
      path="explore/:country/:city/property/:propertyId"
      component={PropertyContainer}
      action={'explore-property-view'}
    />
    <Route
      path="explore/:country/:city/property/:propertyId/:imageId"
      component={PropertyContainer}
      action={'explore-property-view'}
    />
    <Route
      path="explore/:country/:city/:command"
      component={PropertyContainer}
      action={'explore'}
    />

    <Route path="property" component={PropertyContainer} action={'view'} />
    <Route path="property/new" component={PropertyContainer} action={'property-add'} />
    <ProtectedRoute
      path="property/edit/:propertyId"
      component={PropertyContainer}
      action={'property-edit'}
    />
    <Route path="property/:propertyId" component={PropertyContainer} action={'property-view'} />
    <Route
      path="property/:propertyId/:imageId"
      component={PropertyContainer}
      action={'property-view'}
    />
    <ProtectedRoute path="seller/:sellerId" component={BuyerSellerContainer} />
    <ProtectedRoute path="seller/:sellerId/:propertyId" component={BuyerSellerContainer} />
    <ProtectedRoute path="buyer/:watchId" component={BuyerSellerContainer} />
    <ProtectedRoute path="buyer/:watchId/:propertyId" component={BuyerSellerContainer} />
    <Route path="find" component={PropertyContainer} action="searchwatcher" />
    <ProtectedRoute path="find/new" component={PropertyContainer} action={'searchwatcher-add'} />
    <ProtectedRoute
      path="find/edit/:watcherId"
      component={PropertyContainer}
      action={'searchwatcher-edit'}
    />
    <Route path="find/:watcherId" component={PropertyContainer} action={'searchwatcher'} />
    <Route path="terms" component={FreeContainer} action={'terms'} />
    <ProtectedRoute path="favourites" component={PropertyContainer} action={'favourites'} />
    <ProtectedRoute path="profile" component={ProfileContainer} action={'view'} />
    <ProtectedRoute path="profile/edit" component={ProfileContainer} action={'edit'} />
    <ProtectedRoute path="admin/stats" component={ProfileContainer} action={'stats'} />
    <ProtectedRoute path="admin/emails" component={ProfileContainer} action={'emails'} />
    <ProtectedRoute path="messages" component={ChatContainer} action={'view'} />
    <ProtectedRoute path="messages/:threadId" component={ChatContainer} action={'chat'} />
    <ProtectedRoute path="notifications" component={NotificationContainer} action={'view'} />
    <ProtectedRoute
      path="subscribe"
      component={UserDoesNotHaveLicense(PaymentContainer)}
      action={'view'}
    />
    <ProtectedRoute
      path="subscribe/searchwatcher"
      component={PaymentContainer}
      action={'view'}
      returnUrl={'/find'}
    />
    <ProtectedRoute
      path="subscribe/profile"
      component={PaymentContainer}
      action={'view'}
      returnUrl={'/profile'}
    />
    <ProtectedRoute
      path="get-license"
      component={UserDoesNotHaveLicense(PaymentContainer)}
      action={'get-license'}
    />
    <Route path="*" component={NotFoundContainer} status={404} />
  </Route>
)

export default routes
