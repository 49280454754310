import * as ActionTypes from '../constants/ActionTypes'
import { uniqBy, orderBy } from 'lodash'

const initialState = {
  updateTime: 0,
  notifications: null
}

function NotificationsReducer(state = initialState, action) {
  let now = new Date().getTime()

  if (action) {
    switch (action.type) {
      case ActionTypes.GET_NOTIFICATION:
        let currentNotifications = state.notifications || []
        let nextNotifications = uniqBy(
          orderBy([action.data, ...currentNotifications], 'updatedAt', 'desc'),
          'id'
        )
        return { ...state, notifications: nextNotifications, updateTime: now }
      case ActionTypes.GET_ALL_NOTIFICATIONS:
        return { ...state, notifications: uniqBy(action.data || [], 'id'), updateTime: now }
      case ActionTypes.LOGOUT_SUCCESS:
        return {
          ...state,
          updateTime: 0,
          notifications: null
        }
      default:
        return state
    }
  }
}

export default NotificationsReducer
