export function IconPlus({ className, style, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="2632.502 1739.502 22.858 22.858"
      className={className}
      style={style}>
      <path
        fill={color || '#2f3540'}
        d="M9.983,8.082l5.493-5.493a1.411,1.411,0,0,0,0-2.113,1.411,1.411,0,0,0-2.113,0L7.87,6.18,2.588.687a1.411,1.411,0,0,0-2.113,0,1.411,1.411,0,0,0,0,2.113L5.969,8.293.475,13.575a1.411,1.411,0,0,0,0,2.113,1.411,1.411,0,0,0,2.113,0l5.493-5.493,5.493,5.493a1.494,1.494,0,0,0,2.113-2.113Z"
        transform="translate(2643.931 1739.502) rotate(45)"
      />
    </svg>
  )
}
