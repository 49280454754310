import { REACT_APP_GOOGLE_APIKEY } from '../../constants'
import { googleMapUrlStyle } from './googleMapUrlStyle'
import styled from 'styled-components/macro'

const createUrl = ({ lat, lng, zoom = 13, width = 300, height = 300 }) =>
  `https://maps.googleapis.com/maps/api/staticmap?center=${lat},${lng}&zoom=${zoom}&size=${width}x${height}&scale=2&maptype=roadmap&format=png&visual_refresh=true&sensor=false&style=${googleMapUrlStyle}&key=${REACT_APP_GOOGLE_APIKEY}`

export function getZoomFromArea(area) {
  return Math.floor(15 - area.radius / 1000)
}

const StaticGoogleMapImage = styled.img`
  width: ${(p) => p.width || 300}px;
  height: ${(p) => p.height || 300}px;
  display: block;
`

export const StaticGoogleMap = ({ lat, lng, width, height, zoom = 15, className, style }) => {
  let url = createUrl({ lat, lng, zoom, width, height })
  return (
    <StaticGoogleMapImage
      src={url}
      width={width}
      height={height}
      className={className}
      style={style}
    />
  )
}
