import React, { Component } from 'react'

import styled from 'styled-components/macro'
import translate from 'counterpart'
import { changePage } from '../../../actions/PageActions'
import Button from '../../shared/Button'
import Spacer from '../../shared/Spacer'
import userNoLetters from '../../../images/user-no-letters.png'

const Wrapper = styled.div`
  text-align: center;
`

const ImageWrapper = styled.div`
  width: 15rem;
  display: inline-block;

  img {
    max-width: 100%;
    height: auto;
  }
`

export default class EmptyFavourite extends Component {
  render() {
    return (
      <Wrapper>
        <ImageWrapper>
          <img src={userNoLetters} />
        </ImageWrapper>
        <h1>{translate('dashboard.favourites.addTitle')}</h1>
        <p>{translate('dashboard.favourites.addDescription')}</p>
        <Spacer multiplier={2} />
        <div>
          <Button
            text={translate('dashboard.favourites.addButton')}
            type="button"
            onClick={(e) => {
              changePage('/find')
            }}
          />
        </div>
      </Wrapper>
    )
  }
}
