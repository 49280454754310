import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'

import Variables from '../../styles/variables'

const Wrapper = styled.div`
  height: ${(props) =>
    props.multiplier ? props.multiplier * parseFloat(Variables.gutter) + 'rem' : Variables.gutter};
`

export default class Spacer extends Component {
  static propTypes = {
    multiplier: PropTypes.number
  }

  static defaultProps = {
    multiplier: 1
  }

  render() {
    const { multiplier } = this.props

    return <Wrapper multiplier={multiplier} />
  }
}
