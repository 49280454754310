import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import BasicPageContainer from './BasicPageContainer'
import UserHeader from '../components/shared/UserHeader'
import BackHeader from '../components/shared/BackHeader'

import Navigation from '../components/shared/Navigation'
import Footer from '../components/shared/Footer'

import LoggedOutContainer from './LoggedOutContainer'
import { changeLanguage } from '../actions/LanguageActions'
import styled from 'styled-components/macro'
import Variables from '../styles/variables'
import { initSocketConnection } from '../actions/RealtimeActions'

const ContentWrapper = styled.div`
  margin-top: ${Variables.navigationHeight};
`

const LoggedInContainer = ({
  user,
  messages,
  notifications,
  changeLanguage,
  hideNavigation,
  navigationRoute,
  selected,
  backUrl,
  backLinkText,
  goBack,
  customTitle,
  backgroundImage,
  maxWidth,
  children,
  topPadding,
  style,
  initSocketConnection
}) => {
  useEffect(() => {
    let io = messages.io

    if (user && !io) {
      initSocketConnection()
    }
  }, [initSocketConnection, user, messages])

  const hasBackNav = !!(backUrl || goBack)

  if (!user) {
    return (
      <LoggedOutContainer
        changeLanguage={changeLanguage}
        backUrl={backUrl}
        customTitle={customTitle}>
        {children}
      </LoggedOutContainer>
    )
  }

  return (
    <div className="LoggedIn">
      {!hideNavigation && (
        <UserHeader>
          <Navigation
            user={user}
            navigationRoute={navigationRoute || null}
            selected={selected || null}
            messages={messages}
            notifications={notifications}
          />
        </UserHeader>
      )}
      <ContentWrapper>
        {(backUrl || goBack) && (
          <BackHeader
            backUrl={backUrl}
            goBack={goBack}
            customTitle={customTitle}
            backLinkText={backLinkText}
          />
        )}
        <BasicPageContainer
          backgroundImage={backgroundImage}
          maxWidth={maxWidth}
          hasBackNav={hasBackNav}
          hasFooter={true}
          topPadding={topPadding}
          style={style}>
          {children}
        </BasicPageContainer>
        <Footer user={user} />
      </ContentWrapper>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    messages: state.messages,
    notifications: state.notifications
  }
}

const mapDispatchToProps = (dispatch) => ({
  initSocketConnection: () => dispatch(initSocketConnection()),
  changeLanguage: (lang) => dispatch(changeLanguage(lang))
})

export default connect(mapStateToProps, mapDispatchToProps)(LoggedInContainer)
