import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components/macro'
import translate from 'counterpart'
import { Link } from 'react-router'
import Variables from '../../styles/variables'
import Color from '../../styles/color'

const FooterWrapper = styled.footer`
  display: flex;
  align-items: center;
  border-top: 0.1rem solid ${Color.border};
  padding: 0 ${Variables.gutter};
  height: ${Variables.footerHeight};
  font-size: ${Variables.smallFontSize};

  > div {
    position: relative;
    max-width: ${Variables.maxWidthPage};
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
  }

  a {
    color: ${Color.grey};
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;

    li {
      margin-right: ${Variables.gutter};
    }
  }
`

export default class Footer extends Component {
  static propTypes = {
    user: PropTypes.object
  }

  render() {
    const { user } = this.props
    return (
      <FooterWrapper>
        <div>
          <ul>
            {user && user.administrator && (
              <li>
                <Link to={'/admin/stats'}>{translate('menu.stats')}</Link>
              </li>
            )}
            <li>
              <Link to={'/terms'}>{translate('menu.terms')}</Link>
            </li>
          </ul>
        </div>
      </FooterWrapper>
    )
  }
}
