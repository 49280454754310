import { createApiRequest, getApiUrl } from '../utils/Api'
import { fetchStart, fetchEnd } from './FetchActions'
import * as ActionTypes from '../constants/ActionTypes'

export function receiveAllNotifications(data) {
  return {
    type: ActionTypes.GET_ALL_NOTIFICATIONS,
    data: data
  }
}

export function receiveNotification(data) {
  return {
    type: ActionTypes.GET_NOTIFICATION,
    data: data
  }
}

export function initRealtimeNotifications(socket, dispatch) {
  getNotifications(socket, dispatch)

  socket.on(`notification`, (msg) => {
    dispatch(receiveNotification(msg.data))
  })
}

function getNotificationsPath() {
  // Get the proper pathname with any prefixes.
  let apiUrl = new URL(getApiUrl('/notification'))
  return apiUrl.pathname
}

export function getNotifications(socket, dispatch) {
  socket.get(getNotificationsPath(), (body) => {
    let notifications = body?.data

    if (notifications) {
      dispatch(receiveAllNotifications(notifications))
    }
  })
}

export function fetchNotifications() {
  const URL = '/notification'

  return (dispatch, getState) => {
    let apiRequest = createApiRequest(getState().auth.accessToken)

    dispatch(fetchStart('notifications'))

    return apiRequest({ path: URL, method: 'GET' })
      .then((json) => {
        dispatch(receiveAllNotifications(json.data))
        dispatch(fetchEnd('notifications'))
      })

      .catch((error) => {
        dispatch(fetchEnd('notifications'))
      })
  }
}

export function markAsRead(id) {
  var URL = `/notification/${id}`
  var METHOD = 'PATCH'

  return (dispatch, getState) => {
    let apiRequest = createApiRequest(getState().auth.accessToken)

    return apiRequest({ path: URL, method: METHOD })
      .then((json) => {
        dispatch(receiveNotification(json.data))
      })
      .catch((error) => {
        console.log(error)
      })
  }
}

export function markAllAsRead() {
  var URL = `/notification/all`
  var METHOD = 'PATCH'

  return (dispatch, getState) => {
    let apiRequest = createApiRequest(getState().auth.accessToken)

    return apiRequest({ path: URL, method: METHOD })
      .then((json) => {
        dispatch(receiveAllNotifications(json.data))
      })
      .catch((error) => {
        console.log(error)
      })
  }
}
