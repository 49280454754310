import { useJsApiLoader } from '@react-google-maps/api'
import { REACT_APP_GOOGLE_APIKEY } from '../../constants'
import PageLoader from '../loader/PageLoader'
import translate from 'counterpart'

const libraries = ['geometry', 'places', 'visualization']

export function GoogleMapProvider({ children }) {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: REACT_APP_GOOGLE_APIKEY,
    language: 'en',
    libraries
  })

  if (loadError) {
    console.log('Google maps load error:', loadError)
    return <div>{translate('mapLoadError')}</div>
  }

  return isLoaded ? children : <PageLoader />
}
