import sailsIOClient from 'sails.io.js'
import socketIOClient from 'socket.io-client'
import { getApiUrl } from '../utils/Api'
import { REACT_APP_SOCKET_IO_PATH } from '../constants'
import { sendSocketConnection, initRealtimeMessages } from './MessageActions'
import { initRealtimeNotifications } from './NotificationActions'

export function initSocketConnection() {
  return (dispatch) => {
    var io = sailsIOClient(socketIOClient)
    io.sails.autoConnect = false

    let socketUrl = new URL(getApiUrl())

    const socketConnection = {
      url: socketUrl.origin,
      reconnection: true,
      environment: 'production',
      transports: ['websocket', 'polling'],
      path: REACT_APP_SOCKET_IO_PATH
      // TODO: Authenticate socket
    }

    let socket = io.sails.connect(socketConnection)

    socket.on('connect', () => {
      console.log('-- on connect --')
      initRealtimeMessages(socket, dispatch)
      initRealtimeNotifications(socket, dispatch)
    })

    socket.on('disconnect', () => {
      console.log('Socket disconnected. Reconnecting...')
    })

    dispatch(sendSocketConnection(socket, io))
  }
}
