import React, { Component } from 'react'
import styled from 'styled-components/macro'

import translate from 'counterpart'
import Color from '../../styles/color'
import Variables from '../../styles/variables'

import Link from '../shared/Link'
import subscribePay from '../../images/subscribe-pay.svg'
import { hasPaidLicense } from '../../actions/LicenseActions'

const HeaderWrapper = styled.div`
  text-align: center;
  max-width: 50rem;
  margin: 0 auto;
`

const PlanWrapper = styled.div`
  max-width: 40rem;
  padding: ${Variables.gutter};
  border: 0.1rem solid ${Color.border};
  text-align: center;
  margin: 0 auto;

  > h1 {
    margin-bottom: 0;
  }

  > h3 {
    margin-top: 0;
  }
`

const Illustration = styled.img``

export default class PaymentView extends Component {
  render() {
    // TODO: back text for watcher or property
    let backTxt = translate('license.noThanks.buttonText')
    let licenses = this.props.license?.userLicenses || []

    if (hasPaidLicense(licenses)) {
      backTxt = translate('common.back')
    }

    return (
      <div>
        <HeaderWrapper>
          <h1>{translate('license.description')}</h1>
        </HeaderWrapper>
        <PlanWrapper>
          <Illustration src={subscribePay} />
          <h1>{translate('license.subscription.paymentPerMonth')}</h1>
          <h3>{translate('license.subscription.paymentPerMonthText')}</h3>
          <p style={{ marginBottom: '1.75rem' }}>{translate('license.subscription.description')}</p>
          {(hasPaidLicense(licenses) && (
            <p>
              <strong>{translate('common.youHaveAlreadySubscribed')}</strong>
            </p>
          )) || (
            <Link to="/get-license" type="button">
              {translate('license.subscription.buttonText')}
            </Link>
          )}
        </PlanWrapper>
        <p style={{ marginTop: '1rem', textAlign: 'center' }}>
          <Link to={this.props.backUrl || '/property'}>{backTxt}</Link>
        </p>
      </div>
    )
  }
}
