import React, { Component } from 'react'

import styled from 'styled-components/macro'
import { mix } from 'polished'

import Color from '../../styles/color'
import Variables from '../../styles/variables'
import fbLogo from '../../images/icon-facebook-logo.png'
import googleLogo from '../../images/icon-google-logo.png'

const ButtonRounded = styled.button`
  display: inline-block;
  border-radius: 3rem;
  padding: ${(props) => (props.size === 'small' ? '0.75rem 3rem' : '1.2rem 4rem')};
  text-align: center;
  background-color: ${Color.green};
  color: ${Color.white};
  border: 0.1rem solid ${Color.green};
  cursor: pointer;
  font-size: ${(props) =>
    props.size === 'small' ? Variables.smallFontSize : Variables.bodyFontSize};
  transition: opacity ${Variables.defaultSpeed} ${Variables.defaultEasing};
  min-width: ${(props) => (props.size === 'small' ? '0' : '18rem')};
  font-weight: ${(props) => (props.size === 'small' ? '400' : '600')};

  &:hover {
    border-color: ${mix(0.7, Color.green, Color.white)};
    background-color: ${mix(0.7, Color.green, Color.white)};
  }

  &:disabled {
    filter: grayscale(100%) brightness(130%);
    pointer-events: none;
  }
`

const ButtonOutline = styled(ButtonRounded)`
  background-color: transparent;
  color: ${Color.black};
  border-color: ${Color.borderDark};

  &:hover {
    border-color: ${mix(0.5, Color.borderDark, Color.white)};
    background-color: transparent;
  }
`

const ButtonNegative = styled(ButtonRounded)`
  background-color: ${Color.white};
  color: ${Color.black};
  border-color: ${Color.white};

  &:hover {
    border-color: ${mix(0.6, Color.white, Color.toggleGrey)};
    background-color: ${mix(0.6, Color.white, Color.toggleGrey)};
  }
`

const SomeButton = styled(ButtonRounded)`
  background-size: 1.8rem;
  padding-left: 4rem;
  padding-right: 4rem;
  text-align: left;

  img {
    width: 1.8rem;
    height: auto;
    vertical-align: text-bottom;
    margin-right: 1.5rem;
  }
`

const FacebookButton = styled(SomeButton)`
  background-color: ${Color.blue};
  color: ${Color.white};
  border: 0.1rem solid ${Color.blue};

  &:hover {
    border-color: ${mix(0.8, Color.blue, Color.white)};
    background-color: ${mix(0.8, Color.blue, Color.white)};
  }
`

const GoogleButton = styled(SomeButton)`
  background-color: ${Color.white};
  color: ${Color.google};
  border: 0.1rem solid ${Color.google};

  &:hover {
    border-color: ${mix(0.6, Color.google, Color.white)};
    background-color: ${mix(0.6, Color.white, Color.toggleGrey)};
  }
`

const LinkButton = styled.button`
  display: inline-block;
  padding: ${(props) => (props.size === 'small' ? '0.75rem 3rem' : '1.2rem 4rem')};
  text-align: center;
  background-color: transparent;
  appearance: none;
  color: ${Color.basic};
  border: 0 none;
  cursor: pointer;
  font-size: ${(props) =>
    props.size === 'small' ? Variables.smallFontSize : Variables.bodyFontSize};
  transition: opacity ${Variables.defaultSpeed} ${Variables.defaultEasing};
  font-weight: 400;
  text-decoration: underline;

  &:hover {
    opacity: 0.8;
  }
`

export default class Button extends Component {
  static defaultProps = {
    layout: 'rounded',
    type: 'button'
  }

  render() {
    const {
      style,
      disabled,
      type,
      layout,
      className,
      text,
      children = text,
      size,
      onClick
    } = this.props

    let buttonElement
    let buttonText = text || children

    if (layout === 'outline') {
      buttonElement = (
        <ButtonOutline
          style={style}
          size={size}
          className={className}
          disabled={disabled}
          onClick={onClick}
          type={type}
          data-elementname={this.props['data-elementname']}>
          {buttonText}
        </ButtonOutline>
      )
    } else if (layout === 'negative') {
      buttonElement = (
        <ButtonNegative
          style={style}
          size={size}
          className={className}
          disabled={disabled}
          onClick={onClick}
          type={type}
          data-elementname={this.props['data-elementname']}>
          {buttonText}
        </ButtonNegative>
      )
    } else if (layout === 'facebook') {
      buttonElement = (
        <FacebookButton
          style={style}
          size={size}
          className={className}
          disabled={disabled}
          onClick={onClick}
          type={type}
          data-elementname={this.props['data-elementname']}>
          <img src={fbLogo} />
          {buttonText}
        </FacebookButton>
      )
    } else if (layout === 'google') {
      buttonElement = (
        <GoogleButton
          style={style}
          size={size}
          className={className}
          disabled={disabled}
          onClick={onClick}
          type={type}
          data-elementname={this.props['data-elementname']}>
          <img src={googleLogo} />
          {buttonText}
        </GoogleButton>
      )
    } else if (layout === 'link') {
      buttonElement = (
        <LinkButton
          style={style}
          size={size}
          className={className}
          disabled={disabled}
          onClick={onClick}
          type={type}
          data-elementname={this.props['data-elementname']}>
          {buttonText}
        </LinkButton>
      )
    } else {
      buttonElement = (
        <ButtonRounded
          style={style}
          size={size}
          className={className}
          disabled={disabled}
          onClick={onClick}
          type={type}
          data-elementname={this.props['data-elementname']}>
          {buttonText}
        </ButtonRounded>
      )
    }

    return <div>{buttonElement}</div>
  }
}
