import * as ActionTypes from '../constants/ActionTypes'
import { createApiRequest } from '../utils/Api'
import { fetchStart, fetchEnd } from './FetchActions'

function receiveGetHousetype(data) {
  return {
    type: ActionTypes.HOUSETYPE_GET,
    housetypes: data
  }
}

export function getHouseTypes(country) {
  var URL = `/housetype?country=${country}`

  return (dispatch, getState) => {
    let apiRequest = createApiRequest(getState().auth.accessToken)

    dispatch(fetchStart('housetype'))
    return apiRequest({ path: URL })
      .then((json) => {
        //console.log("--- json GET return ---");
        //console.log(json);
        dispatch(receiveGetHousetype(json.data))
        dispatch(fetchEnd('housetype'))
      })
      .catch((error) => {
        //console.log("--- error ---");
        //console.log(error);
        dispatch(fetchEnd('housetype'))
      })
  }
}
