import { useCallback, useState } from 'react'
import ImageField from './ImageField'
import { uploadImage } from '../../utils/uploadImage'
import styled from 'styled-components/macro'
import color from '../../styles/color'

const FileError = styled.div`
  color: ${color.red};
`

const UploadImageField = ({ setFieldValue, ...props }) => {
  let [fileErrors, setFileErrors] = useState([])

  let onUpload = useCallback(
    async (files, fileName) => {
      let uploadedImage = await uploadImage(files)

      if (uploadedImage?.id) {
        setFileErrors([])
        setFieldValue(fileName, uploadedImage.id)
      }
    },
    [setFieldValue]
  )

  let onFileRejected = useCallback((fileRejection) => {
    if (!fileRejection) {
      setFileErrors([])
    } else {
      setFileErrors(
        fileRejection.errors.map((error) => ({
          fileName: fileRejection.fileName,
          error: error.message
        }))
      )
    }
  }, [])

  return (
    <div>
      <ImageField {...props} onFileRejected={onFileRejected} uploadImage={onUpload}>
        {props.children}
      </ImageField>
      {fileErrors.map(({ fileName, error }) => (
        <FileError key={fileName}>{error}</FileError>
      ))}
    </div>
  )
}

export default UploadImageField
