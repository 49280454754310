import * as ActionTypes from '../constants/ActionTypes'

const initialState = {
  route: '/'
}

function RouteReducer(state = initialState, action) {
  if (action) {
    switch (action.type) {
      case ActionTypes.ROUTE_CHANGED:
        return Object.assign({}, state, {
          route: action.route
        })
      default:
        return state
    }
  }
}

export default RouteReducer
