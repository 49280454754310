import React, { Component } from 'react'
import styled from 'styled-components/macro'

import Color from '../../styles/color'
import Variables from '../../styles/variables'

const ErrorWrapper = styled.div`
  background-color: transparent;
  color: ${Color.red};
  padding: 0.5rem 0;
  margin: ${Variables.gutterHalf} 0;
  border-radius: ${Variables.borderRadius};
`

export default class ErrorMessage extends Component {
  render() {
    const { children, ...other } = this.props

    return (
      <ErrorWrapper {...other} role="alert">
        {children}
      </ErrorWrapper>
    )
  }
}
