import React, { Component } from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components/macro'

import Color from '../../styles/color'
import Variables from '../../styles/variables'

const Wrapper = styled.div`
  position: ${(props) => (props.size === 'big' ? 'static' : 'absolute')};
  min-width: ${(props) =>
    props.size === 'big' ? '2.4rem' : props.size === 'tiny' ? '1.1rem' : '1.6rem'};
  height: ${(props) =>
    props.size === 'big' ? '2.4rem' : props.size === 'tiny' ? '1.1rem' : '1.6rem'};
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: ${(props) => (props.size === 'big' ? '1rem' : '0.5rem')};
  padding-right: ${(props) => (props.size === 'big' ? '1rem' : '0.5rem')};
  background-color: ${Color.green};
  color: ${Color.white};
  font-size: ${(props) =>
    props.size === 'big' ? Variables.smallFontSize : Variables.tinyFontSize};
  border: 0.1rem solid ${Color.white};
  border-radius: ${(props) =>
    props.size === 'big' ? '1.2rem' : props.size === 'tiny' ? '0.55rem' : '0.8rem'};
  right: 0;
  top: 0;
  transform: ${(props) => (props.size === 'small' ? 'translate(90%, -30%)' : 'translate(0%, 0%)')};
  line-height: 1;

  > div {
    display: ${(props) => (props.size === 'tiny' ? 'none' : 'block')};
  }
`

export default class NotificationIndicator extends Component {
  static propTypes = {
    count: PropTypes.number,
    size: PropTypes.string
  }

  static defaultProps = {
    size: 'small'
  }

  render() {
    const { count, size } = this.props

    if (!count) {
      return null
    }

    return (
      <Wrapper size={size}>
        <div>{count}</div>
      </Wrapper>
    )
  }
}
