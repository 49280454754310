import React, { useMemo, useCallback, useState } from 'react'
import styled from 'styled-components/macro'
import colors from '../../styles/color'
import { IconClose } from '../../icons/IconClose'
import Variables from '../../styles/variables'

const HideMessageButton = styled.button`
  background-color: transparent;
  color: ${colors.white};
  padding: 0.5rem;
  border: none;
  outline: none;
  margin-right: 1.5rem;
  cursor: pointer;
`

const MessageContent = styled.div``

const CloseIcon = styled(IconClose)`
  width: 1.5rem;
  height: 1.5rem;
`

const MESSAGE_KEY_PREFIX = 'message_hidden'

const getMessageHiddenState = (messageKey) => {
  return localStorage.getItem(`${MESSAGE_KEY_PREFIX}_${messageKey}`) === 'true'
}

const MessageBanner = ({ message, messageKey, className, style, canClose = true }) => {
  let [messageHidden, setMessageHidden] = useState(getMessageHiddenState(messageKey))

  const messageVisible = useMemo(
    () => message && (!messageHidden || !canClose),
    [message, messageHidden, canClose]
  )

  const onHideMessage = useCallback(() => {
    setMessageHidden(true)
    localStorage.setItem(`${MESSAGE_KEY_PREFIX}_${messageKey}`, 'true')
  }, [messageKey])

  if (!messageVisible) {
    return null
  }

  return (
    <div
      className={className}
      style={{
        backgroundColor: colors.green,
        color: colors.white,
        ...(style || {})
      }}>
      <div
        style={{
          maxWidth: Variables.maxWidthPage,
          margin: '0 auto',
          display: 'flex',
          alignItems: 'flex-start',
          padding: '1.5rem'
        }}>
        {canClose && (
          <HideMessageButton onClick={onHideMessage}>
            <CloseIcon color="white" />
          </HideMessageButton>
        )}
        <MessageContent>{message}</MessageContent>
      </div>
    </div>
  )
}

export default MessageBanner
