import * as ActionTypes from '../constants/ActionTypes'
import { createApiRequest } from '../utils/Api'
import { fetchStart, fetchEnd } from './FetchActions'
import * as PageActions from './PageActions'

export function receiveGetLicense(data) {
  return {
    type: ActionTypes.GET_LICENSE,
    data: data
  }
}

export function receiveLicenceCancel(data) {
  return {
    type: ActionTypes.GET_LICENCE_CANCEL,
    data: data
  }
}

export function receiveGetUserLicenses(data) {
  return {
    type: ActionTypes.GET_USER_LICENSES,
    data: data
  }
}

export function confirmLicensePayment(subscriptionId) {
  var URL = '/license/confirm-payment'
  var METHOD = 'POST'
  var body = { subscriptionId }

  return (dispatch, getState) => {
    let apiRequest = createApiRequest(getState().auth.accessToken)
    dispatch(fetchStart('License'))

    return apiRequest({ path: URL, json: body, method: METHOD })
      .then((json) => {
        dispatch(receiveGetLicense(json.data))
        dispatch(fetchEnd('License'))
        PageActions.changePage('/')
      })
      .catch((error) => {
        dispatch(fetchEnd('License'))
      })
  }
}

export function cancelSubscription(id) {
  var URL = `/license/${id}`
  var METHOD = 'DELETE'

  return (dispatch) => {
    let apiRequest = createApiRequest()

    return apiRequest({ path: URL, method: METHOD })
      .then((json) => {
        dispatch(receiveLicenceCancel(json.data))
      })
      .catch((error) => {
        console.log('cancelSubscription error', error)
      })
  }
}

export function getUserLicenses() {
  var URL = '/license'

  return (dispatch) => {
    let apiRequest = createApiRequest()

    return apiRequest({ path: URL })
      .then((json) => {
        dispatch(receiveGetUserLicenses(json.data))
      })
      .catch((error) => {
        console.log('getLicenses error', error)
      })
  }
}

export function hasPaidLicense(licenses) {
  let dateT = new Date()
  let now = dateT.getTime()

  if (licenses && licenses.length !== 0) {
    return licenses.some((license) => {
      let expires = new Date(license.expires).getTime()
      return expires > now
    })
  }

  return false
}
