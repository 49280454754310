import React, { useState } from 'react'
import Measure from 'react-measure'
import styled from 'styled-components/macro'
import Color from '../../../styles/color'
import Variables from '../../../styles/variables'

import Link from '../../shared/Link'
import { getFileUrlWithStorageId } from '../../../utils/getFileUrl'

const Wrapper = styled.div`
  position: relative;
  background-position: 50% 50%;
  background-size: cover;
  height: 0;
  padding-bottom: ${(props) => (props.width < 400 ? '57.75%' : '60%')};
  background-color: ${Color.border};
  color: ${Color.white};
  border-radius: ${Variables.borderRadius};
  overflow: hidden;

  > span {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60%;
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
  }

  > a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
  }
`

const PropertyInfo = styled.div`
  position: absolute;
  top: ${Variables.gutter};
  left: ${Variables.gutter};
  right: ${Variables.gutter};
  bottom: ${Variables.gutter};
  display: flex;
  align-items: center;
  > .content--div {
  }
  h3 {
    margin: 1rem 0 0;
  }

  p {
    margin: 0;
  }
`

const Address = styled.span`
  display: block;
`

const AreaBlock = ({ item, image, urlPrefix = 'property', dontLink, title, subtitle }) => {
  let [dimensions, setDimensions] = useState({ width: -1, height: -1 })
  const { width } = dimensions

  let bgStyle = {}

  if (image) {
    // TODO: Resize to 1024
    bgStyle = {
      backgroundImage: `url("${getFileUrlWithStorageId(image, true)}")`
    }
  }

  let url = '/' + urlPrefix + '/' + item.country + '/' + item.city
  let link = <Link className="propertyLink" to={url} />

  return (
    <Measure
      onMeasure={(dimensions) => {
        setDimensions(dimensions)
      }}>
      <div className="propertyBlock--block">
        <Wrapper style={bgStyle} width={width}>
          <PropertyInfo width={width}>
            <div className="content--div">
              <h3>{title}</h3>
              {subtitle && (
                <p>
                  <Address width={width}>{subtitle}</Address>
                </p>
              )}
            </div>
          </PropertyInfo>
          {!dontLink && link}
        </Wrapper>
      </div>
    </Measure>
  )
}
export default AreaBlock
