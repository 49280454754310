import React, { Component } from 'react'

import Color from '../../styles/color'
import Variables from '../../styles/variables'
import iconSearch from '../../images/icon-search.svg'

import styled from 'styled-components/macro'

const FieldContainer = styled.div`
  > div {
    position: relative;

    img {
      position: absolute;
      top: 50%;
      left: ${Variables.gutter};
      width: 1.6rem;
      height: auto;
      transform: translateY(-50%);
      z-index: 2;
    }

    input {
      border: 0.1rem solid transparent;
      border-bottom: 0.1rem solid ${Color.border};
      display: block;
      padding: 1.5rem 1.5rem 1.5rem 5rem;
      width: 100%;
      font-size: ${Variables.bodyFontSize};
      line-height: ${Variables.bodyLineHeight};
      border-radius: 0;
    }
  }
`

export default class SearchField extends Component {
  static defaultProps = {
    showLabel: false
  }

  render() {
    const props = this.props
    const hasError = !!(props.meta && props.meta.touched && props.meta.error)

    var hasErrorStyle = hasError ? 'error' : null
    var _onBlur = this.props.onBlur || null
    var _onChange = this.props.onChange || null

    return (
      <FieldContainer hasError={hasError} type={props.type} style={props.style}>
        {props.showLabel && <label>{props.label}</label>}
        <div>
          <img src={iconSearch} />
          <input
            id={props.id}
            {...props.input}
            className={`generic-input ${props.className || ''} ${hasErrorStyle}`}
            required={props.required || false}
            placeholder={props.placeholder}
            type={props.type}
            onBlur={_onBlur}
            onChange={_onChange}
          />
        </div>
      </FieldContainer>
    )
  }
}
